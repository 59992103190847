import React, { PureComponent } from "react";
import FullScreenDialog from "../../theme/CustomMUI/FullScreenDialog";
import AppContext from "../../context/store";
import Switch from "@material-ui/core/Switch";
import Select from "../../theme/CustomMUI/Select";
import { Grid, Column } from "../../theme/Grid";
import Button from "../../theme/Button";

import BetweenTimeGenerator from "../../functions/BetweenTimeGenerator";
import DisabledAppointmentsGenerator from "../../functions/DisabledAppointmentsGenerator";

import DatePickerInput from "../../theme/CustomMUI/DatePickerInput";
import AutocompleteInput from "../../theme/CustomMUI/AutoComplete";
import moment from "moment";
import Axios from "axios";
import getDayIndex from "../../functions/GetDayIndex";
import { toast } from "react-toastify";
import Input from "../../theme/CustomMUI/Input";
import CSwitch from "../../theme/CustomMUI/CSwitch";
import styled from "styled-components";
import CustomerAddDialog from "./CustomerAddDialog";
import { flushSync } from "react-dom";
import { IconButton, MenuItem } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomerSearchInput from "../../theme/CustomMUI/CustomerSearchInput";
import Table from "../../theme/Table";

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "white",
        boxShadow: "0px 0px 5px rgba(0,0,0,0.2)",
      },
    },
  },
});
class NewAppointmentDialog extends PureComponent {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      customer: [],
      employees: [],
      services: [],
      packagesList: [],
      selected_customer: this.props.selected_customers
        ? this.props.selected_customers
        : null,
      selected_employee: null,
      selected_service: null,
      selected_package: null,
      selected_date: localStorage.getItem("selected_date")
        ? localStorage.getItem("selected_date")
        : new Date(),
      selected_time: null,
      note: null,
      send_sms: true,
      adj_selected_date: localStorage.getItem("selected_date")
        ? localStorage.getItem("selected_date")
        : new Date(),

      hoursData: {
        closed_hours: [],
        day_closed: false,
        free_hours: [],
        occupied_hours: [],
        appointment_hours: [],
        trans_hours: [],
      },

      unavailableData: {
        single: [], // ["11:00", "11:20"],
        single_per_day: [], // [["11:00", "11:20"], ["11:00", "11:20"]],
        between: [], // [{ start_hour: "10:00", end_hour: "10:20" }, { start_hour: "15:30", end_hour: "16:20" },],
        day_closed: false,
        all_hours_closed: false,
      },
      unavaliableHours: [],
      availableAppointmentExpanded: false,
      customerAddModal: false,
      isConfirmButtonChecked: false,
      infoDialog: true,
      customer_add_modal: false,
      serviceOrPackage: 1,
      settings: null,
      show_free: false,
      show_repeat_appo: false,
      selected_repeat_type: null,
      occurrence_count: null,
      preview_modal: false,
      previewResponseData: [],
      previewData: [],
      preview_loaded: true,
      preview_pagination: {
        total_page: null,
        page: 1,
        onChange: (page) => this.setState({
          preview_pagination: {
            ...this.state.preview_pagination,
            page: page
          }
        }),
      },
      previewRules: {
        package_id: null,
        staff_id: null,
        customer_id: null,
        selected_date: null,
        selected_time: null
      },
      repetition: [
        {
          id: "DAILY",
          full_name: "Her gün",
          count: 1,
          value_id: 1,
        },
        {
          id: "DAILY",
          full_name: "2 günde bir",
          count: 2,
          value_id: 2,
        },
        {
          id: "DAILY",
          full_name: "3 günde bir",
          count: 3,
          value_id: 3,
        },
        {
          id: "DAILY",
          full_name: "4 günde bir",
          count: 4,
          value_id: 4,
        },
        {
          id: "DAILY",
          full_name: "5 günde bir",
          count: 5,
          value_id: 5,
        },
        {
          id: "DAILY",
          full_name: "6 günde bir",
          count: 6,
          value_id: 6,
        },
        {
          id: 'WEEKLY',
          full_name: "Haftada bir",
          count: 1,
          value_id: 7,
        },
        {
          id: 'WEEKLY',
          full_name: "2 haftada bir",
          count: 2,
          value_id: 8,
        },
        {
          id: 'WEEKLY',
          full_name: "3 haftada bir",
          count: 3,
          value_id: 9,
        },
        {
          id: 'WEEKLY',
          full_name: "4 haftada bir",
          count: 4,
          value_id: 10,
        },
        {
          id: 'MONTHLY',
          full_name: "Her ay",
          count: 1,
          value_id: 11,
        },
        {
          id: 'MONTHLY',
          full_name: "2 ayda bir",
          count: 2,
          value_id: 12,
        },
        {
          id: 'MONTHLY',
          full_name: "3 ayda bir",
          count: 3,
          value_id: 13,
        },
        {
          id: 'MONTHLY',
          full_name: "6 ayda bir",
          count: 6,
          value_id: 14,
        },
      ]
    };
  }
  //TODO : Add a loading state
  getRequiredAppointmentArrays = () => {
    if (this.props.open) {
      Axios.get(`${this.context.api_endpoint}/company/appointments/index2`).then(
        ({ data }) => {
          // setServices([...data.data.services]);
          const staffs = data?.data?.map((staff) => {
            staff.services.forEach((service, index) => {
              if (
                service.service.category_name !== null &&
                service.service.category_name.toLowerCase().includes("erkek")
              ) {
                service.service.name += " (E)";
              } else if (
                service.service.category_name !== null &&
                service.service.category_name.toLowerCase().includes("kadın")
              ) {
                service.service.name += " (K)";
              }
            });
            return staff;
          });
          this.setState({
            employees: [...staffs.filter((staff) => staff !== null)],
            // services: [...data.data.services],
          });
        }
      );
    }
  };

  //TODO: search for customer by name
  searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${this.context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(({ data }) => (arr = [...data.data.records]));
    return arr.map((item) => {
      return {
        id: item.id,
        full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
        send_sms: item.send_sms,
        description: item.description,
      };
    });
  };

  searchPackagesById = async (key) => {
    let arr = [];
    await Axios.get(
      `${this.context.api_endpoint}/company/packet/byuser/${this.state.selected_employee ? this.state.selected_employee.id : null
      }`,
      {
        params: {
          page: -1,
        },
      }
    )
      .then(({ data }) => {
        const packages = data.data.map((p) => {
          if (
            p.packet.name !== null &&
            p.packet.name.toLowerCase().includes("erkek")
          ) {
            p.packet.name += " (E)";
          } else if (
            p.packet.name !== null &&
            p.packet.name.toLowerCase().includes("kadın")
          ) {
            p.packet.name += " (K)";
          }
          return p.packet;
        });
        arr = [...packages];
        this.setState({
          packagesList: [...packages],
        });
      })
      .catch((e) => {
        console.log(e);
      });
    return arr;
  };

  getSettingsAppointment = async () => {
    await Axios.get(`${this.context.api_endpoint}/company/platform/setting/index`).then(({ data }) => {
      this.setState({
        ...this.state,
        settings: data.data.appointment_request_overlapping
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  //TODO : unvalidate this function
  getStaffUnavailableHours = () => {
    const {
      selected_employee,
      selected_service,
      selected_package,
      serviceOrPackage,
    } = this.state;

    let selectDate = localStorage.getItem("selected_date")
      ? localStorage.getItem("selected_date")
      : new Date();

    this.setState({
      selected_date: selectDate,
    });

    let package_process_time_sum = 0;

    if (selected_package !== null && selected_package.packet_details) {
      package_process_time_sum = selected_package.packet_details.reduce((sum, detail) => {
        // Servis ve process_time'ın var olduğunu kontrol et
        if (detail?.service?.process_time) {
          return sum + detail.service.process_time;
        }
        return sum;
      }, 0);
    }

    // Process time kontrolü
    const process_time = serviceOrPackage === 1
      ? (selected_service?.process_time || 30) // Eğer servis process_time yoksa default 30 dakika
      : (package_process_time_sum || 30); // Eğer paket process_time toplamı 0 ise default 30 dakika

    if (!process_time || process_time <= 0) {
      toast.error("İşlem süresi geçersiz. Lütfen servis/paket seçiminizi kontrol edin.");
      return;
    }

    Axios.get(
      `${this.context.api_endpoint}/company/appointments/staff/available`,
      {
        params: {
          staff_id: parseInt(selected_employee.id),
          date: moment(selectDate).format("YYYY-MM-DD"),
          day_index: getDayIndex(moment(selectDate).format("YYYY-MM-DD")),
          process_time: parseInt(process_time),
        },
      }
    ).then(({ data }) => {
      this.setState({
        hoursData: {
          ...this.state.hoursData,
          occupied_hours: data.data.occupied_hours
            ? [
              ...data.data.occupied_hours?.map(
                (item) =>
                  `${item.split("T")[1].split(":")[0]}:${item.split("T")[1].split(":")[1]
                  }`
              ),
            ]
            : [],
          free_hours: data.data.free_hours
            ? [
              ...data.data.free_hours?.map(
                (item) =>
                  `${item.split("T")[1].split(":")[0]}:${item.split("T")[1].split(":")[1]
                  }`
              ),
            ]
            : [],
          closed_hours: data.data.closed_hours
            ? [
              ...data.data.closed_hours?.map(
                (item) =>
                  `${item.split("T")[1].split(":")[0]}:${item.split("T")[1].split(":")[1]
                  }`
              ),
            ]
            : [],
          day_closed: data.data.day_closed,
          appointment_requests: data.data.appointment_requests ? data.data.appointment_requests
            .map((item) =>
              BetweenTimeGenerator({
                slice: this.context.state.company_shift.slice,
                end_hour: `${item.app_end.split("T")[1].split(":")[0]}:${item.app_end.split("T")[1].split(":")[1]
                  }`,
                start_hour: `${item.app_start.split("T")[1].split(":")[0]
                  }:${item.app_start.split("T")[1].split(":")[1]}`,
                isSet: 1,
              })
            )
            .flat()
            : [],
          appointment_hours: data.data.app_overlap
            ? data.data.app_overlap
              .map((item) =>
                BetweenTimeGenerator({
                  slice: this.context.state.company_shift.slice,
                  end_hour: `${item.app_end.split("T")[1].split(":")[0]}:${item.app_end.split("T")[1].split(":")[1]
                    }`,
                  start_hour: `${item.app_start.split("T")[1].split(":")[0]
                    }:${item.app_start.split("T")[1].split(":")[1]}`,
                  isSet: 1,
                })
              )
              .flat()
            : [],
          trans_hours: data.data.closed_overlap
            ? data.data.closed_overlap
              .map((item) =>
                BetweenTimeGenerator({
                  slice: this.context.state.company_shift.slice,
                  end_hour: `${item.app_end.split("T")[1].split(":")[0]}:${item.app_end.split("T")[1].split(":")[1]
                    }`,
                  start_hour: `${item.app_start.split("T")[1].split(":")[0]
                    }:${item.app_start.split("T")[1].split(":")[1]}`,
                  isSet: 1,
                })
              )
              .flat()
            : [],
        },
      });

      setTimeout(() => {
        this.setState({
          availableAppointmentExpanded: true,
        });
      }, 500);
    }).catch(error => {
      if (error.response?.status === 400) {
        toast.error("Geçersiz işlem süresi. Lütfen servis/paket seçiminizi kontrol edin.");
      } else {
        toast.error("Bir hata oluştu. Lütfen tekrar deneyin.");
      }
    });
  };

  //TODO : Insert this function in the backend
  insertAppointment = () => {
    const {
      serviceOrPackage,
      selected_customer,
      selected_employee,
      selected_service,
      selected_package,
      selected_time,
      note,
      send_sms,
    } = this.state;

    let selectDate = localStorage.getItem("selected_date")
      ? localStorage.getItem("selected_date")
      : new Date();

    this.setState({
      selected_date: selectDate,
    });

    let appointment_date = new Date(selectDate);
    let appointment_time = selected_time.split(":");

    if (appointment_time[0] > 6) {
      appointment_date = `${moment(appointment_date).format("YYYY-MM-DD")}T${appointment_time[0]
        }:${appointment_time[1]}:00Z`;
    } else {
      appointment_date = `${moment(appointment_date)
        .add(1, "day")
        .format("YYYY-MM-DD")}T${appointment_time[0]}:${appointment_time[1]
        }:00Z`;
    }
    if (this.state.show_repeat_appo) {
      Axios.post(`${this.context.api_endpoint}/company/appointments/recurrents/preview`, {
        customer_id: selected_customer.id,
        staff_id: selected_employee.id,
        service_id: serviceOrPackage === 1 ? selected_service.id : null,
        recurring_appointment_rule: {
          frequency: this.state?.selected_repeat_type ? this.state.selected_repeat_type?.id : '',
          session_start_date: appointment_date,
          interval: this.state?.selected_repeat_type ? this.state.selected_repeat_type?.count : 1,
          occurrence_count: this.state.occurrence_count.id
        }
      }).then((response) => {
        if (response.status === 201 || response.status === 200) {
          toast.success(this.context.t(`['appointments/add'].insertToast`));
          //this.props.onNewAppointment();
          this.props.onClose()
          this.setState({
            previewData: [
              ...response.data.data.map((previewMap, index) => {
                return ({
                  id: index,
                  staff_id: this.state.employees?.find((el) => el?.id === previewMap?.staff_id)?.name + ' ' + this.state.employees?.find((el) => el?.id === previewMap?.staff_id)?.surname,
                  service_id: this.state.employees?.find((el) => el?.id === previewMap?.staff_id)?.services?.find((el) => el.serviceId === previewMap?.service_id)?.service?.name,
                  customer_full_name: selected_customer?.full_name,
                  repeat_time: this.state?.occurrence_count?.id,
                  start_date: previewMap.start_date,
                  end_date: previewMap.end_date,
                  start_finish_time: `${moment(previewMap.start_date).utc().format('HH:mm')}-${moment(previewMap.end_date).utc().format('HH:mm')}`,
                  is_available: previewMap.is_available ? process.env.APP_NAME === 'management' ? 'Active' : 'Aktif' : process.env.APP_NAME === 'management' ? 'Passive' : 'Pasif'
                })
              })
            ],
            previewRules: {
              staff_id: selected_employee.id,
              package_id: serviceOrPackage === 1 ? selected_service.id : null,
              interval: this.state.selected_repeat_type ? this.state.selected_repeat_type?.count : 1,
              customer_id: selected_customer.id,
              selected_date: appointment_date,
              selected_time: appointment_time
            },
            previewResponseData: [...response.data.data.map((data, index) => {
              return ({
                ...data,
                id: index
              })
            })],
            open: false,
            isConfirmButtonChecked: false,
            preview_modal: true,
          });
        }
      })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
            this.setState({
              isConfirmButtonChecked: false,
            });
          }
        })
        .finally(() => this.setState({ serviceOrPackage: 1 }));
    } else {
      Axios.post(`${this.context.api_endpoint}/company/appointment/insert`, {
        staff_id: selected_employee.id,
        customer_id: selected_customer.id,
        service_id: serviceOrPackage === 1 ? selected_service.id : null,
        packet_id: serviceOrPackage === 2 ? selected_package.id : null,
        send_sms: selected_customer
          ? selected_customer.send_sms === false
            ? false
            : send_sms
          : send_sms,
        note: note,
        appointment_date,
        is_web: true,
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(this.context.t(`['appointments/add'].insertToast`));

            this.props.onNewAppointment();
            this.setState({
              open: false,
              isConfirmButtonChecked: false,
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
            this.setState({
              isConfirmButtonChecked: false,
            });
          }
        })
        .finally(() => this.setState({ serviceOrPackage: 1 }));
    }
  };

  //TODO : Clear this function
  clearData = () => {
    this.setState({
      //customer: [],
      services: [],

      selected_customer: null,
      selected_employee: null,
      selected_service: null,
      selected_package: null,
      selected_date: new Date(),
      selected_time: null,
      //note: null,
      //send_sms: true,

      unavailableData: {
        single: [],
        single_per_day: [],
        between: [],
        day_closed: false,
        all_hours_closed: false,
      },
      unavaliableHours: [],
      availableAppointmentExpanded: false,
      customerAddModal: false,
    });
  };

  // Add costumer popup
  CustomerDialog = () => {
    const [setCustomerAddModal] = React.useState(false);

    React.useEffect(() => {
      flushSync(() => {
        this.setState({
          customer_add_modal: this.state.customer_add_modal,
        });
      });
    }, [this.state.customer_add_modal]);
    return (
      <CustomerAddDialog
        open={this.state.customer_add_modal}
        createdCustomerHandler={async (data) => {
          await this.setState({
            selected_customer: {
              ...data,
              full_name: `${data.name} ${data.surname}`,
            },
          });
          await this.setState({ create_appointment_modal: true });
        }}
        closeHandler={() =>
          this.setState({
            customer_add_modal: false,
          })
        }
        setOpen={setCustomerAddModal}
      />
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.open !== prevProps.open ||
      this.state.unavailableData !== prevState.unavailableData
    ) {
      if (this.props.open === true) {
        this.getRequiredAppointmentArrays();
      }
      this.setState({
        unavaliableHours: [
          ...DisabledAppointmentsGenerator(this.state.unavailableData),
        ],
      });
    }
    if (this.props.open !== prevProps.open && this.props.open === false) {
      this.clearData();
    }

    if (this.props.open !== prevProps.open && this.props.open === false) {
      this.setState({
        show_repeat_appo: false,
      })
    }

    if (prevState.selected_employee !== this.state.selected_employee) {
      this.setState({
        selected_package: null,
        selected_service: null,
      });
    }

    if (
      prevState.selected_package !== this.state.selected_package &&
      this.state.selected_package &&
      this.state.selected_package.packet_details
    ) {
      this.state.selected_package.packet_details.forEach((m) => {
        if (m?.service?.is_passive === true) {
          toast.warn(
            this.context.t("appointments.create.select_package_alert")
          );
          this.setState({
            selected_package: null,
          });
        }
      });
    }
  }

  helperText = (sttr, len = 0) => {
    return sttr.replace("%s", len);
  };

  //TODO Insert Random Customer
  insertRandomCustomer = () => {
    Axios.post(`${this.context.api_endpoint}/company/customer/insert/walkin`)
      .then((response) => { })
      .catch((e) => {
        toast.warning(e.response.data.message);
      });
  };

  approveRepeatAppointment = () => {
    Axios.post(`${this.context.api_endpoint}/company/appointments/recurrents`, {
      customer_id: this.state?.previewRules?.customer_id,
      staff_id: this.state.previewRules?.staff_id,
      service_id: this.state?.previewRules?.package_id,
      appointments: this.state?.previewResponseData?.map((data) => {
        return ({
          appointment_start_date: data.start_date,
          staff_id: data?.staff_id,
          service_id: data?.service_id,
          send_sms: this.state?.send_sms,
          note: this.state?.note
        })
      }),
      recurring_appointment_rule: {
        frequency: this.state?.selected_repeat_type ? this.state?.selected_repeat_type?.id : '',
        session_start_date: this.state?.previewRules?.selected_date,
        interval: this.state?.previewRules?.interval,
        occurrence_count: this.state?.occurrence_count?.id
      }
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          toast.success(this.context.t(`['appointments/add'].insertToast`));
          this.props.onNewAppointment();
          this.setState({
            open: false,
            isConfirmButtonChecked: false,
            preview_modal: !this.state.preview_modal,
            selected_repeat_type: null,
            occurrence_count: null,
          });
          this.clearData();
        }
      })
      .catch((e) => {
        toast.warning(e.response.data.message);
      });
  };

  componentDidMount() {
    this.getRequiredAppointmentArrays();
    if (this.state.settings !== false) {
      this.getSettingsAppointment()
    }
  }

  render() {
    return (
      <>
        <FullScreenDialog
          className="appointment-add-dialog"
          maxWidth="md"
          //open
          open={this.props.open}
          title={this.context.t(`component.NADDialog.createAppointment`)}
          onClose={this.props.onClose}
        >
          <Grid style={{ marginTop: "20px" }}>
            <Column className="xs-6 sm-6">
              <Column className="xs-12 sm-12 md-auto">
                <DatePickerInput
                  format="DD MMMM YYYY - dddd"
                  label="Randevu Tarihi"
                  textColor="primary"
                  showTodayButton={true}
                  minDate={new Date('2019-01-01')}
                  value={
                    localStorage.getItem("selected_date")
                      ? localStorage.getItem("selected_date")
                      : this.state.adj_selected_date
                  }
                  onChange={(date) => {
                    localStorage.setItem("selected_date", moment(date));
                    this.setState({
                      adj_selected_date: date,
                      selected_date: date,
                      dialog: false,

                      available_appointment_expanded: false,
                      selected_time: null,
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-auto">
                <Select
                  label={this.context.t(`['appointments/add'].staffInputLabel`)}
                  items={this.state.employees}
                  labelKey="full_name"
                  valueKey="id"
                  selected={
                    this.state.selected_employee !== null
                      ? `${this.state.selected_employee.id}`
                      : ""
                  }
                  returnFullObject
                  handler={(selected_employee) => {
                    this.setState({
                      services: [
                        ...selected_employee.services.map((service) => {
                          return service.service !== null
                            ? { ...service.service }
                            : undefined;
                        }),
                      ].filter((item) => item !== undefined),
                      selected_employee: selected_employee,
                      selected_time: null,
                      availableAppointmentExpanded: false,
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-auto">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    gap: "1px",
                    alignItems: "center",
                  }}
                >
                  {this.context.t(`appointments.create.selecting_service`)}
                  <Switch
                    checked={this.state.serviceOrPackage === 2 ? true : false}
                    onChange={(e) => {
                      this.setState({
                        serviceOrPackage: e.target.checked ? 2 : 1,
                      });
                    }}
                    color="default"
                    inputProps={{
                      "aria-label": "checkbox with default color",
                    }}
                  />
                  {this.context.t(`appointments.create.selecting_package`)}
                </div>
                {this.state.serviceOrPackage === 1 && (
                  <Autocomplete
                    id="service-autocomplete"
                    options={this.state.services}
                    getOptionLabel={(option) => option.name}
                    value={this.state.selected_service}
                    onChange={(event, newValue) => {
                      this.setState({
                        selected_service: newValue,
                        selected_time: null,
                        availableAppointmentExpanded: false,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={this.context.t(
                          `['appointments/add'].serviceInputLabel`
                        )}
                        variant="outlined"
                      />
                    )}
                  />
                )}
                {this.state.serviceOrPackage === 2 && (
                  <Autocomplete
                    id="combo-box-demo"
                    options={this.state.packagesList}
                    value={
                      this.state.selected_package
                        ? this.state.selected_package
                        : ""
                    }
                    onOpen={() => this.searchPackagesById(null)}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, selected_package) => {
                      this.setState({ selected_package });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={this.context.t(
                          `appointments.create.selectedPackageInputLabel`
                        )}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </Column>
              <Column className="xs-12 sm-12 md-auto">
                <CustomerSearchInput
                  autoHighlight
                  label={this.context.t(
                    `['appointments/add'].customerInputLabel`
                  )}
                  placeholder={this.context.t(
                    `['appointments/add'].customerInputPlaceholder`
                  )}
                  labelKey="full_name"
                  valueKey="id"
                  selected={
                    this.state.selected_customer !== null
                      ? this.state.selected_customer
                      : ""
                  }
                  returnFullObject
                  selectedHandler={(selected_customer) => {
                    this.setState({
                      selected_customer: selected_customer,
                      send_sms: selected_customer.send_sms,
                    });
                  }}
                />
                {/* <AutocompleteInput
                autoHighlight
                label={this.context.t(
                  `['appointments/add'].customerInputLabel`
                )}
                placeholder={this.context.t(
                  `['appointments/add'].customerInputPlaceholder`
                )}
                labelKey="full_name"
                valueKey="id"
                selected={
                  this.state.selected_customer !== null
                    ? this.state.selected_customer
                    : ""
                }
                returnFullObject
                selectedHandler={(selected_customer) => {
                  this.setState({
                    selected_customer: selected_customer,
                    send_sms: selected_customer.send_sms,
                  });
                }}
                asyncDataService={async (keyword) =>
                  this.searchCustomerByKey(keyword)
                }
              /> */}
              </Column>
            </Column>
            <Column className="xs-6 sm-6">
              <Column className="xs-12 sm-6">
                <Button
                  icon="add"
                  title={this.context.t(
                    `['appointments/add'].createNewCustomerButtonTitle`
                  )}
                  backgroundColor="primary-opacity"
                  textColor="primary"
                  fullWidth={true}
                  // size="lg"
                  onClick={() => {
                    this.setState({
                      customer_add_modal: true,
                    });
                    // this.props.history.push("/customers/add");
                  }}
                />
              </Column>
              <Column className="xs-12 sm-6">
                <Button
                  icon="add"
                  title={'Tekrarlayan Randevu'}
                  backgroundColor="green-opacity"
                  textColor="green"
                  fullWidth={true}
                  // size="lg"
                  onClick={() => {
                    this.setState({
                      show_repeat_appo: !this.state.show_repeat_appo,
                    });
                    // this.props.history.push("/customers/add");
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12">
                <CSwitch
                  className="mt-2"
                  label={this.context.t(`['appointments/add'].sendSmsInputLabel`)}
                  checked={this.state.send_sms}
                  color="primary"
                  disabled={
                    this.state.selected_customer &&
                    this.state.selected_customer.send_sms === false
                  }
                  onChange={(checked) => {
                    this.setState({
                      send_sms: checked.target.checked,
                    });
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12">
                <Input
                  className="mt-1"
                  label={this.context.t(`['appointments/add'].noteInputLabel`)}
                  multiline
                  rows={1}
                  helperText={this.context.t(
                    `['appointments/add'].noteInputHint`
                  )}
                  onChange={(e) => {
                    this.setState({
                      note: e.target.value.length <= 255 ? e.target.value : null,
                    });
                  }}
                />
              </Column>
              {this.state.show_repeat_appo && (
                <>
                  <Column className="xs-12 sm-6 mt-2">
                    <Select
                      label={"Tekrar Sıklığı"}
                      items={this.state.repetition}
                      labelKey="full_name"
                      valueKey="value_id"
                      selected={
                        this.state.selected_repeat_type ?
                          this.state.selected_repeat_type.value_id : ""
                      }
                      returnFullObject
                      handler={(repeats) => {
                        this.setState({
                          selected_repeat_type: repeats
                        })
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-6 mt-2">
                    <Select
                      label={'Tekrar Sayısı'}
                      items={Array(52).fill(null).map((_, index) => {
                        return ({
                          id: index + 1,
                          full_name: index + 1
                        })
                      })}
                      labelKey="full_name"
                      valueKey="id"
                      selected={
                        this.state.occurrence_count ?
                          this.state.occurrence_count.id : ""
                      }
                      returnFullObject
                      handler={(occurrence) => {
                        this.setState({
                          occurrence_count: occurrence
                        })
                      }}
                    />
                  </Column>
                </>
              )}
              <Column className="xs-12 sm-12">
                <Button
                  className={`${this.state.show_repeat_appo ? 'mt-0' : 'mt-2'} mx-auto`}
                  backgroundColor="primary"
                  fullWidth
                  // size="lg"
                  textColor="white"
                  icon="event"
                  title={this.context.t(
                    `['appointments/add'].availableHoursButtonTitle`
                  )}
                  disabled={
                    this.state.show_repeat_appo
                      ? !(
                        this.state.occurrence_count !== null &&
                        this.state.selected_repeat_type !== null &&
                        this.state.selected_customer !== null &&
                        this.state.selected_employee !== null &&
                        (this.state.selected_service !== null || this.state.selected_package !== null) &&
                        this.state.selected_date !== null
                      )
                      : !(
                        this.state.selected_customer !== null &&
                        this.state.selected_employee !== null &&
                        (this.state.selected_service !== null || this.state.selected_package !== null) &&
                        this.state.selected_date !== null
                      )
                  }
                  onClick={() => {
                    this.getStaffUnavailableHours();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12" style={{ marginTop: '-15px', marginBottom: '15px' }}>
                <CSwitch
                  className="mt-3"
                  label={this.context.t(
                    `['appointments/add'].onlyActiveSwitch`
                  )}
                  checked={this.state.show_free}
                  color="primary"
                  onChange={(checked) => {
                    this.setState({
                      show_free: checked.target.checked,
                    });
                  }}
                />
              </Column>
            </Column>
            {this.state.availableAppointmentExpanded === true ? (
              <>
                <Grid className="mx-4 my-2" style={{ margin: "0 30px", maxHeight: '220px', overflowY: 'scroll' }}>
                  {this.state.show_free ? this.state?.hoursData?.free_hours?.map(
                    (item, index) => {
                      const isDayClosed = this.state.hoursData.day_closed;
                      const isClosedHour = this.state.hoursData.closed_hours.includes(item);
                      const isTransHour = this.state.hoursData.trans_hours.includes(item);
                      const isOccupiedHour = this.state.hoursData.occupied_hours.includes(item);
                      const isAppointmentHour = this.state.hoursData.appointment_hours.includes(item);
                      const isAppointmentRequest = this.state.hoursData.appointment_requests.includes(item);
                      const isSelectedTime = this.state.selected_time === item;

                      let borderColor;

                      if (isDayClosed || isClosedHour) {
                        borderColor = isTransHour ? "1px solid gray" : "1px solid purple";
                      } else if (isAppointmentRequest) {
                        borderColor = "1px solid orange";
                      } else if (isOccupiedHour) {
                        borderColor = isAppointmentHour ? "1px solid red" : "1px solid purple";
                      } else {
                        borderColor = "1px solid green";
                      }

                      const backgroundColor = (isDayClosed || isClosedHour) && isSelectedTime
                        ? isTransHour
                          ? "gray"
                          : "purple"
                        : isAppointmentRequest && isSelectedTime
                          ? "orange"
                          : isOccupiedHour && isSelectedTime
                            ? isAppointmentHour
                              ? "red"
                              : "purple"
                            : isSelectedTime
                              ? "green"
                              : "white";

                      const textColor = isDayClosed || isClosedHour
                        ? isTransHour
                          ? "gray"
                          : "purple"
                        : isAppointmentRequest
                          ? isSelectedTime
                            ? "white"
                            : "orange"
                          : isSelectedTime
                            ? "white"
                            : isOccupiedHour
                              ? isAppointmentHour
                                ? "red"
                                : "purple"
                              : "green";

                      const cursor = isAppointmentRequest ? !this.state.settings ? "not-allowed" : "pointer" : isDayClosed || isClosedHour
                        ? isTransHour
                          ? "not-allowed"
                          : "pointer"
                        : "pointer";

                      return (
                        <div
                          key={index}
                          className="col xs-1 sm-1 md-1 lg-1 xl-1 mb-1 mx-3"
                          style={{
                            cursor: cursor,
                            margin: "5px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30px",
                            maxWidth: "120px",
                            borderRadius: "20px",
                            border: borderColor,
                            backgroundColor,
                            color: textColor,
                          }}
                          onClick={() => {
                            if (isAppointmentRequest && this.state.settings === false) {
                              return null
                            } else {
                              if (item.split(":")[0] < 6) {
                                this.setState({
                                  adj_selected_date: moment(
                                    this.state.selected_date
                                  ).add(1, "day"),
                                });
                              } else {
                                this.setState({
                                  adj_selected_date: this.state.selected_date,
                                });
                              }
                              if (
                                !(
                                  this.state.hoursData.day_closed ||
                                  (this.state.hoursData.closed_hours.includes(
                                    item
                                  ) &&
                                    this.state.hoursData.trans_hours.includes(item))
                                )
                              ) {
                                this.setState({
                                  selected_time: item,
                                });
                              }
                            }
                          }}
                        >
                          {item}
                        </div>
                      );
                    }
                  ) : BetweenTimeGenerator(this.context.state.company_shift)?.map(
                    (item, index) => {
                      const isDayClosed = this.state.hoursData.day_closed;
                      const isClosedHour = this.state.hoursData.closed_hours.includes(item);
                      const isTransHour = this.state.hoursData.trans_hours.includes(item);
                      const isOccupiedHour = this.state.hoursData.occupied_hours.includes(item);
                      const isAppointmentHour = this.state.hoursData.appointment_hours.includes(item);
                      const isAppointmentRequest = this.state.hoursData.appointment_requests.includes(item);
                      const isSelectedTime = this.state.selected_time === item;

                      let borderColor;

                      if (isDayClosed || isClosedHour) {
                        borderColor = isTransHour ? "1px solid gray" : "1px solid purple";
                      } else if (isAppointmentRequest) {
                        borderColor = "1px solid orange";
                      } else if (isOccupiedHour) {
                        borderColor = isAppointmentHour ? "1px solid red" : "1px solid purple";
                      } else {
                        borderColor = "1px solid green";
                      }

                      const backgroundColor = (isDayClosed || isClosedHour) && isSelectedTime
                        ? isTransHour
                          ? "gray"
                          : "purple"
                        : isAppointmentRequest && isSelectedTime
                          ? "orange"
                          : isOccupiedHour && isSelectedTime
                            ? isAppointmentHour
                              ? "red"
                              : "purple"
                            : isSelectedTime
                              ? "green"
                              : "white";

                      const textColor = isDayClosed || isClosedHour
                        ? isTransHour
                          ? "gray"
                          : "purple"
                        : isAppointmentRequest
                          ? isSelectedTime
                            ? "white"
                            : "orange"
                          : isSelectedTime
                            ? "white"
                            : isOccupiedHour
                              ? isAppointmentHour
                                ? "red"
                                : "purple"
                              : "green";

                      const cursor = isAppointmentRequest ? !this.state.settings ? "not-allowed" : "pointer" : isDayClosed || isClosedHour
                        ? isTransHour
                          ? "not-allowed"
                          : "pointer"
                        : "pointer";

                      return (
                        <div
                          key={index}
                          className="col xs-1 sm-1 md-1 lg-1 xl-1 mb-1 mx-3"
                          style={{
                            cursor: cursor,
                            margin: "5px 3px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "30px",
                            maxWidth: "120px",
                            borderRadius: "20px",
                            border: borderColor,
                            backgroundColor,
                            color: textColor,
                          }}
                          onClick={() => {
                            if (isAppointmentRequest && this.state.settings === false) {
                              return null
                            } else {
                              if (item.split(":")[0] < 6) {
                                this.setState({
                                  adj_selected_date: moment(
                                    this.state.selected_date
                                  ).add(1, "day"),
                                });
                              } else {
                                this.setState({
                                  adj_selected_date: this.state.selected_date,
                                });
                              }
                              if (
                                !(
                                  this.state.hoursData.day_closed ||
                                  (this.state.hoursData.closed_hours.includes(
                                    item
                                  ) &&
                                    this.state.hoursData.trans_hours.includes(item))
                                )
                              ) {
                                this.setState({
                                  selected_time: item,
                                });
                              }
                            }
                          }}
                        >
                          {item}
                        </div>
                      );
                    }
                  )}
                </Grid>
                <Grid>
                  <MuiThemeProvider theme={theme}>
                    <Tooltip
                      style={{ backgroundColor: "white", marginLeft: "30px" }}
                      title={
                        <Column
                          style={{ backgroundColor: "white" }}
                          className="xs-12 sm-12 p-2"
                        >
                          <Column className="xs-12 sm-12">
                            <ColorMean>
                              <div
                                className="color"
                                style={{ background: "green" }}
                              >
                                <div className="overlay" />
                              </div>
                              <span>
                                Yeşil renk ile gösterilen saatler randevu alımına
                                açık olan saatlerdir.
                              </span>
                            </ColorMean>
                          </Column>
                          <Column className="xs-12 sm-12 mt-2">
                            <ColorMean>
                              <div
                                className="color"
                                style={{ background: "orange" }}
                              >
                                <div className="overlay" />
                              </div>
                              <span>
                                Turuncu renk ile gösterilen saatler online randevu talebi olan saatlerdir.
                              </span>
                            </ColorMean>
                          </Column>
                          <Column className="xs-12 sm-12 mt-2">
                            <ColorMean>
                              <div
                                className="color"
                                style={{ background: "red" }}
                              >
                                <div className="overlay" />
                              </div>
                              <span>
                                Kırmızı renk ile gösterilen saatler daha önce
                                randevu oluşturulmuş saatlerdir.
                              </span>
                            </ColorMean>
                          </Column>
                          <Column className="xs-12 sm-12 mt-2">
                            <ColorMean>
                              <div
                                className="color"
                                style={{ background: "purple" }}
                              >
                                <div className="overlay" />
                              </div>
                              <span>
                                Mor ile gösterilen saatlere randevu alınması
                                durumunda mevcut bir randevu veya kapalı olan bir
                                saatle çakışma ihtimali olabilir.
                              </span>
                            </ColorMean>
                          </Column>
                          <Column className="xs-12 sm-12 mt-2">
                            <ColorMean>
                              <div
                                className="color"
                                style={{ background: "gray" }}
                              >
                                <div className="overlay" />
                              </div>
                              <span>
                                Gri renk ile gösterilen saatler personel detayı
                                veya randevu takvimi üzerinden kapatılan
                                saatlerdir.
                              </span>
                            </ColorMean>
                          </Column>
                        </Column>
                      }
                      placement="right"
                    >
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </MuiThemeProvider>
                </Grid>
                {this.state.selected_time && (
                  <Button
                    style={{ margin: "-20px auto 15px" }}
                    icon="check"
                    title={this.context.t(
                      `['appointments/add'].confirmButtonHoursTitle`
                    )}
                    backgroundColor="primary"
                    textColor="white"
                    onClick={() => {
                      this.setState({
                        isConfirmButtonChecked: true,
                      });
                      this.insertAppointment();
                      this.clearData();
                    }}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </Grid>
          <this.CustomerDialog />
        </FullScreenDialog>
        <FullScreenDialog
          className="appointment-add-dialog"
          maxWidth="md"
          //open
          open={this.state.preview_modal}
          title={'Önizle'}
          onClose={() => this.setState({
            preview_modal: !this.state.preview_modal
          })}
        >

          <Grid className='d-flex flex-row p-3' style={{ maxHeight: '600px', overflowY: 'scroll', width: '93%' }}>
            <Table
              refreshAction={() => {
              }}
              loaded={this.state.preview_loaded}
              headings={{
                // start_date: {
                //   label: "Randevu Tarihi",
                //   customStyle: (row) => ({ marginLeft: '5px', backgroundColor: row.is_available ? '' : '#ffc2c2' })
                // },
                start_date: {
                  label: 'Randevu Tarihi',
                  customStyle: (row) => ({ marginLeft: '5px', backgroundColor: row.is_available ? '' : '#ffc2c2' }),
                  render: (row, renderIndex) => {
                    return (
                      <>
                        {/* <DatePickerInput
                          format="DD MMMM YYYY - dddd"
                          textColor="primary"
                          showTodayButton={true}
                          minDate={new Date('2019-01-01')}
                          value={
                            moment(row.start_date)?.utcOffset('+03:00')?.format('DD MMMM YYYY - dddd')
                          }
                          onChange={(date) => {
                            console.log(moment(date))
                            // localStorage.setItem("selected_date", moment(date));
                            // this.setState({
                            //   adj_selected_date: date,
                            //   selected_date: date,
                            //   dialog: false,

                            //   available_appointment_expanded: false,
                            //   selected_time: null,
                            // });
                          }}
                        /> */}
                        <DatePickerInput
                          textColor="primary"
                          format="DD/MM/YYYY"
                          showTodayButton={true}
                          inputStyle={{ width: '80px' }}
                          value={moment.utc(row.start_date).format("MM/DD/YYYY")}
                          onChange={(date) => {
                            const dateInput = moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
                            const timeInput = moment.utc(row.start_date).format("HH:mm:ss.SSS");

                            // UTC'de tarih ve saat bilgisi oluştur
                            const isoDate = moment.utc(`${dateInput} ${timeInput}`, "YYYY-MM-DD HH:mm:ss.SSS").toISOString();
                            this.setState((prevState) => ({
                              previewData: prevState.previewData.map((data) => {
                                if (data.id === row.id) {
                                  return {
                                    ...data,
                                    start_date: isoDate,
                                  };
                                }
                                return data;
                              }),
                              previewResponseData: prevState.previewResponseData.map((data, index) => {
                                if (data.id === row.id) {
                                  return {
                                    ...data,
                                    start_date: isoDate,
                                  };
                                }
                                return data;
                              }),
                            }));
                          }}
                        />
                      </>
                    )
                  }
                },
                "customer_full_name": {
                  label: this.context.t(
                    `['appointments/filter'].headings.customerFullName`
                  ),
                  customStyle: (row) => ({ backgroundColor: row.is_available ? '' : '#ffc2c2' })
                },
                // "is_available": {
                //   label: 'Aktiflik Durumu',
                //   customStyle: (row) => ({ backgroundColor: row.is_available ? '#d9fce5' : '#ffc2c2' })
                // },
                "service_id": {
                  label: this.context.t(
                    `['appointments/filter'].headings.serviceName`
                  ),
                  customStyle: (row) => ({ backgroundColor: row.is_available ? '' : '#ffc2c2' })
                },
                "staff_id": {
                  label: this.context.t(
                    `['appointments/filter'].headings.staffFullName`
                  ),
                  customStyle: (row) => ({ backgroundColor: row.is_available ? '' : '#ffc2c2' })
                },
                start_finish_time: {
                  label: 'Başlangıç-Bitiş',
                  customStyle: (row) => ({ backgroundColor: row.is_available ? '' : '#ffc2c2' })
                }
                // repeat_time: {
                //   label: 'Tekrar Sıklığı'
                // },
                // end_date: {
                //   label: 'Son Randevu Tarihi'
                // },
              }}
              rows={this.state.previewData}
              pagination={this.state.preview_pagination}
            // onRowClick={(row) => {
            //   try {
            //     const startDate = moment(row.appointment_start_date, "DD MMMM YYYY HH:mm");
            //     const endDate = moment(row.appointment_end_date);

            //     setHourDetail({
            //       start_hour: startDate.format("HH:mm"),
            //       end_hour: endDate.format("HH:mm")
            //     });
            //     const appointmentData = {
            //       ...row,
            //       start_at: startDate.format("YYYY-MM-DD HH:mm:ss"),
            //       end_at: endDate.format("YYYY-MM-DD HH:mm:ss"),
            //       packet: row.packet || null,
            //       service: row.service || null
            //     };

            //     setAppointmentDate(startDate.toDate());
            //     setSelectedStaff(row.staff);
            //     set_Appointment(appointmentData);
            //     setAppointmentDetailModal(true);

            //   } catch (error) {
            //     console.error("Error in onRowClick:", error);
            //     toast.error("Randevu detayı açılırken bir hata oluştu");
            //   }
            // }}
            />
          </Grid>
          <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3, marginBottom: '15px' }} >
            <Button
              icon="check"
              title={'Onayla'}
              backgroundColor="primary"
              textColor="white"
              onClick={() => {
                this.approveRepeatAppointment()
              }}
            />
            <Button
              style={{ marginLeft: '8px' }}
              title={'iptal'}
              icon='close'
              backgroundColor="grey-opacity"
              textColor="grey"
              onClick={() => {
                this.setState({
                  preview_modal: !this.state.preview_modal
                });
                this.clearData();
              }}
            />
          </Grid>
        </FullScreenDialog>
      </>
    );
  }
}

export default NewAppointmentDialog;

const ColorMean = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  justify-content: center;

  &:nth-last-child(1) {
    margin: 0;
  }

  div.color {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    margin-right: 8px;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        -180deg,
        rgba(255, 255, 255, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  span {
    flex: 1;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    color: #000000;
  }
`;

import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import styled from "styled-components";

import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Button from "../../../theme/Button";
import Axios from "axios";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import {
  Backdrop,
  ButtonBase,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Select from "../../../theme/CustomMUI/Select";
import AutocompleteInput from "../../../theme/CustomMUI/AutoComplete";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { flushSync } from "react-dom";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ServicesAdd = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [newService, setNewService] = useState({
    amount: 0,
    bounty: 0,
    point: 0,
    process_time: null,
    repeat: null,
    show_at_url: true,
    staffs: [],
    description: null,
    is_campaign: 0,
    campaign_title: null,
  });

  const [serviceCategories, setServiceCategories] = useState([]);
  const [serviceNames, setServiceNames] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedServiceName, setSelectedServiceName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const [selectAllEmployees, setSelectAllEmployees] = useState(true);

  const [staffs, setStaffs] = useState({});

  const [isInsertServiceButtonClicked, setIsInsertServiceButtonClicked] =
    useState(false);

  const [openNewServiceDialog, setOpenNewServiceDialog] = useState(false);

  const [moneyOrPercent, setMoneyOrPercent] = useState(false);
  const [dontWantCategory, setDontWantCategory] = useState(false);
  const [justServiceName, setJustServiceName] = useState(null);

  const insertService = () => {
    validateService() &&
      Axios.post(`${context.api_endpoint}/company/service/insert`, {
        ...newService,
        staffs:
          Object.keys(staffs) && Object.keys(staffs).length > 0
            ? Object.keys(staffs)
              .map((key) => (staffs[key].active ? staffs[key].id : undefined))
              .filter((item) => item !== undefined)
            : [],
        [dontWantCategory ? "name" : "platform_service_id"]: dontWantCategory
          ? justServiceName
          : selectedServiceName
            ? selectedServiceName.id
            : null,
        type: !moneyOrPercent ? 1 : null,
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(context.t(`['services/detail'].insertToast`));
            history.push("/services");
            //console.log(response.data)
          }
          setIsInsertServiceButtonClicked(false);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
          setIsInsertServiceButtonClicked(false);
        });
  };

  const getStaffs = () => {
    Axios.get(`${context.api_endpoint}/company/staffs/unsafe`).then(
      (response) => {
        if (response.data.data && response.data.data.length > 0)
          setStaffs(
            Object.assign(
              ...response.data.data.map((staff) => ({
                [staff.id]: { ...staff, active: true },
              })),
              {}
            )
          );
      }
    );
  };

  const getServiceCategories = async (key) => {
    await Axios.get(
      `${context.api_endpoint}/company/services/category/list/all`,
      {
        params: {
          name: key === " " ? "" : key,
        },
      }
    )
      .then(({ data }) => {
        flushSync(() => {
          setServiceCategories([...data.data]);
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })

    return serviceCategories;
  };

  const getServiceNames = async (key) => {
    await Axios.get(
      `${context.api_endpoint}/company/platform/services/list/all`,
      {
        params: {
          paginate: false,
          categoryid: categoryId,
          name: key === " " ? "" : key,
        },
      }
    ).then((response) => {
      const { data } = response;
      flushSync(() => {
        setServiceNames([...data.data]);
      });
    })

    return serviceNames;
  };

  useEffect(() => {
    getStaffs();
  }, []);

  const validateService = () => {
    return Validate([
      !dontWantCategory && {
        field: context.t(`['services/detail'].validate.serviceCategory`),
        value: selectedCategory,
        condition: Object,
      },
      !dontWantCategory
        ? {
          field: context.t(`['services/detail'].validate.servicesName`),
          value: selectedServiceName,
          condition: Object,
        }
        : {
          field: context.t(`['services/detail'].nameInputLabel`),
          value: justServiceName,
          condition: String,
        },
      {
        field: context.t(`['services/detail'].validate.servicePrice`),
        value: newService.amount,
        condition: Number,
      },
      {
        field: context.t(`['services/detail'].validate.servicesProcessTime`),
        value: newService.process_time,
        condition: Number,
      },
    ]);
  };

  const StaffCheckbox = ({ staff, _key }) => (
    <ButtonBase
      style={{ width: "100%", marginBottom: 4 }}
      onClick={() => {
        if (Object.keys(staffs).filter((key) => key.active === true).length !== staffs.length) {
          setSelectAllEmployees(false);
        } else {
          setSelectAllEmployees(true);
        }
        setStaffs({
          ...staffs,
          [_key]: {
            ...staffs[_key],
            active: !staffs[_key].active,
          },
        });
      }}
    >
      <StaffButtonContainer
        className={staff?.active ? "fc-blue bg-blue-opacity" : ""}
        style={{
          borderRadius: 5,
          border: "1px solid",
          borderColor: staff?.active ? "rgba(49, 137, 236, 0.1)" : "transparent",
        }}
      >
        <i className="material-icons">
          {staff?.active ? "check_box" : "check_box_outline_blank"}
        </i>
        <p style={{ width: "250px" }}>
          {staff?.name} {staff?.surname}
        </p>
        <StaffPhotoContainer>
          <StaffPhoto
            src={
              staff?.detail?.profile_photo
                ? `${context.api_endpoint}/${context.state.company_id}/${staff?.detail?.profile_photo}`.replace(
                  "api/v1/",
                  ""
                )
                : require("../../../assets/images/profile_photo.svg")
            }
            alt={`${staff?.name} ${staff?.surname}`}
          />
        </StaffPhotoContainer>
      </StaffButtonContainer>
    </ButtonBase>
  );

  const ServiceInformation = () => {


    const requestDialogHandler = () => {
      setOpenNewServiceDialog(true);
    };

    return (
      <ServiceInformationContainer>
        <p>
          {context.t(`[services/detail].serviceName`)}{" "}
          <span style={{ borderBottom: "1px solid black" }}>
            {context.t(`[services/detail].lookingFor`)}
          </span>{" "}
          {context.t(`[services/detail].ourList`)}{" "}
          <span className="button" onClick={requestDialogHandler}>
            {context.t(`[services/detail].createRequest`)}
          </span>
        </p>
      </ServiceInformationContainer>
    );
  };

  const [newServiceCategory, setNewServiceCategory] = useState(null);
  const [newServiceTitle, setNewServiceTitle] = useState(null);

  const closeDialogHandler = () => {
    setOpenNewServiceDialog(false);
  };

  const cancelRequest = () => {
    setOpenNewServiceDialog(false);
    setNewServiceTitle(null);
  };

  const makeNewServiceRequest = () => {
    if (newServiceTitle === null) {
      setOpenNewServiceDialog(true);
      toast.error(
        context.t(`['services/detail'].newServiceRequestErrorToast`)
      );
    } else {
      // Axios
      // Close dialog after a success process.
      // Make newServiceCategory and newServiceTitle null.
      Axios.post(`${context.api_endpoint}/company/services/request/insert`, {
        name: newServiceTitle,
        categories_id: newServiceCategory ? newServiceCategory.id : null,
      })
        .then(({ data }) => {
          toast.success(
            context.t(`['services/detail'].newServiceRequestToast`)
          );
          setOpenNewServiceDialog(false);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        })
        .finally(() => {
          setNewServiceCategory(null);
          setNewServiceTitle(null);
        });
    }
  };

  useEffect(() => {
    if (categoryId) {
      getServiceNames()
    }
  }, [categoryId])

  return (
    <AuthContainer authorities={[1]} limited_permission="services">
      <Grid className="no-gutters-all">
        <Grid className="xs-12 sm-12 md-12 lg-12 mb-2">
          {<ServiceInformation />}
          <AlertDialog
            title={context.t(`['services/detail'].newServiceRequestDialogTitle`)}
            buttons={[
              {
                title: context.t(`['services/detail'].cancelNewServiceRequest`),
                icon: "close",
                backgroundColor: "white",
                textColor: "grey",
                onClick: () => {
                  cancelRequest();
                },
              },
              {
                title: context.t(`['services/detail'].createNewServiceRequest`),
                icon: "check",
                backgroundColor: "primary-opacity",
                textColor: "primary",
                onClick: () => {
                  makeNewServiceRequest();
                },
              },
            ]}
            fullWidth
            disableBackdropClick={false}
            maxWidth={"sm"}
            open={openNewServiceDialog}
            closeHandler={closeDialogHandler}
            containerStyle={{ overflow: "visible" }}
            overflowContent={false}
          >
            <div>
              <AutocompleteInput
                required
                label={context.t(`['services/detail'].serviceCategoryInput`)}
                hoverHelperText={context.t(
                  `['services/detail'].newServiceCategoryHint`
                )}
                labelKey="name"
                valueKey="id"
                returnFullObject
                onRemove={async () => {
                  setNewServiceCategory(null);
                }}
                selected={newServiceCategory || null}
                selectedHandler={async (selected_category) =>
                  setNewServiceCategory(selected_category)
                }
                asyncDataService={async (keyword) => getServiceCategories(keyword)}
              />
              <Input
                required
                label={context.t(`['services/detail'].newServiceInputTitle`)}
                value={newServiceTitle !== null ? newServiceTitle : ""}
                onChange={(e) => setNewServiceTitle(e.target.value)}
              />
            </div>
          </AlertDialog>
        </Grid>
        <Column className="xs-12 sm-12 md-12 lg-9">
          <Switch
            checked={dontWantCategory}
            onChange={(e) => {
              setDontWantCategory(!dontWantCategory);
            }}
          />
          Continue Without Category
          <Grid>
            <Column className="xs-12 sm-12 md-6">
              <AutocompleteInput
                disabled={dontWantCategory}
                required
                label={context.t(`['services/detail'].serviceCategoryInput`)}
                //helperText={context.t(`['services/detail'].serviceCategoryHint`)}
                hoverHelperText={context.t(
                  `['services/detail'].serviceCategoryHint`
                )}
                labelKey="name"
                valueKey="id"
                returnFullObject
                onRemove={() => {
                  setSelectedCategory(null);
                  setSelectedServiceName(null);
                  setCategoryId(null);
                  getServiceCategories("");
                }}
                selected={selectedCategory !== null ? selectedCategory : ""}
                selectedHandler={(selected_category) => {
                  setSelectedCategory(selected_category);
                  setCategoryId(selected_category.id);
                }}
                asyncDataService={async (keyword) =>
                  getServiceCategories(keyword)
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-6">
              {!dontWantCategory ? (
                <AutocompleteInput
                  required
                  disabled={selectedCategory || dontWantCategory ? false : true}
                  label={context.t(`['services/detail'].nameInputLabel`)}
                  hoverHelperText={context.t(
                    `['services/detail'].serviceNameHint`
                  )}
                  labelKey="name"
                  valueKey="id"
                  returnFullObject
                  onRemove={() => {
                    setSelectedServiceName(null);
                    getServiceNames("");
                  }}
                  selected={
                    selectedServiceName !== null ? selectedServiceName : ""
                  }
                  selectedHandler={(selected_category_name) =>
                    setSelectedServiceName(selected_category_name)
                  }
                  asyncDataService={async (keyword) => getServiceNames(keyword)}
                />
              ) : (
                <Input
                  label={context.t(`['services/detail'].nameInputLabel`)}
                  onChange={(e) => {
                    setJustServiceName(e.target.value.trim());
                  }}
                  hoverHelperText={context.t(
                    `['services/detail'].serviceNameHint`
                  )}
                />
              )}
            </Column>
          </Grid>
          <Grid>
            <Column className="xs-12 sm-12 md-6">
              <Input
                required
                label={
                  context.t(`['services/detail'].amountInputLabel`) +
                  (context.state.currency
                    ? " (" + context.state.currency + ")"
                    : "(₺)")
                }
                value={newService.amount ?? ""}
                //helperText={context.t(`['services/detail'].amountInputHint`)}
                hoverHelperText={context.t(
                  `['services/detail'].amountInputHint`
                )}
                type="number"
                InputProps={{
                  min: 0,
                  step: 0.1,
                }}
                onChange={(e) =>
                  setNewService({
                    ...newService,
                    amount:
                      e.target.value.length > 0
                        ? Math.round(parseFloat(e.target.value).toFixed(2))
                        : null,
                  })
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-6">
              <span style={{ fontSize: "12px", opacity: "0.8" }}>
                {context.t(`['services/detail'].bountySelectMoney`)}
              </span>
              <Switch
                checked={moneyOrPercent ? true : false}
                onChange={() => {
                  setNewService({
                    ...newService,
                    bounty: 0,
                  });
                  setMoneyOrPercent(!moneyOrPercent);
                }}
                color="default"
                inputProps={{
                  "aria-label": "checkbox with default color",
                }}
              />
              <span style={{ fontSize: "12px", opacity: "0.8" }}>
                {context.t(`['services/detail'].bountySelectPer`)}
              </span>
              <MaskedInput
                label={
                  moneyOrPercent
                    ? context.t(`['services/detail'].bountyInputLabel`)
                    : `Prim Tutarı ${context.state.currency
                      ? " (" + context.state.currency + ")"
                      : "(₺)"
                    }`
                }
                value={newService.bounty ?? 0}
                //helperText={context.t(`['services/detail'].bountyInputHint`)}
                hoverHelperText={context.t(
                  `['services/detail'].bountyInputHint`
                )}
                mask={moneyOrPercent ? "99" : "99999999999"}
                maskChar=""
                onChange={(e) =>
                  setNewService({
                    ...newService,
                    bounty: e.target.value ? parseInt(e.target.value) : 0,
                  })
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-6">
              <MaskedInput
                label={context.t(`['services/detail'].pointInputLabel`)}
                value={newService.point ?? ""}
                //helperText={context.t(`['services/detail'].pointInputHint`)}
                hoverHelperText={context.t(
                  `['services/detail'].pointInputHint`
                )}
                mask="99999"
                maskChar=""
                onChange={(e) =>
                  setNewService({
                    ...newService,
                    point: e.target.value ? parseInt(e.target.value) : null,
                  })
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-6">
              <MaskedInput
                required
                label={context.t(`['services/detail'].timeInputLabel`)}
                value={newService.process_time ?? ""}
                //helperText={context.t(`['services/detail'].timeInputHint`)}
                hoverHelperText={context.t(`['services/detail'].timeInputHint`)}
                mask="999"
                maskChar=""
                type="text"
                onChange={(e) =>
                  setNewService({
                    ...newService,
                    process_time: e.target.value
                      ? parseInt(e.target.value)
                      : null,
                  })
                }
              />
            </Column>
          </Grid>
          <Grid>
            <Column className="xs-12 sm-12 md-6">
              <MaskedInput
                label={context.t(`['services/detail'].repeatInputLabel`)}
                value={newService.repeat ?? ""}
                //helperText={context.t(`['services/detail'].repeatInputHint`)}
                hoverHelperText={context.t(
                  `['services/detail'].repeatInputHint`
                )}
                mask="999"
                maskChar=""
                type="text"
                onChange={(e) =>
                  setNewService({
                    ...newService,
                    repeat: e.target.value ? parseInt(e.target.value) : null,
                  })
                }
              />
            </Column>
            <Column className="xs-12 sm-12 md-6">
              <Select
                label={context.t(`['services/detail'].urlInputLabel`)}
                //helperText={context.t(`['services/detail'].urlInputHint`)}
                hoverHelperText={context.t(`['services/detail'].urlInputHint`)}
                items={[
                  {
                    label: context.t(`['services/detail'].urlInputTrue`),
                    value: true,
                  },
                  {
                    label: context.t(`['services/detail'].urlInputFalse`),
                    value: false,
                  },
                ]}
                labelKey="label"
                valueKey="value"
                selected={`${newService.show_at_url}`}
                handler={(show_at_url) =>
                  setNewService({
                    ...newService,
                    show_at_url: show_at_url === "true",
                  })
                }
              />
            </Column>

            <Column className="xs-12 sm-12 md-6">
              <Input
                label={context.t(`['services/detail'].serviceDescriptionLabel`)}
                value={newService.description ?? ""}
                hoverHelperText={context.t(
                  `['services/detail'].serviceDescriptionHint`
                )}
                onChange={(e) =>
                  setNewService({
                    ...newService,
                    description: e.target.value,
                  })
                }
              />
            </Column>
          </Grid>
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-3">
          <StaffBox>
            <SectionTitle>
              {context.t(`['services/detail'].staffSectionTitle`)}
            </SectionTitle>
            <FormControlLabel
              checked={selectAllEmployees}
              control={<Checkbox color="primary" size="small" />}
              label={context.t(`['services/detail'].selcetAllEmployees`)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectAllEmployees(true);
                  const updatedStaffs = Object.keys(staffs).reduce((result, key) => {
                    result[key] = {
                      ...staffs[key],
                      active: true,
                    };
                    return result;
                  }, {});

                  setStaffs(updatedStaffs);
                } else {
                  setSelectAllEmployees(false);
                  const updatedStaffs = Object.keys(staffs).reduce((result, key) => {
                    result[key] = {
                      ...staffs[key],
                      active: false,
                    };
                    return result;
                  }, {});

                  setStaffs(updatedStaffs);
                }
              }}
            />
            <StaffListBox>
              {Object.keys(staffs) &&
                Object.keys(staffs).length > 0 &&
                Object.keys(staffs).map((key, index) => (
                  <StaffCheckbox key={index} _key={key} staff={staffs[key]} />
                ))}
            </StaffListBox>
          </StaffBox>
        </Column>
      </Grid>
      {(context.state.company_license.accounting === 1 ||
        context.state.company_license.accounting === 3) && (
          <Grid>
            <Column className="x2-12 sm-12 md-6">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newService.is_campaign === 1}
                    onChange={(e) => {
                      setNewService((prevNewService) => ({
                        ...prevNewService,
                        is_campaign: e.target.checked ? 1 : 0,
                      }));
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={context.t(`['services/detail'].setCampaignTitle`)}
              />
              {newService.is_campaign === 1 && (
                <CampaignContainer>
                  <Input
                    label={context.t(`['services/detail'].campaignNameTitle`)}
                    hoverHelperText={context.t(
                      `['services/detail'].campaignHint`
                    )}
                    value={newService.campaign_title ?? ""}
                    onChange={(e) => {
                      setNewService((prevNewService) => ({
                        ...prevNewService,
                        campaign_title: e.target.value,
                      }));
                    }}
                  />
                </CampaignContainer>
              )}
            </Column>
          </Grid>
        )}

      <Grid>
        <Column className="xs-12 sm-12">
          <Button
            icon="check"
            title={context.t(`['services/detail'].createButtonTitle`)}
            textColor="white"
            backgroundColor="primary"
            fullWidth
            disabled={isInsertServiceButtonClicked}
            onClick={() => {
              setIsInsertServiceButtonClicked(true);
              setTimeout(() => {
                setIsInsertServiceButtonClicked(false);
              }, 1000);
              insertService();
            }}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default ServicesAdd;

const StaffBox = styled.div`
  width: 100%;
  padding: 12px; 
  box-sizing: border-box; 
  border-radius: 5px; 
  margin-bottom: 24px;
  box-shadow 0 2px 12px rgba(0,0,0,0.04);
`;

const StaffListBox = styled.div`
  width: 100%;
  height: 300px;
  padding-right: 8px;
  box-sizing: border-box;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    transition: all ease 0.35s;
  }

  &::-webkit-scrollbar-track {
    background: #eaeaea;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all ease 0.35s;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #d0d0d0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    border-radius: 50px;
    transition: all ease 0.35s;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
    transition: all ease 0.35s;
  }
`;

const StaffButtonContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 12px;

  box-sizing: border-box;
  text-align: left;

  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    font-size: 20px;
    line-height: 20px;
    padding: 0;
    margin: 0;
    margin-right: 10px;
  }
  span {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }
`;

const StaffPhotoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const StaffPhoto = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  object-fit: cover;
`;

const SectionTitle = styled.h3`
  font-size: 15px;
  padding: 0;
  margin: 0 0 8px;
`;

const ServiceInformationContainer = styled.div`
  width: 100%;
  background: #fff4e5;
  padding: 10px;
  boxsizing: border-box;
  color: #995a1f;
  border: 1px solid #ccc;

  .button {
    color: #3189ec;
    font-weight: bold;
    cursor: pointer;
  }
`;

const CampaignContainer = styled.div`
  width: 100%;
`;

import {
  Home,
  AccountBalanceWallet,
  InsertInvitation,
  Receipt,
  LocalMall,
  Store,
  PeopleAlt,
  Layers,
  HowToReg,
  Sms,
  InsertChart,
  Settings,
  AccountBalance,
  ListAlt,
  NoteAdd,
  Storefront,
  PhotoLibrary,
  CalendarTodayOutlined,
  CalendarToday,
} from "@material-ui/icons";

const favorite_pages = [
  { name: "Müşteri Yönetimi", index: null },
  {
    name: "Müşteri Listesi",
    index: 1,
    icon: PeopleAlt,
    path: "/customers",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Kayıp Müşteriler",
    index: 2,
    icon: PeopleAlt,
    path: "/customers/passive",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Müşteri Yorumları",
    index: 3,
    icon: PeopleAlt,
    path: "/customers/rating",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Yeni Müşteri Oluştur",
    index: 4,
    icon: PeopleAlt,
    path: "/customers/add",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Rehberden Müşteri Ekle",
    index: 5,
    icon: PeopleAlt,
    path: "/customers/add/bulk",
    auth: [1, 2, 3, 4],
  },
  { name: "Hizmet Yönetimi", index: null, auth: [1, 2, 3, 4] },
  {
    name: "Hizmet Listesi",
    index: 6,
    icon: Layers,
    path: "/services",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Yeni Hizmet Oluştur",
    index: 7,
    icon: Layers,
    path: "/services/add",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Hizmet Adı Talebi Oluştur",
    index: null,
    icon: Layers,
    auth: [1, 2, 3, 4],
  },
  {
    name: "Hizmet Adı Talepleri",
    index: 42,
    icon: Layers,
    path: "/services/requests",
    auth: [1, 2, 3, 4],
  },
  {
    name: "+ Hizmet Primi Oluştur",
    index: 43,
    icon: Layers,
    path: "/services/bounty",
    auth: [1, 2, 3, 4],
  },
  { name: "Paket Yönetimi", index: null },
  {
    name: "Paket Listesi",
    index: 8,
    icon: LocalMall,
    path: "/packages/list",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Paket Satışları listesi",
    index: 9,
    icon: LocalMall,
    path: "/packages/sales",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Randevusu Oluşturulmamış Paketler",
    index: 33,
    icon: LocalMall,
    path: "/packages/unbooked",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Yeni Paket Oluştur",
    index: 10,
    icon: LocalMall,
    path: "/packages/add",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Yeni Paket Satışı Oluştur",
    index: 11,
    icon: LocalMall,
    path: "/packages/sales/add",
    auth: [1, 2, 3, 4],
  },
  { name: "Stok Yönetimi", index: null, auth: [1, 2, 3, 4], },
  {
    name: "Ürün Listesi",
    index: 12,
    icon: Store,
    path: "/products",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Yeni Ürün Oluştur",
    index: 13,
    icon: Store,
    path: "/products/add",
    auth: [1, 2, 3, 4],
  },
  { name: "Muhasebe Yönetimi", index: null, auth: [1, 2, 3, 4], },
  {
    name: "Gelir İşlemleri",
    index: 14,
    icon: AccountBalance,
    path: "/accounting/income",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Gider İşlemleri",
    index: 15,
    icon: AccountBalance,
    path: "/accounting/expense",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Gider Kalemleri",
    index: 16,
    icon: AccountBalance,
    path: "/accounting/expense/types",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Alacaklandırma",
    index: 29,
    icon: AccountBalance,
    path: "/accounting/credit",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Borçlandırma",
    index: 72,
    icon: AccountBalance,
    path: "/accounting/debit",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Borç İşlemleri",
    index: 17,
    icon: AccountBalance,
    path: "/accounting/debt",
    auth: [1, 2, 3, 4],
  },
  { name: "Personel Yönetimi", index: null, auth: [1, 2, 3, 4] },
  {
    name: "Personel Listesi",
    index: 18,
    icon: HowToReg,
    path: "/settings/employees",
    auth: [1, 2, 3, 4],
  },
  { name: "Prim İşlemleri", index: null, auth: [1, 2, 3, 4] },
  {
    name: "Yeni Personel Oluştur",
    index: 20,
    icon: HowToReg,
    path: "/settings/employees/add",
    auth: [1, 2, 3, 4],
  },
  { name: "Gelişmiş  Raporlar", index: null, auth: [1, 2, 3, 4], },
  {
    name: "İşletme Raporu",
    index: 21,
    icon: InsertChart,
    path: "/reports/company",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Personel Raporu",
    index: 22,
    icon: InsertChart,
    path: "/reports/staff",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Müşteri Raporu",
    index: 23,
    icon: InsertChart,
    path: "/reports/customer",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Hizmet Raporu",
    index: 24,
    icon: InsertChart,
    path: "/reports/service",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Ürün Raporu",
    index: 25,
    icon: InsertChart,
    path: "/reports/stock",
    auth: [1, 2, 3, 4],
  },
  { name: "Mesaj Yönetimi", index: null, auth: [1, 2, 3, 4] },
  {
    name: "SMS Raporları",
    index: 28,
    icon: Sms,
    path: "/sms/reports",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Seçimli Mesaj Gönder",
    index: 26,
    icon: Sms,
    path: "/sms/send/selective",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Filtreli Mesaj Gönder",
    index: 27,
    icon: Sms,
    path: "/sms/send/filter",
    auth: [1, 2, 3, 4],
  },
  { name: "Mesaj Raporları", index: null, auth: [1, 2, 3, 4] },
  {
    name: "SMS Ayarları",
    index: 32,
    icon: Sms,
    path: "/settings/sms",
    auth: [1, 2, 3, 4],
  },
  { name: "Sistem Ayarları", index: null, auth: [1, 2, 3, 4] },
  { name: "Profil Ayarları", index: null, auth: [1, 2, 3, 4] },
  {
    name: "İşletme Ayarları",
    index: 30,
    icon: Settings,
    path: "/settings",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Platform Ayarları",
    index: 39,
    icon: Settings,
    path: "/settings/platform-settings",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Çalışma Saatleri",
    index: 31,
    icon: Settings,
    path: "/settings/hours",
    auth: [1, 2, 3, 4],
  },
  { name: "Yeni Şube Oluştur", index: null, auth: [1, 2, 3, 4] },
  {
    name: "Sistem Logları",
    index: 34,
    icon: Settings,
    path: "/settings/logs",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Referans Ol",
    index: 38,
    icon: Storefront,
    path: "/settings/give-referance",
    auth: [1, 2, 3, 4],
  },

  { name: "Dil Ayarları", index: null, auth: [1, 2, 3, 4] },
  { name: "Bakiye İşlemleri", index: null, auth: [1, 2, 3, 4] },
  { name: "Ödeme Bildirimi", index: null, auth: [1, 2, 3, 4] },
  { name: "Mevcut Bakiyem", index: null, auth: [1, 2, 3, 4] },
  { name: "Satın Al", index: null, auth: [1, 2, 3, 4] },
  { name: "Sms Paketi", index: null, auth: [1, 2, 3, 4] },
  { name: "Çıkış", index: null, auth: [1, 2, 3, 4] },
  { name: "Galeri Yönetimi", index: null, auth: [1, 2, 3, 4] },
  { name: "Vitrin Fotoğrafı", index: null, auth: [1, 2, 3, 4] },
  { name: "İşletme Galerisi", index: null, auth: [1, 2, 3, 4] },
  { name: "Hizmet Galerisi", index: null, auth: [1, 2, 3, 4] },
  { name: "Randevu Takvimi", index: null, auth: [1, 2, 3, 4] },
  {
    name: "Randevu Takvimi",
    index: 56,
    icon: CalendarToday,
    path: "/appointments",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Online Randevu Talepleri",
    index: 57,
    icon: CalendarToday,
    path: "/appointments/online",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Yeni Randevu Oluştur",
    index: 58,
    icon: CalendarToday,
    path: "/appointments",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Randevuları Filtrele",
    index: 59,
    icon: CalendarToday,
    path: "/appointments/list",
    auth: [1, 2, 3, 4],
  },
  { name: "Adisyonları Yönetimi", index: null, auth: [1, 2, 3, 4] },
  {
    name: "Adisyonları Listele",
    index: 60,
    icon: Receipt,
    path: "/receipts",
    auth: [1, 2, 3, 4],
  },
  {
    name: "Yeni Adisyon Oluştur",
    index: 61,
    icon: Receipt,
    path: "/receipts/add",
    auth: [1, 2, 3, 4],
  },
];

export const favoritePage = (index) => {
  return favorite_pages.find((item) => item.index === parseInt(index));
};

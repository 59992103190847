import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/store";

import AuthContainer from "../../../components/AuthContainer";

import Input from "../../../theme/CustomMUI/Input";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Select from "../../../theme/CustomMUI/Select";
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import {
  Checkbox,
  createTheme,
  // CircularProgress,
  FormControlLabel,
  MuiThemeProvider,
  Tooltip,
  Switch,
  Typography,
} from "@material-ui/core";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";
import Table from "../../../theme/Table";
import Axios from "axios";
import { toast } from "react-toastify";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";
import moment from "moment";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Validate from "../../../functions/Validate";
import {
  InfoOutlined,
  Receipt,
  Event,
  Photo,
  DateRangeOutlined,
  Contacts,
  WhatsApp,
} from "@material-ui/icons";
import StatsCard from "../../../components/Cards/StatsCard";
import ConvertTimeToZulu from "../../../functions/ConvertTimeToZulu";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
import getDayIndex from "../../../functions/GetDayIndex";
import DisabledAppointmentsGenerator from "../../../functions/DisabledAppointmentsGenerator";
import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";
import CompressImage from "../../../functions/CompressImage";
import styled from "styled-components";
import FCLabel from "../../../theme/FCLabel";
import CloudComputing from "../../../assets/images/cloud-computing.svg";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import SmsIcon from "@material-ui/icons/Sms";
import SMSReview from "../../../components/Dialogs/SMSReview";
import NewAppointmentDialog from "../../../components/Dialogs/NewAppointmentDialog";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "#3189ec",
        fontSize: "12px",
        backgroundColor: "white",
        boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
      },
    },
  },
});
const blackListTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "red",
        fontSize: "12px",
        backgroundColor: "white",
        boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
      },
    },
  },
});

let years = [];
for (let i = 1950; i < 2025; i++) {
  years.push({ name: i, value: i });
}
const days = [
  {
    value: "01",
    name: 1,
  },
  {
    value: "02",
    name: 2,
  },
  {
    value: "03",
    name: 3,
  },
  {
    value: "04",
    name: 4,
  },
  {
    value: "05",
    name: 5,
  },
  {
    value: "06",
    name: 6,
  },
  {
    value: "07",
    name: 7,
  },
  {
    value: "08",
    name: 8,
  },
  {
    value: "09",
    name: 9,
  },
  {
    value: "10",
    name: 10,
  },
  {
    value: "11",
    name: 11,
  },
  {
    value: "12",
    name: 12,
  },
  {
    value: "13",
    name: 13,
  },
  {
    value: "14",
    name: 14,
  },
  {
    value: "15",
    name: 15,
  },
  {
    value: "16",
    name: 16,
  },
  {
    value: "17",
    name: 17,
  },
  {
    value: "18",
    name: 18,
  },
  {
    value: "19",
    name: 19,
  },
  {
    value: "20",
    name: 20,
  },
  {
    value: "21",
    name: 21,
  },
  {
    value: "22",
    name: 22,
  },
  {
    value: "23",
    name: 23,
  },
  {
    value: "24",
    name: 24,
  },
  {
    value: "25",
    name: 25,
  },
  {
    value: "26",
    name: 26,
  },
  {
    value: "27",
    name: 27,
  },
  {
    value: "28",
    name: 28,
  },
  {
    value: "29",
    name: 29,
  },
  {
    value: "30",
    name: 30,
  },
  {
    value: "31",
    name: 31,
  },
];


const SingleCustomer = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [tab, setTab] = useState(0);

  const [filterExpandedStatistic, setFilterExpandedStatistic] = useState(false);
  const [filterExpandedHistory, setFilterExpandedHistory] = useState(false);

  const [loadedCustomer, setLoadedCustomer] = useState(false); // Skeleton gösterim değeri
  const [photos, setPhotos] = useState([]); // İstekten gelen fotoğraflar

  const [accountings, setAccountings] = useState({
    1: 50, // premium photo limitation
    2: 20, // standart limitation
    3: 4, // free limitation
  });

  const [deleteModal, setDeleteModal] = useState(false); // Silme işlemi onay kutusu
  const [selectedPhotos, setSelectedPhotos] = useState([]); // Silme işlemi için seçilen fotoğraflar

  const [unavailableData, setUnavailableData] = useState({
    single: [],
    between: [],
    day_closed: false,
    all_hours_closed: false,
  });

  const [availableAppointmentExpanded, setAvailableAppointmentExpanded] =
    useState(false);
  const [unavaliableHours, setUnavaliableHours] = useState([]);

  const [hours, setHours] = useState([
    ...BetweenTimeGenerator(context.state.company_shift),
  ]);
  const [checkoutDataDialog, setCheckoutDataDialog] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sendSms, setSendSms] = useState(true);
  const [note, setNote] = useState(null);
  const [servicesByStaff, setServicesByStaff] = useState([]);
  const [staffs, setStaffs] = useState([]);

  // Customer Details
  const [customer, setCustomer] = useState({
    name: "",
    surname: "",
    phone: null,
    dialCode: null,
    s_dialCode: null,
    email: null,
    sex: null,
    point: null,
    description: null,
    birthday: null,

    related_company: null,
    sms_send: null,
    nationalityID: null,
    //Picker
    real_selected_birthday: null,
    selectedPhone: {},
    disable_online_booking: false,
    black_listed: 0,
  });

  const [selectedDay, setSelectedDay] = useState(
    moment(new Date() - 568080000000).format("DD")
  );
  const [selectedMonth, setSelectedMonth] = useState(
    moment(new Date() - 568080000000).format("MM")
  );
  const [selectedYear, setSelectedYear] = useState(
    moment(new Date() - 568080000000).format("YYYY")
  );

  const [passiveConfirm, setPassiveConfirm] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [loaded, setLoaded] = useState(false);

  const [lastTransactions, setLastTransactions] = useState({
    //customer receipts
    start: ConvertTimeToZulu(moment().subtract(30, "days").unix()),
    end: ConvertTimeToZulu(moment().unix()),
    filterPicker: [
      {
        id: 1,
        label: "Bugün",
        offset: 1,
      },
      {
        id: 2,
        label: "Bu Hafta",
        offset: 3,
      },
      {
        id: 3,
        label: "Bu Ay",
        offset: 4,
      },
    ],
    selected_filter_picker: null,
    replacements: {
      "customer.full_name": {
        null: context.t(`customers.customerFullNameLabel`),
        "": context.t(`customers.customerFullNameLabel`),
      },
      is_package: {
        true: context.t(`customers.ispackageTrueLabel`),
        false: context.t(`customers.ispackageFalseLabel`),
      },
    },
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => {
        setPagesHistory(page)
      },
    },
    data: [],
  });
  const setPagesHistory = (page) => {
    setLastTransactions((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        page: page,
      }
    }))
  }

  const [appointments, setAppointments] = useState({
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => {
        setPages(page)
      },
    },
    data: [],
  });

  const setPages = (page) => {
    setAppointments((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        page: page,
      }
    }))
  }
  // Customer appointments detail data
  const [customerAppointments, setCustomerAppointments] = useState([]);

  const [filterBeforeInsert, setFilterBeforeInsert] = useState([
    {
      id: 1,
      label: "Tümünü Seç",
      choice: null,
    },
    {
      id: 2,
      label: "Önceki",
      choice: "before",
    },
    {
      id: 3,
      label: "Sonraki",
      choice: "after",
    },
  ]);
  const [selectedFilterBeforeInsert, setSelectedFilterBeforeInsert] =
    useState(null);
  // Customer appointments detail data filter models
  // const [customerAppointmentsFilterOffset, setCustomerAppointmentsFilterOffset] = useState(30),

  const [filterPickerStatistic, setFilterPickerStatistic] = useState([
    {
      id: 1,
      label: "Bugün",
      offset: 1,
    },
    {
      id: 2,
      label: "Bu Hafta",
      offset: 3,
    },
    {
      id: 3,
      label: "Bu Ay",
      offset: 4,
    },
  ]);

  const [customerStateFilter, setCustomerStateFilter] = useState([
    {
      id: 0,
      label: "Tümü",
      fetch_only: null,
    },
    {
      id: 1,
      label: "Pasif Randevular",
      fetch_only: "passive",
    },
    {
      id: 2,
      label: "Aktif Randevular",
      fetch_only: "waiting",
    },
    {
      id: 3,
      label: "İptal Edilen Randevular",
      fetch_only: "canceled",
    },
    {
      id: 4,
      label: "Gelmediği Randevular",
      fetch_only: "notjoined",
    },
    {
      id: 5,
      label: "Geldiği Randevular",
      fetch_only: "joined",
    },
  ]);

  const [selectedFilterPickerStatistic, setSelectedFilterPickerStatistic] =
    useState(null);
  // const [customerAppointmentsFilterIsBetween, setCustomerAppointmentsFilterIsBetween] = useState(false);

  const [selectedCustomerStateFilter, setSelectedCustomerStateFilter] =
    useState(null);
  const [
    customerAppointmentsFilterStartDate,
    setCustomerAppointmentsFilterStartDate,
  ] = useState(ConvertTimeToZulu(moment().subtract(30, "days").unix()));

  const [
    customerAppointmentsFilterEndDate,
    setCustomerAppointmentsFilterEndDate,
  ] = useState(ConvertTimeToZulu(moment().unix()));

  const [customerAppointmentsFilterData, setCustomerAppointmentsFilterData] =
    useState([]);

  const [showBirthdayDate, setShowBirthdayDate] = useState(false);

  const [smsSendDialog, setSmsSendDialog] = useState(false);
  const [smsRequestStatus, setSmsRequestStatus] = useState(false);
  const [smsSettings, setSmsSettings] = useState({
    message: "",
    id: [parseInt(params.id)],
  });
  const [openSmsReviewDialog, setOpenSmsReviewDialog] = useState(false);

  const [whatsappConDialog, setWhatsappConDialog] = useState(false);
  const [whatsappSettings, setWhatsappSettings] = useState({
    message: "",
    phone_number: null,
  });

  const [smsTemplates, setSmsTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [shortCode, setShortCode] = useState(null);

  const [totalNumber, setTotalNumber] = useState(null);
  const [totalSmsCredit, setTotalSmsCredit] = useState(null);
  const [totalSmsReviewDialog, setTotalSmsReviewDialog] = useState(false);

  // Sözleşmeler
  const [agreements, setAgreements] = useState([]);
  const [agreementLoad, setAgreementLoad] = useState(true);
  const [agreementDeleteId, setAgreementDeleteId] = useState(null);
  const [deleteAgreementDialog, setDeleteAgreementDialog] = useState(false);

  const [deleteAppointmentModal, setDeleteAppointmentModal] = useState(false);
  const [deleteAppointmentId, setDeleteAppointmentId] = useState(null);

  // sms onay dialogu
  const [smsAprovalModal, setSmsAprovalModal] = useState(false);
  // gönderilecek sms id,name,agreement name
  const [sendSmsId, setSendSmsId] = useState(null);
  const [sendSmsCustomerName, setSendSmsCustomerName] = useState(null);
  const [sendSmsAgreementName, setSendSmsAgreementName] = useState(null);
  // Whatsapp onay dialogu
  const [wpAprovalModal, setWpAprovalModal] = useState(false);
  // gönderilecek wp id
  const [sendWpId, setSendWpId] = useState(null);
  const [sendWpCustomerName, setSendWpCustomerName] = useState(null);
  const [sendWpAgreementName, setSendWpAgreementName] = useState(null);
  // wp phone number
  const [sendWpPhone, setSendeWpPhone] = useState(null);
  const [sendWpMessage, setSendeWpMessage] = useState(null);

  const [paginationAgreement, setPaginationAgreement] = useState({
    page: null,
    total_page: null,
    onChange: (page) => getAgreement(page),
  });

  const [selectPhoneNumberList, setSelectedPhoneNumberList] = useState([
    {
      id: 1,
      name: context.t(`['customers/add'].firstPhoneNumber`),
      type: 0,
    },
    {
      id: 2,
      name: context.t(`['customers/add'].secondPhoneNumber`),
      type: 1,
    },
    {
      id: 3,
      name: context.t(`['customers/add'].allPhoneNumber`),
      type: 2,
    },
  ]);
  const [selectPhoneNumber, setSelectPhoneNumber] = useState({});
  const [appointmentsListLoader, setAppointmentsListLoader] = useState(false);

  const [business, setBusiness] = useState([]);

  const [inputNameFocus, setInputNameFocus] = useState(false);
  const [inputSurnameFocus, setInputSurnameFocus] = useState(false);
  const [inputNationalityIDFocus, setInputNationalityIDFocus] = useState(false);
  const [inputFirstPhoneFocus, setInputFirstPhoneFocus] = useState(false);
  const [inputSecondPhoneFocus, setInputSecondPhoneFocus] = useState(false);
  const [inputMailFocus, setInputMailFocus] = useState(false);
  const [inputTypePointFocus, setInputTypePointFocus] = useState(false);
  const [inputBusinessFocus, setInputBusinessFocus] = useState(false);
  const [inputDescriptionFocus, setInputDescriptionFocus] = useState(false);

  const months = [
    {
      id: 0,
      value: "01",
      name: context.t(`customers.months.jan`),
    },
    {
      id: 1,
      value: "02",
      name: context.t(`customers.months.feb`),
    },
    {
      id: 2,
      value: "03",
      name: context.t(`customers.months.mar`),
    },
    {
      id: 3,
      value: "04",
      name: context.t(`customers.months.apr`),
    },
    {
      id: 4,
      value: "05",
      name: context.t(`customers.months.may`),
    },
    {
      id: 5,
      value: "06",
      name: context.t(`customers.months.jun`),
    },
    {
      id: 6,
      value: "07",
      name: context.t(`customers.months.jul`),
    },
    {
      id: 7,
      value: "08",
      name: context.t(`customers.months.aug`),
    },
    {
      id: 8,
      value: "09",
      name: context.t(`customers.months.sep`),
    },
    {
      id: 9,
      value: "10",
      name: context.t(`customers.months.oct`),
    },
    {
      id: 10,
      value: "11",
      name: context.t(`customers.months.nov`),
    },
    {
      id: 11,
      value: "12",
      name: context.t(`customers.months.dec`),
    },
  ];



  const BLACK_LIST_STATUS = {
    0: {
      label: context.t('[customers/add].blacklist.none'),
      color: 'success'
    },
    1: {
      label: context.t('[customers/add].blacklist.primary'),
      color: 'error'
    },
    2: {
      label: context.t('[customers/add].blacklist.secondary'),
      color: 'error'
    },
    3: {
      label: context.t('[customers/add].blacklist.both'),
      color: 'error'
    }
  };

  // send sms to customer
  const sendSMSAgreement = (id) => {
    Axios.post(`${context.api_endpoint}/company/contract/template/pdf/sms`, {
      id: id,
      is_wp: 1,
    })
      .then((data) => {
        if (data.data.status === 201) {
          toast.success(data.data.data);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // send wp to customer
  const sendWPAgreement = (id) => {
    Axios.post(`${context.api_endpoint}/company/contract/template/pdf/sms`, {
      id: id,
      is_wp: 2,
    })
      .then((data) => {
        window.open(
          `https://api.whatsapp.com/send?phone=${customer.phone}&text=${data.data.data}`,
          "_blank"
        );
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const cancelAppointment = (appointment_id) => {
    Axios.post(`${context.api_endpoint}/company/appointment/cancel`, {
      appointment_id,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Randevu başarıyla iptal edildi.");
          getFilterByAppointmentsStatistics();
          setDeleteAppointmentId(null);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getAgreement = (page) => {
    setAgreementLoad(false);
    Axios.get(
      `${context.api_endpoint}/company/contract/template/pdf?customer_id=${customer.id
      }&page=${page ? page : 1}`
    )
      .then(({ data }) => {
        if (data.status === 200) {
          setAgreements(
            data.data.pdfs.records.map((item) => {
              return {
                id: item.id,
                url: item.secure_url,
                date: moment(item.created_at).format(
                  "DD MMMM dddd, YYYY, HH:mm"
                ),
                agreement_name: item.title,
                bytes: (item.bytes / 1024 / 1024).toFixed(2),
                opened: item.is_connection_opened,
                name: `${item.customer_name} ${item.customer_surname}`,
              };
            })
          );
          setAgreementLoad(true);
          setPaginationAgreement({
            ...paginationAgreement,
            page: data.data.pdfs.page,
            total_page: data.data.pdfs.records.length,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const deleteAgreement = (id) => {
    Axios.delete(`${context.api_endpoint}/company/contract/template/pdf/${id}`)
      .then(({ data }) => {
        if (data.status === 200) {
          toast.success(data.data);
          getAgreement();
        }
      })
      .catch((err) => {
        toast.error(
          context.t(`customers.errorOccuredToast`)
        );
      });
    setAgreementDeleteId(null);
  };

  const getFilterByAppointmentsStatistics = async () => {
    setAppointmentsListLoader(false);
    // company/customer/:id/appointment/statistics
    // await setCustomerAppointmentsFilterData([]);
    Axios.get(
      `${context.api_endpoint}/company/customer/${params.id}/appointment/statistics`,
      filterExpandedStatistic
        ? selectedFilterPickerStatistic === null
          ? {
            params: {
              page: appointments.pagination.page ? appointments.pagination.page : 1,
              isbetween: true,
              start: customerAppointmentsFilterStartDate.split("T")[0],
              end: customerAppointmentsFilterEndDate.split("T")[0],
              fetch_only: selectedCustomerStateFilter ? selectedCustomerStateFilter.fetch_only : null,
            },
          }
          : {
            params: {
              page: appointments.pagination.page ? appointments.pagination.page : 1,
              isbetween: false,
              offset: selectedFilterPickerStatistic.offset,
              fetch_only: selectedCustomerStateFilter ? selectedCustomerStateFilter.fetch_only : null,
            },
          }
        : {
          params: {
            page: appointments.pagination.page ? appointments.pagination.page : 1,
          },
        }
    )
      .then(async (response) => {
        if (response.status === 200) {
          const maskedData = {
            fail_state_count: {
              label: "highlight_off",
              color: "red",
              title: "dashboard.stats.meta.notArrived",
            },
            success_state_count: {
              label: "check_circle_outline",
              color: "green",
              title: "dashboard.stats.meta.arrived",
            },
            waiting_state_count: {
              label: "hourglass_full",
              color: "orange",
              title: "dashboard.stats.meta.waiting",
            },
            canceled_appointments: {
              label: "close",
              color: "red",
              title: "İptal Edildi",
            },
          };

          const data = response.data.data;
          const transformData = [];

          Object.keys(data).map((item) => {
            Object.keys(maskedData).map((mask) => {
              if (item === mask) {
                transformData.push({
                  ...maskedData[mask],
                  value: data[item],
                });
              }
            });
          });

          transformData.unshift({
            label: "event_note",
            color: "dark-blue",
            title: "dashboard.stats.meta.total",
            value: data.total_appointment_count,
          });
          await setCustomerAppointmentsFilterData(transformData);
          await setLoaded(true);
          await setAppointments({
            ...appointments,
            data: [
              ...data.appointments.map((appointment) => {
                return appointment
                  ? {
                    ...appointment,
                    created_at: moment(appointment.created_at)
                      .tz("Europe/Istanbul")
                      .format("DD/MM/YYYY HH:mm"),
                    staff:
                      appointment.staff && appointment.staff.full_name ? (
                        appointment.staff.deleted_at !== null ? (
                          <p style={{ color: "red" }}>
                            {appointment.staff.full_name}
                            {context.t(`customers.deletedStaffLabel`)}
                          </p>
                        ) : (
                          `${appointment.staff.full_name}`
                        )
                      ) : (
                        ""
                      ),
                    appointment_start_date: moment(
                      appointment.appointment_start_date
                    )
                      .tz("Europe/Istanbul")
                      .format("DD/MM/YYYY HH:mm"),
                    appointmentDate: moment(
                      appointment.appointment_start_date
                    ).tz("Europe/Istanbul"),
                    service: appointment.service
                      ? appointment.service.name
                      : appointment.packet
                        ? appointment.packet.name
                        : "-",
                    note: appointment.note ? appointment.note : "-",
                    appointment_status: appointment.active,
                    active:
                      appointment.active !== 2 ? (
                        appointment.customer_state === 0 ? (
                          <span
                            className="text-success"
                            style={{
                              marginLeft: "15px",
                            }}
                          >
                            <i
                              className="material-icons"
                              style={{ color: "green" }}
                            >
                              check_circle_outline
                            </i>
                          </span>
                        ) : appointment.customer_state === 2 ? (
                          <span
                            className="text-warning"
                            style={{
                              marginLeft: "15px",
                            }}
                          >
                            <i
                              className="material-icons"
                              style={{ color: "orange" }}
                            >
                              hourglass_full
                            </i>
                          </span>
                        ) : (
                          <span
                            className="text-danger"
                            style={{
                              marginLeft: "15px",
                            }}
                          >
                            <i
                              className="material-icons"
                              style={{ color: "red" }}
                            >
                              highlight_off
                            </i>
                          </span>
                        )
                      ) : (
                        <span
                          className="text-success"
                          title={context.t(
                            `['appointments/filter'].canceled`
                          )}
                          style={{
                            marginLeft: "15px",
                          }}
                        >
                          <i
                            className="material-icons"
                            style={{ color: "red" }}
                          >
                            close
                          </i>
                        </span>
                      ),
                  }
                  : null;
              }),
            ],
          });
        }
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          toast.error(e?.response?.data?.message);
        } else {
          toast.warning(e?.response?.data?.message);
        }
      })
      .finally(() => {
        setAppointmentsListLoader(true);
      });
  };

  //TODO : Customer get Photos
  /**
   * Veri kaynağı üzerindne fotoğrafları getiren fonksiyon.
   */
  const getPhotos = () => {
    Axios.get(
      `${context.api_endpoint}/company/customer/photos?customerid=${location.pathname.split("/")[3]
      }`
    )
      .then((response) => {
        const { data } = response;
        if (response.status === 200) {
          setLoadedCustomer(true);
          setPhotos([...data.data]);
          setSelectedPhotos([]);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  /**
   * Buttonda Seçimi yapılan fotoğrafın yüklenmesi.
   */
  const uploadMultiPhotosNext = async (e, id) => {
    e.persist();

    let currentAccounting = context.state.company_license.accounting;
    const appointment_limitation =
      context.state.company_license.appointment_limitation;

    if (currentAccounting === 2 && appointment_limitation > 0) {
      currentAccounting = 3;
    }

    if (
      accountings[currentAccounting] <
      [...e.target.files].length + photos.length
    ) {
      toast.error(context.t(`customers.photoLimitErrorToast`));
    } else {
      const length = [...e.target.files].length;
      const Form = new FormData();

      setLoadedCustomer(false);

      for (let i = 0; i < length; i++) {
        Form.append(
          "files",
          await CompressImage(e.target.files[i], {
            maxSizeMB: 2,
            fileType: "image/jpg",
          })
        );
        Form.append("customer_id", customer.id);
        Form.append("id", id);
      }

      Axios.post(`${context.api_endpoint}/company/customer/photos`, Form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              context.t(`['settings/service-gallery'].insertToast`)
            );
            getPhotos();
          }
        })
        .catch((e) => {
          setLoadedCustomer(true);
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }

    if (
      currentAccounting === 2 &&
      business.license.appointment_limitation === 0
    ) {
      toast.error(context.t(`customers.photoUploadErrorToast`));
    } else {
    }
  };

  /**
   * Inputta seçimi yapılan fotoğrafları yükleyen fonksiyon.
   */
  const uploadMultiPhotos = async (e) => {
    e.persist();

    let currentAccounting = context.state.company_license.accounting;
    const appointment_limitation =
      context.state.company_license.appointment_limitation;

    if (currentAccounting === 2 && appointment_limitation > 0) {
      currentAccounting = 3;
    }

    if (
      accountings[currentAccounting] <
      [...e.target.files].length + photos.length
    ) {
      toast.error(context.t(`customers.photoLimitErrorToast`));
    } else {
      const length = [...e.target.files].length;
      const Form = new FormData();

      setLoadedCustomer(false);

      for (let i = 0; i < length; i++) {
        Form.append(
          "files",
          await CompressImage(e.target.files[i], {
            maxSizeMB: 2,
            fileType: "image/jpg",
          })
        );
        Form.append("customer_id", customer.id);
      }

      Axios.post(`${context.api_endpoint}/company/customer/photos`, Form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          if (response.status === 201) {
            toast.success(
              context.t(`['settings/service-gallery'].insertToast`)
            );
            getPhotos();
          }
        })
        .catch((e) => {
          setLoadedCustomer(true);
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    }

    if (
      currentAccounting === 2 &&
      business.license.appointment_limitation === 0
    ) {
      toast.error(context.t(`customers.photoUploadErrorToast`));
    } else {
    }
  };

  /**
   * Multi seçimin sonucunda silme işlemini yapan fonksiyondur.
   */
  const deleteMultiPhotos = () => {
    Axios.delete(
      `${context.api_endpoint}/company/customer/photos/${selectedPhotos[0]}`,
      {
        params: {
          choice: selectedFilterBeforeInsert
            ? selectedFilterBeforeInsert.choice
            : null,
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`['settings/service-gallery'].deleteToast`));
          getPhotos();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : staff and service
  // GET Staff
  const beforeInsertRequiredFields = () => {
    // Axios.get(`${context.api_endpoint}/company/appointments/index2`)
    //   .then(({ data }) => {
    //     const staffs = [
    //       ...data.data.map((staff) => {
    //         staff.services.forEach((service, index) => {
    //           if (
    //             service.service.category_name !== null &&
    //             service.service.category_name.toLowerCase().includes("erkek")
    //           ) {
    //             service.service.name += " (E)";
    //           } else if (
    //             service.service.category_name !== null &&
    //             service.service.category_name.toLowerCase().includes("kadın")
    //           ) {
    //             service.service.name += " (K)";
    //           }
    //         });
    //         return staff;
    //       }),
    //     ];
    //     setStaffs([
    //       ...staffs
    //         .map((staff) => {
    //           return staff;
    //         })
    //         .filter((staff) => staff !== null),
    //     ]);
    //   })
    //   .catch((e) => {
    //     if (e.response.status === 401) {
    //       toast.error(e.response.data.message);
    //     } else {
    //       toast.warning(e.response.data.message);
    //     }
    //   });
  };

  const getSingleCustomer = async () => {
    await Axios.get(`${context.api_endpoint}/company/customer/${params.id}`)
      .then(({ data }) => {
        setSelectPhoneNumber(
          data.data.selectedPhone === 0
            ? selectPhoneNumberList.filter((m) => m.type === 0)
            : selectPhoneNumberList.filter(
              (m) => m.type === data.data.selectedPhone
            )
        );
        setCustomer({
          ...data.data,
          birthday: moment(data.data.birthday).format("DD/MM/YYYY"),
          real_selected_birthday:
            data.data.birthday !== null
              ? new Date(moment(data.data.birthday).format("YYYY-MM-DD"))
              : null,
          ...(process.env.APP_NAME === 'management' ? {
            phone:
              context.state.user.permission === 2 &&
                context.state.limited_permission.customer_info === 0
                ? ""
                : data.data.dialCode + data.data.phone,
          } : {
            phone:
              context.state.user.permission === 2 &&
                context.state.limited_permission.customer_info === 0
                ? ""
                : data.data.phone,
          }),
          dialCode: data.data.dialCode
            ? data.data.dialCode
            : context.state.dialCode,
          secondary_phone:
            context.state.user.permission === 2 &&
              context.state.limited_permission.customer_info === 0
              ? ""
              : data.data.s_dialCode + data.data.secondary_phone,
          s_dialCode: data.data.s_dialCode
            ? data.data.s_dialCode
            : context.state.dialcode,

          email:
            context.state.user.permission === 2 &&
              context.state.limited_permission.customer_info === 0
              ? ""
              : data.data.email,
          nationalityID: data.data.nationalityID ? data.data.nationalityID : "",
        });

        if (data.data.birthday !== null) {
          setShowBirthdayDate(true);
          setSelectedDay(
            moment(data.data.birthday).tz("Europe/Istanbul").format("DD")
          );
          setSelectedMonth(
            moment(data.data.birthday).tz("Europe/Istanbul").format("MM")
          );
          setSelectedYear(
            moment(data.data.birthday).tz("Europe/Istanbul").format("YYYY")
          );
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getHistorySingleCustomer = () => {
    Axios.get(
      `${context.api_endpoint}/company/customer/${params.id}/history`,
      filterExpandedHistory
        ? lastTransactions.selected_filter_picker === null
          ? {
            params: {
              page: lastTransactions.pagination.page ? lastTransactions.pagination.page : 1,
              start: moment(lastTransactions.start).format("YYYY-MM-DD"),
              end: moment(lastTransactions.end).format("YYYY-MM-DD"),
              isbetween: true,
            },
          }
          : {
            params: {
              page: lastTransactions.pagination.page ? lastTransactions.pagination.page : 1,
              offset: lastTransactions.selected_filter_picker.offset,
              isbetween: false,
            },
          }
        : {
          params: {
            page: lastTransactions.pagination.page ? lastTransactions.pagination.page : 1,
          },
        }
    )
      .then(({ data }) => {
        setLoaded(true);
        setLastTransactions({
          ...lastTransactions,
          data: [
            ...data.data.records.map((record) => {
              return {
                ...record,
                all_amount: record.total ? record.total.toFixed(0) : "0.00",
                paid: record.paid > 0 ? record.paid.toFixed(0) : "0.00",
                created_at: moment(record.created_at)
                  .tz("Europe/Istanbul")
                  .format("DD/MM/YYYY HH:mm"),
                debt_total:
                  (record.total ?? 0) - (record.paid ?? 0) < 1 &&
                    (record.total ?? 0) - (record.paid ?? 0) > 0
                    ? 0
                    : (record.total ?? 0) - (record.paid ?? 0) > 0
                      ? `${parseFloat(
                        ((record.total ?? 0) - (record.paid ?? 0)).toFixed(0)
                      )}`
                      : "0.00",
              };
            }),
          ],
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateSingleCustomer = () => {
    let birthday;
    if (showBirthdayDate) {
      // Create a new Date object with the selected year, month, and day
      const selectedDate = new Date(
        `${selectedYear}-${selectedMonth}-${selectedDay}`
      );

      // Add 3 hours to the selected date
      selectedDate.setHours(selectedDate.getHours() + 3);

      // Format the date and convert it to ISO string
      birthday = selectedDate.toISOString();
    }
    if (validateCustomer()) {
      Axios.put(
        `${context.api_endpoint}/company/customer/update?id:${params.id}`,
        {
          ...customer,
          birthday,
          ...(process.env.APP_NAME === 'management' ? {
            phone: customer.phone?.slice(customer?.dialCode.length),
            dialCode: customer.dialCode ? "+" + customer.dialCode : "",
          } : {
            phone: `${customer.phone}`.length === 10 ? customer.phone : "",
            dialCode:
              context.state.dialCode !== "" ||
                context.state.dialCode !== null
                ? context.state.dialCode
                : "+90",
          }),
          secondary_phone: customer?.secondary_phone ? customer?.secondary_phone
            .slice(customer.s_dialCode.length)
            .trim() : null,
          s_dialCode: customer.s_dialCode ? customer.s_dialCode.startsWith("+")
            ? customer.s_dialCode
            : "+" + customer.s_dialCode : null,
          nationalityID: customer.nationalityID
            ? customer.nationalityID
            : null,
          selectedPhone: selectPhoneNumber && selectPhoneNumber[0].type,
        }
      )
        .then((response) => {
          if (response.status === 200) {
            toast.success(
              context.t(
                `[appointments/filter].tabMenuLeft.updateCustomerDetailsTrue`
              )
            );
            setTimeout(() => {
              history.push("/customers");
            }, 1000);
          } else {
            toast.warn(
              context.t(
                `[appointments/filter].tabMenuLeft.updateCustomerDetailsFalse`
              )
            );
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            toast.warn(e.response.data.message);
          } else if (e.response.status === 400) {
            if (e.response.data.message === "İkincil telefon numarası boş olamaz") {
              toast.warn(context.t(`['customers/add'].secondPhoneNumberWarning`));
            } else if (e.response.data.message === "Birincil telefon numarası boş olamaz") {
              toast.warn(context.t(`['customers/add'].firstPhoneNumberWarning`));
            } else if (e.response.data.message === "İki telefon numarasıda boş olamaz.") {
              toast.warn(context.t(`['customers/add'].bothPhoneNumberWarning`));
            } else {
              toast.warn(e.response.data.message);
            }
          } else {
            toast.warn(e.response.data.message);
          }


        });
    }
  };

  const makePassiveSingleCustomer = () => {
    Axios.post(`${context.api_endpoint}/company/customer/delete/multiple`, {
      customer_id: [parseInt(params.id)],
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`customers.updateToPassiveSuccessToast`));
          setCustomer({ ...customer, is_passive: true });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const makeActiveSingleCustomer = () => {
    Axios.put(`${context.api_endpoint}/company/customer/state`, {
      customer_id: [parseInt(params.id)],
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`customers.updateToActiveSuccessToast`));
          setCustomer({ ...customer, is_passive: false });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const deleteSingleCustomer = () => {
    Axios.delete(`${context.api_endpoint}/company/customer/delete/${params.id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`customers.customerDeleteSuccessToast`));
          history.push("/customers");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const validateCustomer = () => {
    let validate_for_phone_number;
    if (customer.secondary_phone === null || customer.secondary_phone === "") {
      validate_for_phone_number = {
        field: "Telefon Numarası",
        value: customer.phone,
        condition: /\S{10}/,
      };
    } else {
      if (customer.phone === false || customer.phone === true) {
        validate_for_phone_number = {
          field: "Telefon Numarası",
          value: "",
          condition: /^\s*|\S{10}/,
        };
      } else {
        validate_for_phone_number = {
          field: "Telefon Numarası",
          value: customer.phone,
          condition: /^\s*|\S{10}/,
        };
      }
    }

    return Validate([
      {
        field: "İsim",
        value: customer.name,
        condition: /.+/,
      },
      {
        field: "Soyisim",
        value: customer.surname,
        condition: /.+/,
      },
      validate_for_phone_number,
    ]);
  };

  //TODO : insert Appointment
  const insertAppointment = () => {
    if (selectedTime === null) {
      return toast.warning(context.t(`customers.suitableClockWarnToast`));
    }
    let appointment_date = selectedDate;
    let appointment_time = selectedTime.split(":");
    appointment_date = `${moment(appointment_date).format("YYYY-MM-DD")}T${appointment_time[0]
      }:${appointment_time[1]}:00Z`;

    Axios.post(`${context.api_endpoint}/company/appointment/insert`, {
      staff_id: selectedStaff.id,
      customer_id: customer.id,
      service_id: selectedService.id,
      send_sms: selectedCustomer
        ? selectedCustomer.send_sms === false
          ? false
          : sendSms
        : sendSms,
      appointment_date,
      note,
      is_web: true,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`appointments.insertToast`));
          setCheckoutDataDialog(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  //TODO : add Receipts
  const addReceiptBySelectedCustomer = () => {
    Axios.post(`${context.api_endpoint}/company/receipt/insert`, {
      customer_id: customer.id,
      note: "",
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`['receipts/add'].insertToast`));
          history.push({
            pathname: `/receipts/detail/${response.data.data.id}`,
          });
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  // Get templates for sms and whatsapp message

  const getTemplates = () => {
    Axios.get(`${context.api_endpoint}/company/sms/template/all`).then(
      ({ data }) => {
        setSmsTemplates([...data.data]);
      }
    );
  };

  // get shortcode

  const getShortcode = () => {
    Axios.get(`${context.api_endpoint}/company/shortcode/list/keyword`)
      .then(async (response) => {
        if (response.status === 200) {
          setShortCode(response.data.data.keyword);
        }
      })
      .catch((e) => { });
  };

  // sms review

  const getSMSReview = () => {
    Axios.post(`${context.api_endpoint}/company/message/to/customer/preview`, {
      all: false,
      id: [parseInt(params.id)],
      except: [],
      message: smsSettings.message,
      sms_prefer: 1,
    })
      .then(async (response) => {
        if (response.status === 200) {
          setTotalNumber(response.data.data.total_numbers);
          setTotalSmsCredit(response.data.data.total_sms_credit);
          setOpenSmsReviewDialog(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  // SMS final send
  const sendSelectiveSms = () => {
    setSmsRequestStatus(true);

    Axios.post(`${context.api_endpoint}/company/message/to/customers`, {
      //Tüm müşterilere gönderilecek mi?
      all: false,

      //Müşteri ID listesi,
      id: [parseInt(params.id)],

      //Tüm müşteriler true ise hariç müşterilerin ID listesi,
      except: [],
      sms_prefer: 1,
      //Gönderilecek mesaj içeriği
      message: smsSettings.message,

      //Gönderilecek tarih
      send_date: null,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
        } else {
          toast.success(response.data.message);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  const SecondaryPhone_handleOnChange = (
    value,
    data,
    event,
    formattedValue
  ) => {
    setCustomer({
      ...customer,
      secondary_phone: value,
      s_dialCode: data.dialCode,
    });
  };

  const PhoneNumber_handleOnChange = (value, data, event, formattedValue) => {
    setCustomer({
      ...customer,
      phone: value,
      dialCode: data.dialCode,
    });
  };

  useEffect(() => {
    if (history.location.state === undefined) {
      getSingleCustomer();
    } else if (history.location.state.tab === 2) {
      setTab(2);
    }
    // getHistorySingleCustomer();
    beforeInsertRequiredFields();
    getTemplates();
    getShortcode();
  }, []);

  useEffect(() => {
    if (tab === 2) {
      getFilterByAppointmentsStatistics();
    }
  }, [
    tab,
    checkoutDataDialog,
    filterExpandedStatistic,
    customerAppointmentsFilterEndDate,
    customerAppointmentsFilterStartDate,
    filterPickerStatistic,
    selectedFilterPickerStatistic,
    selectedCustomerStateFilter,
    appointments.pagination.page
  ])

  useEffect(() => {
    if (tab === 1) {
      getHistorySingleCustomer()
    }
  }, [
    tab,
    filterExpandedHistory,
    lastTransactions.pagination.page,
    lastTransactions.start,
    lastTransactions.end,
    lastTransactions.selected_filter_picker
  ])

  const _TabMenuItem = ({ btnProps, tab }) => {
    return (
      <Button
        {...btnProps}
        iconSize={20}
        style={{ borderRadius: "0px !important" }}
        containerStyle={{ justifyContent: "center" }}
        backgroundColor={tab === tab ? "primary-opacity" : ""}
        textColor={tab === tab ? "primary" : "grey"}
        size="lg"
        fullWidth
      />
    );
  };

  const WhatsappIletisim = () => {
    setWhatsappConDialog(true);
    setWhatsappSettings({
      ...whatsappSettings,
      phone_number: customer.phone,
    });
  };

  const WpIletisimIkinciNumara = () => {
    setWhatsappConDialog(true);
    setWhatsappConDialog({
      ...whatsappSettings,
      foreign: false,
      phone_number: customer.secondary_phone,
    });
  };

  const TabMenuItem = _TabMenuItem;

  return (
    <AuthContainer limited_permission="customer">
      <Grid>
        <Column className="xs-12 sm-12">
          <Column className="xs-12 sm-12 md-3 lg-12">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Column className="xs-12 sm-12 md-12 lg-2">
                <TabMenuItem
                  tab={0}
                  btnProps={{
                    iconComponent: InfoOutlined,
                    title: context.t(
                      `[appointments/filter].tabMenuLeft.customerBill`
                    ),
                    onClick: () => {
                      setTab(0);
                    },
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-2">
                {(context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
                  <TabMenuItem
                    tab={1}
                    btnProps={{
                      iconComponent: Receipt,
                      title: context.t(
                        `[appointments/filter].tabMenuLeft.customerAppointment`
                      ),
                      onClick: () => {
                        getHistorySingleCustomer();
                        setTab(1);
                      },
                    }}
                  />
                )) ||
                  null}
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-2">
                <TabMenuItem
                  tab={2}
                  btnProps={{
                    iconComponent: Event,
                    title: context.t(
                      `[appointments/filter].tabMenuLeft.customerDetails`
                    ),
                    onClick: () => {
                      beforeInsertRequiredFields();
                      setTab(2);
                    },
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-2">
                <TabMenuItem
                  tab={3}
                  btnProps={{
                    iconComponent: Photo,
                    title: context.t(
                      `[appointments/filter].tabMenuLeft.customerPhotos`
                    ),
                    onClick: () => {
                      getPhotos();
                      setTab(3);
                    },
                  }}
                />
              </Column>
              {process.env.APP_NAME !== "management" && (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <TabMenuItem
                    tab={4}
                    btnProps={{
                      iconComponent: Contacts,
                      title: context.t(
                        `[appointments/filter].tabMenuLeft.customerContract`
                      ),
                      onClick: () => {
                        getAgreement();
                        setTab(4);
                      },
                    }}
                  />
                </Column>
              )}
              {process.env.APP_NAME !== "management" && (
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <TabMenuItem
                    tab={4}
                    btnProps={{
                      iconComponent: Receipt,
                      title: context.t(`customers.routeToLogs`),
                      onClick: () => {
                        context.dispatch({
                          type: "LOG_TYPE",
                          payload: { log_type: 1 },
                        });
                        context.dispatch({
                          type: "LOG_ID",
                          payload: {
                            log_id: Number(customer.id),
                          },
                        });
                        history.push("/settings/logs");
                      },
                    }}
                  />
                </Column>
              )}
            </div>
          </Column>

          <Column
            className="xs-12 sm-12 md-9 lg-12 mt-1 pt-3"
            style={{
              borderTop: "1px solid #eee",
            }}
          >
            {tab === 0 && (
              <>
                <Grid className="mb-3">
                  {context.state.user.permission === 0 && (
                    <Column className="xs-12 sm-12">
                      <Alert severity="warning" className="mb-3">
                        <b>
                          {context.t(`customers.staffUpdateWarn1`)}
                        </b>
                        <br />
                        {context.t(`customers.staffUpdateWarn2`)}
                      </Alert>
                    </Column>
                  )}

                  <Column className="xs-12 sm-12 md-12 lg-3">
                    <Input
                      autoFocus={inputNameFocus}
                      onFocus={() => {
                        setInputNameFocus(true);
                        setInputSurnameFocus(false);
                        setInputNationalityIDFocus(false);
                        setInputFirstPhoneFocus(false);
                        setInputSecondPhoneFocus(false);
                        setInputMailFocus(false);
                        setInputTypePointFocus(false);
                        setInputBusinessFocus(false);
                        setInputDescriptionFocus(false);
                      }}
                      required
                      label={context.t(`component.CADialog.nameInputLabel`)}
                      placeholder={context.t(`component.CADialog.nameInputPlaceholder`)}
                      value={customer.name !== null ? customer.name : ""}
                      onChange={(e) => {
                        let nameText = e.target.value;
                        setCustomer({
                          ...customer,
                          name:
                            nameText.split(" ")[1] !== undefined
                              ? nameText.split(" ")[0] + " " + nameText.split(" ")[1]
                              : nameText.split(" ")[0] !== undefined
                                ? nameText.split(" ")[0]
                                : nameText,
                        });
                      }}
                    />
                  </Column>

                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <Input
                      autoFocus={inputSurnameFocus}
                      onFocus={() => {
                        setInputNameFocus(false);
                        setInputSurnameFocus(true);
                        setInputNationalityIDFocus(false);
                        setInputFirstPhoneFocus(false);
                        setInputSecondPhoneFocus(false);
                        setInputMailFocus(false);
                        setInputTypePointFocus(false);
                        setInputBusinessFocus(false);
                        setInputDescriptionFocus(false);
                      }}
                      required
                      label={context.t(`component.CADialog.surnameInputLabel`)}
                      placeholder={context.t(
                        `component.CADialog.surnameInputPlaceholder`
                      )}
                      value={customer.surname !== null ? customer.surname : ""}
                      onChange={(e) => {
                        let surnameText = e.target.value;
                        setCustomer({
                          ...customer,
                          surname:
                            surnameText.split(" ")[1] !== undefined
                              ? surnameText.split(" ")[0] +
                              " " +
                              surnameText.split(" ")[1]
                              : surnameText,
                        });
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-3">
                    <Input
                      autoFocus={inputNationalityIDFocus}
                      onFocus={() => {
                        setInputNameFocus(false);
                        setInputSurnameFocus(false);
                        setInputNationalityIDFocus(true);
                        setInputFirstPhoneFocus(false);
                        setInputSecondPhoneFocus(false);
                        setInputMailFocus(false);
                        setInputTypePointFocus(false);
                        setInputBusinessFocus(false);
                        setInputDescriptionFocus(false);
                      }}
                      label={context.t(`['customers/add/bulk'].preview.nationalID`)}
                      placeholder={context.t(
                        `['customers/add/bulk'].preview.nationalID`
                      )}
                      value={
                        customer.nationalityID !== null
                          ? customer.nationalityID.substring(0, 11)
                          : ""
                      }
                      onChange={(e) => {
                        const value = e.target.value.trim();
                        if (value.length > 11) {
                          return;
                        }
                        setCustomer({
                          ...customer,
                          nationalityID: value,
                        });
                      }}
                    />
                  </Column>

                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <CSwitch
                      title={context.t(`component.CADialog.genderInputLabel`)}
                      label={context.t(`component.CADialog.genderMale`)}
                      labelLeft={context.t(`component.CADialog.genderFemale`)}
                      checked={!!customer.sex}
                      color="primary"
                      closeColor="purple"
                      onChange={(checked) => {
                        setCustomer({
                          ...customer,
                          sex: checked.target.checked === false ? 0 : 1,
                        });
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-2">
                    <CSwitch
                      title={context.t(`['customers/add'].disableOnlineBooking`)}
                      label={context.t(`['customers/add'].disableOnlineBookingLabel`)}
                      checked={!!customer.disable_online_booking || customer.black_listed > 0}  // Kara listede varsa true olacak
                      disabled={customer.black_listed > 0}
                      color="primary"
                      onChange={(checked) =>
                        setCustomer({
                          ...customer,
                          disable_online_booking: checked.target.checked
                        })
                      }
                    />
                  </Column>
                </Grid>

                <Grid className="mb-3">
                  <Column
                    className="xs-12 sm-12 md-12 lg-3"
                    style={{ position: "relative" }}
                  >
                    {process.env.APP_NAME === "management" ? (
                      <PhoneInput
                        countryCodeEditable={false}
                        className="input-phone-number"
                        inputStyle={{ border: "none", width: "100%", height: "70%", boxShadow: "0px 0px 4px 0px #f9f9f9", borderRadius: "4px" }}
                        containerStyle={{ marginBottom: "13px" }}
                        value={customer.phone}
                        inputProps={{ autoFocus: inputFirstPhoneFocus }}
                        onClick={() => {
                          setInputNameFocus(false);
                          setInputSurnameFocus(false);
                          setInputNationalityIDFocus(false);
                          setInputFirstPhoneFocus(true);
                          setInputSecondPhoneFocus(false);
                          setInputMailFocus(false);
                          setInputTypePointFocus(false);
                          setInputBusinessFocus(false);
                          setInputDescriptionFocus(false);
                        }}
                        placeholder={context.t(`customers.phone.placeHolder`)}
                        onChange={PhoneNumber_handleOnChange}
                      />
                    ) : (
                      <MaskedInput
                        autoFocus={inputFirstPhoneFocus}
                        required
                        label={context.t(`component.CADialog.phoneInputLabel`)}
                        disabled={
                          context.state.user.permission === 2 &&
                          (context.state.limited_permission.customer_info === 1 ||
                            context.state.limited_permission.customer_info === 0)
                        }
                        maskChar={""}
                        mask="999 999 9999"
                        startAdornment="+90"
                        value={customer.phone !== null ? customer.phone : ""}
                        placeholder="500 000 0000"
                        onChange={(e) => {
                          setInputNameFocus(false);
                          setInputSurnameFocus(false);
                          setInputNationalityIDFocus(false);
                          setInputFirstPhoneFocus(true);
                          setInputSecondPhoneFocus(false);
                          setInputMailFocus(false);
                          setInputTypePointFocus(false);
                          setInputBusinessFocus(false);
                          setInputDescriptionFocus(false);
                          setCustomer({
                            ...customer,
                            phone:
                              e.target.value.substring(0, 1) === "5" &&
                              e.target.value.replace(/\s/g, ""),
                          })
                        }}
                      />
                    )}
                    {customer.phone === null || customer.phone === "" ? (
                      <></>
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          height: "100%",
                          marginRight: "1vw",
                          display: "flex",
                          paddingTop: "1.5vh",
                          paddingLeft: "5px",
                        }}
                      >
                        <MuiThemeProvider theme={theme}>
                          <Tooltip title={context.t(`customers.whatsappTooltipTitle`)}>
                            <WhatsAppIcon
                              style={{
                                cursor: "pointer",
                                top: "2vh",
                                right: "3.5vw",
                                color: "green",
                                fontSize: "2.2em",
                                paddingRight: "10px",
                              }}
                              onClick={() => {
                                context.state.whatsapp_permission
                                  ? WhatsappIletisim()
                                  : toast.warn(
                                    context.t(`customers.whatsappWarnToast`)
                                  );
                              }}
                            />
                          </Tooltip>
                        </MuiThemeProvider>
                        {customer.black_listed === 1 && (
                          <MuiThemeProvider theme={blackListTheme}>
                            <Tooltip title={BLACK_LIST_STATUS[customer.black_listed].label} >
                              <HighlightOffRoundedIcon
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  top: "2vh",
                                  right: "3.5vw",
                                  fontSize: "2.2em",
                                  paddingRight: "10px",
                                }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        )}
                        {customer.black_listed === 3 && (
                          <MuiThemeProvider theme={blackListTheme}>
                            <Tooltip title={BLACK_LIST_STATUS[customer.black_listed].label} >
                              <HighlightOffRoundedIcon
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  top: "2vh",
                                  right: "3.5vw",
                                  fontSize: "2.2em",
                                  paddingRight: "10px",
                                }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        )}

                        {process.env.APP_NAME === "management" ? null : (
                          <MuiThemeProvider theme={theme}>
                            <Tooltip title={context.t(`customers.sendMessageTooltipTitle`)}>
                              <SmsOutlinedIcon
                                style={{
                                  cursor: "pointer",
                                  top: "2vh",
                                  right: "1.5vw",
                                  color: "black",
                                  fontSize: "2.2em",
                                  paddingRight: "10px",
                                }}
                                onClick={() => {
                                  setSmsSettings({
                                    ...smsSettings,
                                    id: [params.id],
                                  });
                                  setSmsSendDialog(true);
                                }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        )}
                      </div>
                    )}
                  </Column>

                  <Column
                    className="xs-12 sm-12 md-12 lg-3"
                    style={{ position: "relative" }}
                  >
                    <PhoneInput
                      className="input-phone-number"
                      inputStyle={{ border: "none", width: "100%", height: "70%", boxShadow: "0px 0px 4px 0px #f9f9f9", borderRadius: "4px" }}
                      containerStyle={{ marginBottom: "13px" }}
                      country={"tr"}
                      required={process.env.APP_NAME === "salon" ? customer.first_phone_number !== null || customer.first_phone_number !== "" : false  }
                      countryCodeEditable={false}
                      value={customer.secondary_phone}
                      inputProps={{ autoFocus: inputSecondPhoneFocus }}
                      onClick={() => {
                        setInputNameFocus(false);
                        setInputSurnameFocus(false);
                        setInputNationalityIDFocus(false);
                        setInputFirstPhoneFocus(false);
                        setInputSecondPhoneFocus(true);
                        setInputMailFocus(false);
                        setInputTypePointFocus(false);
                        setInputBusinessFocus(false);
                        setInputDescriptionFocus(false);
                      }}
                      placeholder="Enter a phone number"
                      onChange={SecondaryPhone_handleOnChange}
                      specialLabel={context.t(
                        `['customers/add'].secondPhoneInputLabel`
                      )}
                    />
                    {customer.secondary_phone === null ||
                      customer.secondary_phone === "" ? (
                      <></>
                    ) : (
                      <div style={{
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        right: "26px",
                        top: "36%",
                        transform: "translateY(-50%)",
                      }}>
                        <MuiThemeProvider theme={theme}>
                          <Tooltip title={context.t(`customers.whatsappTooltipTitle`)}>
                            <WhatsAppIcon
                              style={{
                                cursor: "pointer",
                                marginRight: "8px",
                                color: "green",
                                fontSize: "2.2em",
                              }}
                              onClick={() => {
                                context.state.whatsapp_permission
                                  ? WpIletisimIkinciNumara()
                                  : toast.warn(
                                    context.t(`customers.whatsappWarnToast`)
                                  );
                              }}
                            />
                          </Tooltip>
                        </MuiThemeProvider>
                        {customer.black_listed === 2 && (
                          <MuiThemeProvider theme={blackListTheme}>
                            <Tooltip title={BLACK_LIST_STATUS[customer.black_listed].label} >
                              <HighlightOffRoundedIcon
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  top: "2vh",
                                  right: "3.5vw",
                                  fontSize: "2.2em",
                                  paddingRight: "10px",
                                }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        )}
                        {customer.black_listed === 3 && (
                          <MuiThemeProvider theme={blackListTheme} >
                            <Tooltip title={BLACK_LIST_STATUS[customer.black_listed].label} >
                              <HighlightOffRoundedIcon
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  top: "2vh",
                                  right: "3.5vw",
                                  fontSize: "2.2em",
                                  paddingRight: "10px",
                                }}
                              />
                            </Tooltip>
                          </MuiThemeProvider>
                        )}
                      </div>
                    )}
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-3">
                    <Input
                      autoFocus={inputMailFocus}
                      onFocus={() => {
                        setInputNameFocus(false);
                        setInputSurnameFocus(false);
                        setInputNationalityIDFocus(false);
                        setInputFirstPhoneFocus(false);
                        setInputSecondPhoneFocus(false);
                        setInputMailFocus(true);
                        setInputTypePointFocus(false);
                        setInputBusinessFocus(false);
                        setInputDescriptionFocus(false);
                      }}
                      label={context.t(`component.CADialog.mailInputLabel`)}
                      disabled={
                        context.state.user.permission === 2 &&
                        (context.state.limited_permission.customer_info === 1 ||
                          context.state.limited_permission.customer_info === 0)
                      }
                      value={customer.email !== null ? customer.email : ""}
                      placeholder={context.t(`component.CADialog.mailInputPlaceholder`)}
                      onChange={(e) =>
                        setCustomer({ ...customer, email: e.target.value })
                      }
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-3">
                    <CSwitch
                      title={context.t(`component.CADialog.sendSmsInputLabel`)}
                      label={`${context.t(`component.CADialog.sendSmsFalse`)}/${context.t(`component.CADialog.sendSmsTrue`)}`}
                      checked={!!customer.send_sms}
                      color="primary"
                      onChange={(checked) =>
                        setCustomer({ ...customer, send_sms: checked.target.checked })
                      }
                    />
                  </Column>



                </Grid>

                <Grid>
                  {showBirthdayDate ? (
                    <Column
                      className="xs-12 sm-12 md-12 lg-3"
                      style={{ display: "flex", position: "relative" }}
                    >
                      <div style={{ position: "absolute", top: "-2vh", left: "30px" }}>
                        <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                          {context.t(`["customers/add"].birthdayInputLabel`)}
                        </span>
                      </div>
                      <CostumSelect
                        className="mt-1"
                        style={{ cursor: "pointer", width: "60%" }}
                        value={selectedDay ? selectedDay : 3}
                        onChange={(e) => {
                          setSelectedDay(e.target.value);
                        }}
                      >
                        {days.map((item) => (
                          <option value={item.value}>{item.name}</option>
                        ))}
                      </CostumSelect>

                      <CostumSelect
                        className="m-1"
                        style={{ cursor: "pointer" }}
                        value={
                          selectedMonth
                            ? selectedMonth
                            : moment(new Date() - 568080000000).format("MM")
                        }
                        onChange={(e) => {
                          setSelectedMonth(e.target.value);
                        }}
                      >
                        {months.map((item) => (
                          <option value={item.value}>{item.name}</option>
                        ))}
                      </CostumSelect>

                      <CostumSelect
                        className="mt-1"
                        style={{ cursor: "pointer", width: "75%" }}
                        value={
                          selectedYear === ""
                            ? moment(new Date() - 568080000000).format("YYYY")
                            : selectedYear
                        }
                        onChange={async (e) => {
                          setSelectedYear(e.target.value);
                        }}
                      >
                        {years.map((item) => (
                          <option value={item.value}>{item.name}</option>
                        ))}
                      </CostumSelect>
                    </Column>
                  ) : (
                    <Column className="xs-12 sm-12 md-12 lg-3">
                      <div
                        style={{
                          boxShadow: "0px 2px 12px rgb(0 0 0 / 4%)",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "start",
                          height: "59px",
                        }}
                        onClick={() => {
                          setShowBirthdayDate(true);
                        }}
                      >
                        <DateRangeOutlined
                          color="disabled"
                          style={{ padding: "10px" }}
                        />
                        <span>
                          {context.t(`["customers/add"].birthdayInputLabel`)}
                        </span>
                      </div>
                    </Column>
                  )}

                  <Column className="xs-12 sm-12 md-12 lg-3">
                    <Input
                      autoFocus={inputTypePointFocus}
                      onFocus={() => {
                        setInputNameFocus(false);
                        setInputSurnameFocus(false);
                        setInputNationalityIDFocus(false);
                        setInputFirstPhoneFocus(false);
                        setInputSecondPhoneFocus(false);
                        setInputMailFocus(false);
                        setInputTypePointFocus(true);
                        setInputBusinessFocus(false);
                        setInputDescriptionFocus(false);
                      }}
                      label={context.t(`[receipt/detail/id].payments.typePOINT`)}
                      value={customer.point !== null ? customer.point : ""}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          point: /[0-9,.]+/.test(e.target.value)
                            ? parseFloat(e.target.value)
                            : 0,
                        })
                      }
                    />
                  </Column>

                  <Column className="xs-12 sm-12 md-12 lg-3">
                    <Input
                      autoFocus={inputBusinessFocus}
                      onFocus={() => {
                        setInputNameFocus(false);
                        setInputSurnameFocus(false);
                        setInputNationalityIDFocus(false);
                        setInputFirstPhoneFocus(false);
                        setInputSecondPhoneFocus(false);
                        setInputMailFocus(false);
                        setInputTypePointFocus(false);
                        setInputBusinessFocus(true);
                        setInputDescriptionFocus(false);
                      }}
                      label={context.t(`component.CADialog.relatedBusinessInputLabel`)}
                      placeholder={context.t(
                        `component.CADialog.relatedBusinessInputPlaceholder`
                      )}
                      value={
                        customer.related_company !== null
                          ? customer.related_company
                          : ""
                      }
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          related_company: e.target.value,
                        })
                      }
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-3">
                    <Select
                      label={context.t(`['customers/add'].selectPhoneNumberForMessage`)}
                      items={selectPhoneNumberList}
                      labelKey="name"
                      valueKey="id"
                      selected={
                        selectPhoneNumber[0]
                          ? selectPhoneNumber[0].id
                          : selectPhoneNumberList[0].id
                      }
                      returnFullObject
                      handler={(filterPicker) => {
                        if (filterPicker.type === 1 && filterPicker.type === 2 && (!customer.secondary_phone || customer.secondary_phone === "")) {
                          toast.warning(context.t(`['customers/add'].secondPhoneNumberWarning`));
                          return;
                        }
                        setSelectPhoneNumber([filterPicker]);
                      }}
                    />
                  </Column>
                </Grid>

                <Grid>
                  <Column className="xs-12 sm-12 md-6">
                    <Input
                      autoFocus={inputDescriptionFocus}
                      onFocus={() => {
                        setInputNameFocus(false);
                        setInputSurnameFocus(false);
                        setInputNationalityIDFocus(false);
                        setInputFirstPhoneFocus(false);
                        setInputSecondPhoneFocus(false);
                        setInputMailFocus(false);
                        setInputTypePointFocus(false);
                        setInputBusinessFocus(false);
                        setInputDescriptionFocus(true);
                      }}
                      label={context.t(`component.CADialog.descriptionInputLabel`)}
                      value={customer.description !== null ? customer.description : ""}
                      maxRows={6}
                      minRows={3}
                      multiline={true}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          description: e.target.value,
                        })
                      }
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-6">
                    <Button
                      type="submit"
                      title={context.t(`component.CADialog.updateCustomerDetails`)}
                      icon="update"
                      backgroundColor="primary"
                      textColor="white"
                      disabled={
                        context.state.user.permission === 2 &&
                        context.state.limited_permission.customer_info === 1
                      }
                      fullWidth
                      onClick={() => updateSingleCustomer()}
                    />

                    {customer.is_passive ? (
                      <>
                        <Button
                          className="mt-1"
                          type="submit"
                          title={context.t(`component.CADialog.changeCutomertoActive`)}
                          icon="check"
                          backgroundColor="primary-opacity"
                          textColor="primary"
                          disabled={
                            context.state.user.permission === 2 &&
                            context.state.limited_permission.customer_info === 1
                          }
                          fullWidth
                          onClick={() => makeActiveSingleCustomer()}
                        />

                        {/*
         <Button
           className="mt-1"
           type="submit"
           title="Müşteriyi Kalıcı Olarak Sil"
           icon="block"
           backgroundColor="red-opacity"
           textColor="red"
           disabled={context.state.user.permission !== 1}
           fullWidth
           onClick={() => setDeleteConfirm(true)}
        />
        */}
                      </>
                    ) : (
                      <>
                        <Button
                          className="mt-1"
                          type="submit"
                          title={context.t(`component.CADialog.changeCutomertoPassive`)}
                          icon="block"
                          backgroundColor="red-opacity"
                          textColor="red"
                          disabled={
                            context.state.user.permission === 2 &&
                            context.state.limited_permission.customer_info === 1
                          }
                          fullWidth
                          onClick={() => setPassiveConfirm(true)}
                        />
                      </>
                    )}

                    <ModalDialog
                      open={deleteConfirm}
                      closeHandler={() => setDeleteConfirm(false)}
                      title={context.t(`component.CADialog.delete.title`)}
                      buttons={[
                        {
                          title: context.t(
                            `component.CADialog.delete.confirmButtonTitle`
                          ),
                          icon: "check",
                          backgroundColor: "primary-opacity",
                          textColor: "primary",
                          onClick: () => deleteSingleCustomer(),
                        },
                        {
                          title: context.t(
                            `component.CADialog.delete.discardButtonTitle`
                          ),
                          icon: "close",
                          textColor: "grey",
                        },
                      ]}
                    >
                      <Alert severity="warning">
                        <b>{context.t(`component.CADialog.delete.alertText`)}</b>
                        <br />
                        {context.t(`component.CADialog.delete.alertTitleText`)}
                      </Alert>
                    </ModalDialog>

                    <ModalDialog
                      open={passiveConfirm}
                      closeHandler={() => setPassiveConfirm(false)}
                      title={context.t(`component.CADialog.confirmChangeCustomerTitle`)}
                      buttons={[
                        {
                          title: context.t(
                            `component.CADialog.delete.confirmButtonTitle`
                          ),
                          icon: "check",
                          backgroundColor: "primary-opacity",
                          textColor: "primary",
                          onClick: () => makePassiveSingleCustomer(),
                        },
                        {
                          title: context.t(
                            `component.CADialog.delete.discardButtonTitle`
                          ),
                          icon: "close",
                          textColor: "grey",
                        },
                      ]}
                    >
                      <Alert severity="warning">
                        <b>{context.t(`component.CADialog.confirmCustomerTitle`)}</b>
                        {context.t(`component.CADialog.confirmTitle1`)}{" "}
                        <b>{context.t(`component.CADialog.confirmTitle2`)}</b>{" "}
                        {context.t(`component.CADialog.confirmTitle3`)}
                      </Alert>
                    </ModalDialog>
                  </Column>
                </Grid>

                <Grid>

                </Grid>

                <AlertDialog
                  title={context.t(`sms.sendType.sendSMS`)}
                  open={smsSendDialog}
                  fullWidth
                  maxWidth="sm"
                  closeHandler={() => setSmsSendDialog(false)}
                  buttons={[
                    {
                      title: context.t(`component.sms_review.sendButtonTitle`),
                      icon: "send",
                      backgroundColor: "green",
                      style: {
                        height: "40px",
                      },
                      textColor: "white",
                      onClick: () => getSMSReview(),
                    },
                    {
                      title: context.t(`component.sms_review.discardButtonTitle`),
                      icon: "close",
                      backgroundColor: "white",
                      style: {
                        border: "1px solid #666666",
                        height: "40px",
                      },
                      textColor: "black",
                      onClick: () => setSmsSendDialog(false),
                    },
                  ]}
                >
                  <Grid>
                    <Column className="sx-12 sm-12 md-12 lg-4">
                      <Select
                        label={context.t(`sms.send.template`)}
                        items={[...smsTemplates]}
                        selected={selectedTemplate?.name ?? ""}
                        labelKey="name"
                        valueKey="name"
                        returnFullObject
                        handler={(template) => {
                          setSelectedTemplate(template);
                          setSmsSettings({
                            ...smsSettings,
                            message: template.content,
                          });
                        }}
                      />
                    </Column>
                    <Column className="sx-12 sm-12 md-12 lg-5"></Column>
                    <Column
                      className="sx-12 sm-12 md-12 lg-3 mb-2"
                      style={{ display: "flex", alignItems: "end" }}
                    >
                      <Button
                        className="mb-1 ml-1"
                        title={context.t(`sms.send.autoText.shortCode`)}
                        size="xs"
                        textColor="purple"
                        outlined
                        disabled={shortCode === ""}
                        onClick={() => {
                          setSmsSettings({
                            ...smsSettings,
                            message: `${smsSettings.message} SMS iptali için ${shortCode} yazın 4607'a gönderin.`,
                          });
                        }}
                      />
                    </Column>
                    <Column className="sx-12 sm-12 md-12 lg-12">
                      <Input
                        label={context.t(`sms.send.messageInputLabel`)}
                        multiline={true}
                        rows={10}
                        value={smsSettings.message}
                        onChange={(e) =>
                          setSmsSettings({
                            ...smsSettings,
                            message: e.target.value,
                          })
                        }
                        helperText={CalculateMessageCharCount(smsSettings.message, "1")}
                      />
                    </Column>
                  </Grid>
                </AlertDialog>

                <AlertDialog
                  title={
                    <Grid>
                      <Column className="xs-12 sm-12 md-12 lg-8">
                        {context.t(`customers.whatsappTooltipTitle`)}
                      </Column>
                      <Column className="xs-12 sm-12 md-12 lg-2"></Column>
                      <Column className="xs-12 sm-12 md-12 lg-2">
                        <WhatsAppIcon
                          style={{
                            color: "green",
                            fontSize: "2.2em",
                          }}
                        />
                      </Column>
                    </Grid>
                  }
                  open={whatsappConDialog}
                  fullWidth
                  maxWidth="sm"
                  closeHandler={() => setWhatsappConDialog(false)}
                  buttons={[
                    {
                      title: context.t(`component.sms_review.sendButtonTitle`),
                      icon: "send",
                      backgroundColor: "green",
                      style: {
                        height: "40px",
                      },
                      textColor: "white",
                      onClick: () =>
                        window.open(
                          `https://api.whatsapp.com/send?phone=${whatsappSettings.phone_number}&text=${whatsappSettings.message}`,
                          "_blank"
                        ),
                    },
                    {
                      title: context.t(`component.sms_review.discardButtonTitle`),
                      icon: "close",
                      backgroundColor: "white",
                      style: {
                        border: "1px solid #666666",
                        height: "40px",
                      },
                      textColor: "black",
                      onClick: () => setWhatsappConDialog(false),
                    },
                  ]}
                >
                  <Grid>
                    <Column className="sx-12 sm-12 md-12 lg-4">
                      <Select
                        label={context.t(`sms.send.template`)}
                        items={[...smsTemplates]}
                        selected={selectedTemplate?.name ?? ""}
                        labelKey="name"
                        valueKey="name"
                        returnFullObject
                        handler={(template) => {
                          setSelectedTemplate(template);
                          setWhatsappSettings({
                            ...whatsappSettings,
                            message: template.content,
                          });
                        }}
                      />
                    </Column>
                    <Column className="sx-12 sm-12 md-12 lg-5"></Column>
                    <Column className="sx-12 sm-12 md-12 lg-12">
                      <Input
                        label={context.t(`sms.send.messageInputLabel`)}
                        multiline={true}
                        rows={10}
                        value={whatsappSettings.message}
                        onChange={(e) =>
                          setWhatsappSettings({
                            ...whatsappSettings,
                            message: e.target.value,
                          })
                        }
                      />
                    </Column>
                  </Grid>
                </AlertDialog>

                <SMSReview
                  open={openSmsReviewDialog}
                  closeHandler={() => {
                    setOpenSmsReviewDialog(false);
                  }}
                  sendSMS={sendSelectiveSms}
                  sms_content={smsSettings.message}
                  total_number={totalNumber}
                  total_sms_credit={totalSmsCredit}
                />
              </>
            )}
            {(context.current_flag & context.state.FLAGS.SALON_RANDEVU &&
              tab === 1 && (
                <React.Fragment>
                  <Grid>
                    <Grid className="mb-2">
                      <Column
                        className={`xs-12 sm-12 md-12 ${filterExpandedHistory ? "lg-3" : "lg-10"
                          } mt-2`}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={filterExpandedHistory}
                              onChange={async (e) => {
                                await setFilterExpandedHistory(e.target.checked);
                              }}
                            />
                          }
                          label={
                            <FCLabel>
                              <i className="material-icons">filter_list</i>{" "}
                              <span>
                                {context.t(`['appointments/filter'].filterTitle`)}
                              </span>
                            </FCLabel>
                          }
                        />
                      </Column>
                      {filterExpandedHistory && (
                        <>
                          <Column className="xs-12 sm-12 md-12 lg-3">
                            <DatePickerInput
                              className="mb-0"
                              label={context.t(`receipts.filter.startInputLabel`)}
                              value={
                                new Date(moment(lastTransactions.start).toISOString())
                              }
                              maxDate={lastTransactions.end}
                              onChange={async (start) => {
                                await setLoaded(false);
                                await setLastTransactions({
                                  ...lastTransactions,
                                  start: moment(start).toISOString(),
                                  selected_filter_picker: null,
                                });
                              }}
                            />
                          </Column>
                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <DatePickerInput
                              className="mb-0"
                              label={context.t(`receipts.filter.endInputLabel`)}
                              value={lastTransactions.end}
                              minDate={
                                new Date(moment(lastTransactions.start).toISOString())
                              }
                              onChange={async (end) => {
                                await setLoaded(false);
                                await setLastTransactions({
                                  ...lastTransactions,
                                  end: moment(end).toISOString(),
                                  selected_filter_picker: null,
                                });
                              }}
                            />
                          </Column>
                          <Column className="xs-12 sm-12 md-12 lg-2">
                            <Select
                              label={context.t(`appointments.dayWeekSelectLabel`)}
                              items={lastTransactions.filterPicker}
                              labelKey="label"
                              valueKey="id"
                              selected={
                                lastTransactions.selected_filter_picker
                                  ? `${lastTransactions.selected_filter_picker.id}`
                                  : null
                              }
                              returnFullObject
                              handler={(filterPicker) => {
                                setLoaded(false);
                                setLastTransactions({
                                  ...lastTransactions,
                                  selected_filter_picker: filterPicker,
                                });
                              }}
                            />
                          </Column>
                        </>
                      )}
                      <Column className="xs-12 sm-12 md-12 lg-2">
                        <Button
                          className="mt-1"
                          type="submit"
                          title={context.t(`scaffold.QAItems.createReceipt`)}
                          icon="content_paste"
                          backgroundColor="primary-opacity"
                          textColor="primary"
                          fullWidth
                          onClick={() => addReceiptBySelectedCustomer()}
                        />
                      </Column>
                    </Grid>
                    <Column className="xs-12 sm-12">
                      <Table
                        refreshAction={() => getHistorySingleCustomer()}
                        loaded={loaded}
                        headings={{
                          id: {
                            label: context.t(`receipts.headings.id`),
                            style: { width: 100 },
                          },
                          is_package: {
                            label: context.t(`receipts.headings.billType`),
                            style: { width: 150 },
                          },
                          created_at: {
                            label: context.t(`receipts.headings.createdAt`),
                            style: { width: 200 },
                          },
                          info: {
                            label: context.t(`receipts.headings.info`),
                            style: { width: 400 },
                            limited_line: 2,
                          },
                          all_amount: {
                            label: context.t(`receipts.headings.allAmount`),
                            suffix: context.state.currency ? context.state.currency : "₺",
                            style: { width: 100 },
                          },
                          paid: {
                            label: context.t(`receipts.headings.paid`),
                            suffix: context.state.currency ? context.state.currency : "₺",
                            style: { width: 100 },
                          },
                          debt_total: {
                            label: context.t(`receipts.headings.debtTotal`),
                            suffix: context.state.currency ? context.state.currency : "₺",
                            style: { width: 100 },
                          },
                          _: { label: context.t(`component.actionHeadingText`) },
                        }}
                        replacements={lastTransactions.replacements}
                        rows={lastTransactions.data}
                        pagination={lastTransactions.pagination}
                        buttons={[
                          {
                            icon: "launch",
                            title: context.t(`["customers/rating"].viewToComment`),
                            pushEffect: true,
                            onClick: (row) =>
                              history.push({
                                pathname: `/receipts/detail/${row.id}`,
                              }),
                          },
                        ]}
                      />
                    </Column>
                  </Grid>
                </React.Fragment>
              )) ||
              null}
            {tab === 2 && (
              <React.Fragment>
                <Grid>
                  <Grid className="mb-2">
                    <Column
                      className={`xs-12 sm-12 md-12 ${filterExpandedStatistic ? "lg-2" : "lg-10"
                        } mt-2`}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={filterExpandedStatistic}
                            onChange={(e) => {
                              setFilterExpandedStatistic(e.target.checked);
                            }}
                          />
                        }
                        label={
                          <FCLabel>
                            <i className="material-icons">filter_list</i>{" "}
                            <span>
                              {context.t(`['appointments/filter'].filterTitle`)}
                            </span>
                          </FCLabel>
                        }
                      />
                    </Column>
                    {filterExpandedStatistic && (
                      <>
                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <DatePickerInput
                            className="mb-1"
                            label={context.t(`['appointments/filter'].startInputLabel`)}
                            value={
                              new Date(
                                moment(
                                  customerAppointmentsFilterStartDate
                                ).toISOString()
                              )
                            }
                            onChange={(start_date) => {
                              setLoaded(false);
                              setCustomerAppointmentsFilterStartDate(
                                ConvertTimeToZulu(moment(start_date).unix())
                              );
                              setSelectedFilterPickerStatistic(null);
                            }}
                          />
                        </Column>
                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <DatePickerInput
                            className="mb-1"
                            label={context.t(`['appointments/filter'].endInputLabel`)}
                            value={
                              new Date(
                                moment(customerAppointmentsFilterEndDate).toISOString()
                              )
                            }
                            onChange={(end_date) => {
                              setLoaded(false);
                              setCustomerAppointmentsFilterEndDate(
                                ConvertTimeToZulu(moment(end_date).unix())
                              );
                              setSelectedFilterPickerStatistic(null);
                            }}
                          />
                        </Column>
                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <Select
                            label={context.t(`appointments.dayWeekSelectLabel`)}
                            items={filterPickerStatistic}
                            labelKey="label"
                            valueKey="id"
                            selected={
                              selectedFilterPickerStatistic
                                ? `${selectedFilterPickerStatistic.id}`
                                : null
                            }
                            returnFullObject
                            handler={async (filterPicker) => {
                              setLoaded(false);
                              setSelectedFilterPickerStatistic(filterPicker);
                            }}
                          />
                        </Column>
                        <Column className="xs-12 sm-12 md-12 lg-2">
                          <Select
                            label={context.t(`appointments.customerStateSelectLabel`)}
                            items={customerStateFilter}
                            labelKey="label"
                            valueKey="id"
                            selected={
                              selectedCustomerStateFilter
                                ? `${selectedCustomerStateFilter.id}`
                                : null
                            }
                            returnFullObject
                            handler={async (customerStateFilter) => {
                              setLoaded(false);
                              setSelectedCustomerStateFilter(customerStateFilter);
                            }}
                          />
                        </Column>
                      </>
                    )}
                    <Column className="xs-12 sm-12 md-12 lg-2">
                      <Button
                        className="mt-1"
                        type="submit"
                        title={context.t(`scaffold.QAItems.createAppointment`)}
                        icon="date_range"
                        backgroundColor="primary-opacity"
                        textColor="primary"
                        onClick={() => setCheckoutDataDialog(true)}
                      />
                    </Column>
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <Column
                    className="xs-12 sm-12 md-12 lg-4"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    <div
                      style={{
                        color: "#7c7c7c",
                        fontSize: "24px",
                      }}
                    >
                      {customer.name.concat(" " + customer.surname)}
                    </div>
                  </Column>

                  {customerAppointmentsFilterData.length > 0 &&
                    customerAppointmentsFilterData.map((item) => {
                      return (
                        <Column className="xs-12 sm-12 md-12 lg-4">
                          <StatsCard
                            key={item.title.concat(item.value)}
                            className="mb-2"
                            icon={{ label: item.label, color: item.color }}
                            title={
                              item.title === "İptal Edildi"
                                ? "İptal Edildi"
                                : context.t(item.title)
                            }
                            value={item.value}
                          />
                        </Column>
                      );
                    })}
                </Grid>
                {/* To be used when the table structure changes */}

                <Grid>
                  <Column className="xs-12 sm-12">
                    {appointmentsListLoader ? (
                      <Table
                        refreshAction={() =>
                          getFilterByAppointmentsStatistics(
                            appointments.pagination.page
                          )
                        }
                        loaded={loaded}
                        headings={{
                          created_at: {
                            label: context.t(
                              `['appointments/filter'].headings.createdAt`
                            ),
                          },
                          staff: {
                            label: context.t(
                              `['appointments/filter'].headings.staffFullName`
                            ),
                          },
                          appointment_start_date: {
                            label: context.t(
                              `['appointments/filter'].headings.appointmentStartDate`
                            ),
                          },
                          service: {
                            label:
                              context.t(
                                `['appointments/filter'].headings.serviceName`
                              ) +
                              " " +
                              ` /  ${context.t(`customers.serviceTableLabel`)}`,
                          },
                          note: {
                            label: context.t(
                              `['appointments/filter'].headings.appointmentNote`
                            ),
                          },
                          active: {
                            label: context.t(`['appointments/filter'].situations`),
                          },
                          _: { label: context.t(`customers.contractHeading.transactions`) },
                        }}
                        rows={appointments.data}
                        pagination={appointments.pagination}
                        buttons={[
                          {
                            title: context.t(`customers.routeToAppointment`),
                            icon: "find_in_page",
                            textColor: "blue",
                            onClick: (row) => {
                              localStorage.setItem(
                                "selected_date",
                                moment(row.appointmentDate._i)
                              );
                              setTimeout(() => {
                                history.push({
                                  pathname: "/appointments",
                                  state: {
                                    date: row.appointmentDate._i,
                                  },
                                });
                              }, 250);
                            },
                            disabled: (row) => {
                              if (row.appointment_status === 2) {
                                return true;
                              } else {
                                return false;
                              }
                            },
                          },
                          {
                            title: context.t(`["services/requests"].cancel.cancelButton`),
                            icon: "cancel",
                            textColor: "red",
                            disabled: (row) => {
                              if (row.appointment_status === 2) {
                                return true;
                              } else {
                                return false;
                              }
                            },
                            onClick: (row) => {
                              setDeleteAppointmentModal(true);
                              setDeleteAppointmentId(row.id);
                            },
                          },
                        ]}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={
                            process.env.APP_NAME === "salon"
                              ? Loader
                              : process.env.APP_NAME === "en"
                                ? LoaderEN
                                : LoaderSM
                          }
                          width="80"
                          height="80"
                          alt="loading"
                        />
                      </div>
                    )}
                  </Column>
                </Grid>

                <AlertDialog
                  title={context.t(`customers.acceptAppointmentDecline`)}
                  open={deleteAppointmentModal}
                  closeHandler={() => setDeleteAppointmentModal(false)}
                  buttons={[
                    {
                      title: context.t(`["customers/rating"].delete.confirmButtonTitle`),
                      icon: "check",
                      backgroundColor: "primary-opacity",
                      textColor: "primary",
                      onClick: () => cancelAppointment(deleteAppointmentId),
                    },
                    {
                      title: context.t(`["customers/rating"].delete.discardButtonTitle`),
                      icon: "close",
                      textColor: "grey",
                    },
                  ]}
                >
                  <Alert severity="warning">
                    <b>{context.t(`customers.appointmentDeclineWarn1`)}</b>
                    <br />
                    {context.t(`customers.appointmentDeclineWarn2`)}
                  </Alert>
                </AlertDialog>
              </React.Fragment>
            )}
            {tab === 3 && (
              <React.Fragment>
                <AuthContainer authorities={[1]} limited_permission="gallery">
                  <Grid>
                    <Column className="xs-12 sm-12 mb-3">
                      <input
                        id="imageUploadInput"
                        type="file"
                        multiple
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => uploadMultiPhotos(e)}
                      />
                      <ImageUploadBox htmlFor="imageUploadInput">
                        <i className="material-icons">cloud_upload</i>{" "}
                        {context.t(`['settings/service-gallery'].uploadButtonTitle`)}
                      </ImageUploadBox>
                    </Column>

                    <Column className="xs-12 sm-12 mb-2">
                      <Button
                        icon="delete"
                        title={context.t(
                          `['settings/service-gallery'].deleteSelectedButtonTitle`,
                          {
                            count: selectedPhotos.length,
                          }
                        )}
                        disabled={!selectedPhotos.length > 0}
                        textColor="red"
                        backgroundColor="red-opacity"
                        transitionEffect
                        onClick={() => setDeleteModal(true)}
                      />

                      {context.state.company_license.accounting && (
                        <p style={{ display: "inline-block", marginLeft: "10px" }}>
                          {context.t(`[settings/service-gallery].numberPhoto`)}:{" "}
                          {photos ? photos.length : 0} /{" "}
                          {
                            accountings[
                            context.state.company_license.accounting === 2 &&
                              context.state.company_license.appointment_limitation > 0
                              ? 3
                              : context.state.company_license.accounting
                            ]
                          }
                        </p>
                      )}
                    </Column>

                    {loadedCustomer ? (
                      <>
                        {photos &&
                          photos.map((item, index) => (
                            <Column key={index} className="xs-12 sm-12 md-6 lg-6 xl-6 mb-4">
                              <PhotoContainer
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedPhotos(
                                    selectedPhotos.includes(item.id)
                                      ? selectedPhotos.filter((id) => id !== item.id)
                                      : [...selectedPhotos, item.id]
                                  );
                                }}
                              >
                                {item.before_photo_path ? (
                                  <Column style={{ width: "100%" }}>
                                    <Typography className="mb-1">{context.t(`customers.beforePhoto`)}</Typography>
                                    <GalleryPhoto
                                      onClick={() => {
                                        if (!selectedPhotos.includes(item.id)) {
                                          selectedPhotos[item.id] = true;
                                        }
                                      }}
                                      src={`${context.api_endpoint.replace("api/v1", "")}${context.state.company_id
                                        }/${item.before_photo_path}`}
                                    />
                                    <Dialog
                                      key={item.id}
                                      onClose={() => {
                                        selectedPhotos[item.id] = false;
                                      }}
                                      open={selectedPhotos[item.id]}
                                    >
                                      <div
                                        style={{
                                          width: "500px",
                                          height: "500px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <img
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            cursor: "pointer",
                                            objectFit: "contain",
                                          }}
                                          onClick={() => (selectedPhotos[item.id] = false)}
                                          src={`${context.api_endpoint.replace("api/v1", "")}${context.state.company_id
                                            }/${item.before_photo_path}`}
                                        />
                                      </div>
                                    </Dialog>
                                  </Column>
                                ) : (
                                  <Grid
                                    style={{ justifyContent: "center", position: "relative" }}
                                  >
                                    <Typography
                                      className="mb-1"
                                      style={{ position: "absolute", left: 12 }}
                                    >
                                      {context.t(`customers.beforePhoto`)}
                                    </Typography>
                                    <img src={CloudComputing} alt="not found" />
                                  </Grid>
                                )}
                                {item.after_photo_path ? (
                                  <Column style={{ width: "100%" }}>
                                    <Typography className="mb-1">{context.t(`customers.afterPhoto`)}</Typography>
                                    <GalleryPhoto
                                      onClick={() => {
                                        if (!selectedPhotos.includes(item.id)) {
                                          selectedPhotos[item.id] = true;
                                        }
                                      }}
                                      src={`${context.api_endpoint.replace("api/v1", "")}${context.state.company_id
                                        }/${item.after_photo_path}`}
                                    />
                                    <Dialog
                                      key={item.id}
                                      onClose={() => (selectedPhotos[item.id] = false)}
                                      open={selectedPhotos[item.id]}
                                    >
                                      <div
                                        style={{
                                          width: "500px",
                                          height: "500px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <img
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            cursor: "pointer",
                                            objectFit: "contain",
                                          }}
                                          onClick={() => (selectedPhotos[item.id] = false)}
                                          src={`${context.api_endpoint.replace("api/v1", "")}${context.state.company_id
                                            }/${item.after_photo_path}`}
                                        />
                                      </div>
                                    </Dialog>
                                  </Column>
                                ) : (
                                  <Grid
                                    style={{ justifyContent: "center", position: "relative" }}
                                  >
                                    <Typography
                                      className="mb-1"
                                      style={{ position: "absolute", left: 12 }}
                                    >
                                      {context.t(`customers.afterPhoto`)}
                                    </Typography>
                                    <img src={CloudComputing} alt="not found" />
                                  </Grid>
                                )}
                                <PhotoProcesses>
                                  <Checkbox
                                    color="primary"
                                    checked={selectedPhotos.includes(item.id)}
                                  />
                                  <div className="xs-12 sm-12 mr-2">
                                    <input
                                      id="imageUploadInputNext"
                                      type="file"
                                      multiple
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      onChange={async (e) => {
                                        if (selectedPhotos.length > 0) {
                                          await uploadMultiPhotosNext(e, selectedPhotos[0]);
                                        } else if (selectedPhotos.length === 0) {
                                          await uploadMultiPhotosNext(e, item.id);
                                        }
                                      }}
                                    />
                                    <ImageUploadBoxNext htmlFor="imageUploadInputNext">
                                      <i className="material-icons">cloud_upload</i>{" "}
                                      {context.t(`['settings/gallery'].nextPhoto`)}
                                    </ImageUploadBoxNext>
                                  </div>
                                </PhotoProcesses>
                              </PhotoContainer>
                            </Column>
                          ))}
                      </>
                    ) : (
                      <LoadingContainer>
                        <img
                          src={
                            process.env.APP_NAME === "salon"
                              ? Loader
                              : process.env.APP_NAME === "en"
                                ? LoaderEN
                                : LoaderSM
                          }
                          width="100"
                          height="100"
                          alt="loading"
                        />
                      </LoadingContainer>
                    )}

                    <AlertDialog
                      title={context.t(`['settings/service-gallery'].delete.title`)}
                      maxWidth="sm"
                      fullWidth
                      open={deleteModal}
                      closeHandler={() => setDeleteModal(false)}
                      buttons={[
                        {
                          title: context.t(
                            `['settings/service-gallery'].delete.confirmButtonTitle`
                          ),
                          icon: "check",
                          backgroundColor: "primary-opacity",
                          textColor: "primary",
                          onClick: () => deleteMultiPhotos(),
                        },
                        {
                          title: context.t(
                            `['settings/service-gallery'].delete.discardButtonTitle`
                          ),
                          icon: "close",
                          textColor: "grey",
                        },
                      ]}
                      textType
                    >
                      <b>
                        {context.t(`['settings/service-gallery'].delete.alertBoldText`)}
                      </b>
                      <br />
                      {context.t(`['settings/service-gallery'].delete.alertText`)}
                      <Select
                        className="mt-2"
                        label={context.t(`['settings/service-gallery'].photoDelete`)}
                        items={filterBeforeInsert}
                        labelKey="label"
                        valueKey="id"
                        selected={
                          selectedFilterBeforeInsert
                            ? `${selectedFilterBeforeInsert.id}`
                            : null
                        }
                        returnFullObject
                        handler={(filterBefore) => {
                          setLoaded(false);
                          setSelectedFilterBeforeInsert(filterBefore);
                        }}
                      />
                    </AlertDialog>
                  </Grid>
                </AuthContainer>
              </React.Fragment>
            )}
            {tab === 4 && (
              <Grid>
                <Column className="xs-12 sm-12">
                  <Table
                    refreshAction={() => getAgreement(paginationAgreement.page)}
                    headings={{
                      date: {
                        label: context.t(`customers.contractHeading.date`),
                      },
                      agreement_name: {
                        label: context.t(`customers.contractHeading.agreement`),
                      },
                      bytes: {
                        label: context.t(`customers.contractHeading.bytes`),
                        suffix: "mb",
                      },
                      _: {
                        label: context.t(`customers.contractHeading.transactions`),
                      },
                    }}
                    rows={agreements}
                    loaded={agreementLoad}
                    pagination={paginationAgreement}
                    buttons={[
                      {
                        title: "",
                        icon: "find_in_page",
                        textColor: "blue",
                        onClick: (row) => window.open(row.url, "_blank"),
                      },
                      {
                        title: "",
                        icon: <WhatsApp fontSize="small" />,
                        textColor: "green",
                        onClick: (row) => {
                          if (!context.state.whatsapp_permission) {
                            toast.warning(
                              context.t(`customers.whatsappWarnToast`)
                            );
                          } else {
                            setWpAprovalModal(true);
                            setSendWpId(row.id);
                            setSendWpCustomerName(row.name);
                            setSendWpAgreementName(row.agreement_name);
                          }
                        },
                      },
                      {
                        title: "",
                        icon: "sms",
                        textColor: "orange",
                        onClick: (row) => {
                          setSmsAprovalModal(true);
                          setSendSmsId(row.id);
                          setSendSmsCustomerName(customer.full_name);
                          setSendSmsAgreementName(row.agreement_name);
                        },
                      },
                      {
                        title: "",
                        icon: "delete_forever",
                        textColor: "red",
                        onClick: (row) => {
                          setAgreementDeleteId(row.id);
                          setDeleteAgreementDialog(true);
                        },
                      },
                    ]}
                  />
                </Column>

                {/* **
               sms onayı için açılan dialog
               */}
                <AlertDialog
                  title={context.t(`customers.sendContractViaSMS`)}
                  open={smsAprovalModal}
                  closeHandler={() => setSmsAprovalModal(false)}
                  buttons={[
                    {
                      title: context.t(`component.sms_review.sendButtonTitle`),
                      icon: "send",
                      backgroundColor: "primary-opacity",
                      textColor: "primary",
                      onClick: () => {
                        sendSMSAgreement(sendSmsId);
                      },
                    },
                    {
                      title: context.t(`component.sms_review.discardButtonTitle`),
                      icon: "clear",
                      textColor: "grey",
                      onClick: () => {
                        setSendSmsId(null);
                        setSendSmsCustomerName(null);
                        setSendSmsAgreementName(null);
                      },
                    },
                  ]}
                >
                  {context.t(`customers.sendContractMessage`).replace("%s", sendSmsCustomerName).replace("%v", sendSmsAgreementName)}
                </AlertDialog>

                {/* **
               wp onayı için açılan dialog
               */}
                <AlertDialog
                  title="Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?"
                  // title="Kayıtlı sözleşmeyi Whatsapp ile iletmek ister misiniz?"
                  open={wpAprovalModal}
                  closeHandler={() => setWpAprovalModal(false)}
                  buttons={[
                    {
                      title: context.t(`component.sms_review.sendButtonTitle`),
                      icon: "send",
                      backgroundColor: "primary-opacity",
                      textColor: "primary",
                      onClick: () => {
                        sendWPAgreement(sendWpId);
                      },
                    },
                    {
                      title: context.t(`component.sms_review.discardButtonTitle`),
                      icon: "clear",
                      textColor: "grey",
                      onClick: () => {
                        setSendWpId(null);
                        setSendWpCustomerName(null);
                        setSendWpAgreementName(null);
                      },
                    },
                  ]}
                >
                  {context.t(`customers.sendContractWP`).replace("%s", sendWpCustomerName).replace("%v", sendWpAgreementName)}
                </AlertDialog>

                <AlertDialog
                  title={context.t(`customers.deleteContractTitle`)}
                  open={deleteAgreementDialog}
                  closeHandler={() => setDeleteAgreementDialog(false)}
                  buttons={[
                    {
                      title: context.t(`customers.deleteContractAccept`),
                      icon: "check",
                      backgroundColor: "primary-opacity",
                      textColor: "primary",
                      onClick: () => {
                        deleteAgreement(agreementDeleteId);
                      },
                    },
                    {
                      title: context.t(`["accounting/bounty"].check.discardButtonTitle`),
                      icon: "close",
                      textColor: "grey",
                    },
                  ]}
                >
                  <Alert severity="warning">
                    <b>{context.t(`customers.deleteContractWarn1`)}</b>
                    <br />
                    {context.t(`customers.deleteContractWarn2`)}
                  </Alert>
                </AlertDialog>
              </Grid>
            )}
          </Column>
        </Column>
      </Grid>
      <NewAppointmentDialog
        open={checkoutDataDialog}
        onNewAppointment={() => {
          setCheckoutDataDialog(false);
        }}
        onClose={() => setCheckoutDataDialog(false)}
      />
    </AuthContainer>
  );
};

export default SingleCustomer;

const LoadingContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PhotoContainer = styled.div`
  display: flex;
  padding: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  position: relative;
  height: 240px;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.04);
`;

const PhotoProcesses = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  background: rgba(255, 255, 255, 1);
  z-index: 200;

  span.MuiCheckbox-root {
    padding: 0 !important;
  }
`;

const GalleryPhoto = styled.img`
  width: 100%;
  height: 70%;
  margin-right: 8px;
  border: 1px solid #d3d3d3;
  object-fit: cover;
`;

const ImageUploadBox = styled.label`
  display: flex;
  width: -webkit-fill-available;
  padding: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fefefe;
  border: 2px dotted #eaeaea;
  border-radius: 5px;
  cursor: pointer;
  color: #808080;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  &:hover {
    border: 2px dotted #dadada;
    color: #000;
  }

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;

const ImageUploadBoxNext = styled.label`
  display: flex;
  width: 100%;
  padding: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #bbdefb;
  border-radius: 5px;
  cursor: pointer;
  color: #0d47a1;
  font-size: 15px;
  font-weight: 500;
  transition: all ease 0.35s;

  i.material-icons {
    font-size: 21px;
    margin-right: 8px;
  }
`;

const CostumSelect = styled.select`
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #b2b2b2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  height: 46px;
  margin-bottom: 0px;
  margin-top: -2px;
`;

import React, { useEffect, useState, useContext, useCallback, memo, useRef, useMemo } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import AuthContainer from "../../../components/AuthContainer";
import Axios from "axios";
import Select from "../../../theme/CustomMUI/Select";
import moment from "moment";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";
import Input from "../../../theme/CustomMUI/Input";
import CalculateMessageCount from "../../../functions/CalculateMessageCount";
import SMSReview from "../../../components/Dialogs/SMSReview";
import { toast } from "react-toastify";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { DateRange, Search } from "@material-ui/icons";
import InfoIcon from '@material-ui/icons/Info';
const MessageDialogContent = memo(({ initialSms, onSmsChange, templates, context, onPlatformChange }) => {
  const [localSms, setLocalSms] = useState(initialSms);
  const [wpBalance, setWpBalance] = useState(null);
  const [credit, setCredit] = useState(null);

  useEffect(() => {
    setWpBalance(context.state?.balance?.data?.wp_credit);
    setCredit(context.state?.balance?.data?.credit);
  }, [context.state?.balance?.data?.credit, context.state?.balance?.data?.wp_credit]);

  // Platform seçeneklerini wp bakiyesine göre oluştur
  const platformOptions = useMemo(() => {
    const options = [
      { id: "sms", name: "SMS" }
    ];

    if (wpBalance) {
      options.push(
        { id: "all", name: "WhatsApp & SMS" },
        { id: "wp", name: "WhatsApp" }
      );
    }

    return options;
  }, [wpBalance, credit, context.t]);

  const handleInputChange = useCallback((e) => {
    const newValue = e.target.value;
    setLocalSms(prev => ({
      ...prev,
      message: newValue
    }));
    onSmsChange({
      ...localSms,
      message: newValue
    });

  }, [localSms, onSmsChange]);

  return (
    <>
      <Select
        label={context.t(`sms.send.platformSelect`)}
        items={platformOptions}
        selected={localSms.selectedPlatform?.id}
        labelKey="name"
        valueKey="id"
        returnFullObject
        handler={(platform) => {
          const newSms = {
            ...localSms,
            selectedPlatform: platform
          };
          setLocalSms(newSms);
          onPlatformChange(platform.id);
        }}
        style={{ textAlign: 'left' }}
        labelStyle={{ textAlign: 'left' }}
      />
      <Select
        label={context.t(`sms.send.template`)}
        items={templates}
        selected={localSms.selectedTemplate?.name ?? ""}
        labelKey="name"
        valueKey="name"
        returnFullObject
        handler={(template) => {
          const newSms = {
            ...localSms,
            selectedTemplate: template,
            message: template.content,
          };
          setLocalSms(newSms);
          onSmsChange(newSms);
        }}
      />
      <Input
        label={context.t(`sms.send.messageInputLabel`)}
        multiline={true}
        rows={10}
        value={localSms.message}
        onChange={handleInputChange}
        helperText={CalculateMessageCharCount(localSms.message, "1")}
      />
    </>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.initialSms.message === nextProps.initialSms.message &&
    prevProps.initialSms.selectedTemplate?.name === nextProps.initialSms.selectedTemplate?.name &&
    prevProps.initialSms.selectedPlatform?.id === nextProps.initialSms.selectedPlatform?.id
  );
});

const MessageDialog = memo(({
  open,
  sms,
  onClose,
  onSmsChange,
  onSend,
  onDiscard,
  onPlatformChange,
  context,
  platform
}) => {
  return (
    <AlertDialog
      open={open}
      title={context.t(`['customers/risky'].sendMessageButtonTitle`)}
      maxWidth="md"
      fullWidth={true}
      buttons={[
        {
          disabled: sms.message.length === 0 ||
            !CalculateMessageCount(sms.message) ||
            !platform ||
            platform === "null",
          icon: "send",
          title: context.t(`sms.send.messageButtonTitle`),
          backgroundColor: "primary",
          textColor: "white",
          fullWidth: true,
          onClick: onSend,
        },
        {
          icon: "close",
          title: context.t(`sms.send.discardButtonTitle`),
          backgroundColor: "red",
          textColor: "white",
          fullWidth: true,
          onClick: onDiscard,
        },
      ]}
      closeHandler={onClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <MessageDialogContent
        initialSms={sms}
        templates={sms.templates}
        context={context}
        onSmsChange={onSmsChange}
        onPlatformChange={onPlatformChange}
      />
    </AlertDialog>
  );
});

const CustomersRisky = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);

  const [offset, setOffset] = useState(30);

  const urlPage = parseInt(
    new URLSearchParams(window.location.search).get("page")
  );

  const [pagination, setPagination] = useState({
    total_page: 1,
    page: urlPage ? urlPage : 1,
    onChange: (page) => setPagination((prev) => ({ ...prev, page: page })),
  });

  const [data, setData] = useState([]);

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [selectedFilterPicker, setSelectedFilterPicker] = useState({
    id: 1,
    label: "Bugün",
    offset: 1,
  });

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedAllCustomer, setSelectedAllCustomer] = useState(false);
  const [exceptedCustomers, setExceptedCustomers] = useState([]);

  const [sms, setSms] = useState({
    message: "",
    templates: [],
    selectedTemplate: null,
  });
  const [platform, setPlatform] = useState("null");

  const [openSmsDialog, setOpenSmsDialog] = useState(false);

  const [openSmsReviewDialog, setOpenSmsReviewDialog] = useState(false);
  const [totalNumber, setTotalNumber] = useState(null);
  const [totalSmsCredit, setTotalSmsCredit] = useState(null);

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [readyToUseFilter, setReadyToUseFilter] = useState(null);
  const [reportsDate, setReportsDate] = useState({
    between: {
      start: moment().local().format("DD/MM/YYYY"),
      end: moment().local().format("DD/MM/YYYY"),
    },
  });
  const [initialLoad, setInitialLoad] = useState(true);

  // Son 6 ayın başlangıç tarihini hesapla
  const sixMonthsAgo = moment().subtract(6, 'months').toDate();

  const getRiskyCustomers = () => {
    setLoaded(true);
    Axios.get(
      `${context.api_endpoint}/company/customers/risky`,
      selectedFilterPicker === null
        ? {
          params: {
            page: pagination.page ? pagination.page : 1,
            start: moment(start).format("YYYY-MM-DD"),
            end: moment(end).format("YYYY-MM-DD"),
            isbetween: true,
          },
        }
        : {
          params: {
            page: pagination.page ? pagination.page : 1,
            offset: selectedFilterPicker.offset,
            isbetween: false,
          },
        }
    )
      .then(({ data }) => {
        if (data?.data?.records) {
          setData([
            ...data.data.records.map((item) => {
              return {
                ...item,
                phone:
                  context.state.user.permission === 2 &&
                    context.state.limited_permission.customer_info === 0
                    ? phoneNumberDisplay(item.phone)
                    : `+90 ${item.phone.substring(0, 3)} ${item.phone.substring(
                      3,
                      6
                    )} ${item.phone.substring(6, 10)}`,
                extra_detail: {
                  ...item.extra_detail,
                  last_transaction_date: item.extra_detail.last_transaction_date
                    ? moment(item.extra_detail.last_transaction_date)
                      .startOf()
                      .fromNow()
                    : null,
                  sum_income: item.extra_detail.sum_income
                    ? item.extra_detail.sum_income.toFixed(2)
                    : "0.00",
                  total_debt: item.extra_detail.total_debt
                    ? item.extra_detail.total_debt.toFixed(2)
                    : "0.00",
                },
              };
            }),
          ]);
          setPagination(prev => ({
            ...prev,
            total_page: data.data.total_record,
            //page: data.data.page
          }));
        } else {
          setData([]);
        }
      })
      .catch((e) => {
        setData([]);
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .finally(() => {
        setLoaded(false);
      });
  };

  useEffect(() => {
    if (!initialLoad) {
      handleList()
    }
  }, [pagination.page])

  const handleList = () => {
    setInitialLoad(false);
    getRiskyCustomers();
  };
  const handleFilterClick = (filter) => {
    setSelectedFilterPicker(filter);

    // Tarihleri filtreye göre güncelle
    let startDate, endDate;

    switch (filter.id) {
      case 1: // Bugün
        startDate = moment().startOf('day');
        endDate = moment().endOf('day');
        break;
      case 2: // Bu Hafta
        startDate = moment().startOf('week');
        endDate = moment().endOf('week');
        break;
      case 3: // Bu Ay
        startDate = moment().startOf('month');
        endDate = moment().endOf('month');
        break;
      default:
        return;
    }

    setStart(startDate.toDate());
    setEnd(endDate.toDate());
    setReportsDate({
      between: {
        start: startDate.format("DD/MM/YYYY"),
        end: endDate.format("DD/MM/YYYY")
      }
    });
  };
  const getSMSReview = async () => {
    if (!platform || platform === "null") {
      toast.warning(context.t(`sms.send.platformRequired`));
      return setOpenSmsDialog(true);
    }

    if (!sms.message || sms.message === "") {
      toast.warning(context.t(`sms.send.messageRequired`));
      return setOpenSmsDialog(true);
    }


    await Axios.post(
      `${context.api_endpoint}/company/customers/risky/message/preview`,
      {
        all: selectedAllCustomer,
        customer_id: [...selectedCustomers],
        except: [...exceptedCustomers],
        message: sms.message,
        platform: platform === null ? null : platform,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setTotalNumber(response.data.data.total_numbers);
          setTotalSmsCredit(response.data.data.total_sms_credit);
          setOpenSmsDialog(true);
          setOpenSmsReviewDialog(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const sendSMS = () => {
    Axios.post(
      `${context.api_endpoint}/company/customers/risky/message/send`,
      {
        all: selectedAllCustomer,
        customer_id: [...selectedCustomers],
        except: [...exceptedCustomers],
        message: sms.message,
        offset: parseInt(offset),
        platform: platform === null ? null : platform,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setSelectedCustomers([]);
          setSelectedAllCustomer(false);
          setSms({
            ...sms,
            message: "",
            selectedTemplate: null,
          })
          toast.success(context.t(`["customers/risky"].sendMessageSuccessToast`));
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const handleSmsChange = useCallback((newSms) => {
    setSms(prev => ({
      ...prev,
      message: newSms.message,
      selectedTemplate: newSms.selectedTemplate
    }));
  }, []);

  const handleDiscard = useCallback(() => {
    setSelectedCustomers([]);
    setExceptedCustomers([]);
    setSelectedAllCustomer(false);
    setSms({
      ...sms,
      message: "",
      selectedTemplate: null,
    });
    setOpenSmsDialog(false);
  }, []);

  const handlePlatformChange = useCallback((platformId) => {
    setPlatform(platformId);
  }, []);

  return (
    <AuthContainer authorities={[1]} limited_permission="customer">
      <Grid>
        <Column
          className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
          style={{ borderBottom: "1px solid #b2b2b2" }}
        >
          <Column
            className="xs-12 sm-12 md-12 lg-6"
            style={{ height: "63px", display: "flex", alignItems: "center" }}
          >
            <ShortButtonContainer>
              <ShortButton
                onClick={() => handleFilterClick({
                  id: 1,
                  label: "Bugün",
                  offset: 1,
                })}
                style={{
                  backgroundColor: `${selectedFilterPicker?.id === 1 ? "rgb(49, 169, 243, 0.14)" : ""}`,
                  border: "1px solid rgb(91, 187, 245, 0.41)",
                  color: "rgb(49, 169, 243)",
                }}
              >
                {context.t(`accounting.today`)}
              </ShortButton>
              <ShortButton
                onClick={() => handleFilterClick({
                  id: 2,
                  label: "Bu Hafta",
                  offset: 3,
                })}
                style={{
                  backgroundColor: `${selectedFilterPicker?.id === 2 ? "rgb(29, 139, 62, 0.21)" : ""}`,
                  border: "1px solid rgb(58, 154, 87, 0.35)",
                  color: "rgb(29, 139, 62)",
                }}
              >
                {context.t(`accounting.thisWeek`)}
              </ShortButton>
              <ShortButton
                onClick={() => handleFilterClick({
                  id: 3,
                  label: "Bu Ay",
                  offset: 4,
                })}
                style={{
                  backgroundColor: `${selectedFilterPicker?.id === 3 ? "rgb(254, 151, 151, 0.19)" : ""}`,
                  border: "1px solid rgb(185, 178, 178, 0.65)",
                  color: "rgb(254, 111, 111)",
                }}
              >
                {context.t(`accounting.thisMonth`)}
              </ShortButton>
            </ShortButtonContainer>
          </Column>
          <Column
            className="xs-12 sm-12 md-12 lg-6"
            style={{ borderLeft: "1px solid #b2b2b2", height: "63px", display: "flex", alignItems: "center" }}
          >
            <FilterContainer>
              <FilterButton
                onClick={() => {
                  setFilterModalOpen(true);
                  setSelectedFilterPicker(null);
                  setReadyToUseFilter(1);
                }}
              >
                <DateRange />
                <span style={{ fontWeight: "500", fontSize: "1.2em", paddingLeft: "1vw" }}>
                  {reportsDate.between.start} - {reportsDate.between.end}
                </span>
              </FilterButton>
              <FilterButton
                onClick={handleList}
                style={{
                  backgroundColor: "rgb(33, 150, 243, 0.14)",
                  border: "1px solid rgb(33, 150, 243, 0.41)",
                  color: "rgb(33, 150, 243)",


                }}
              >
                <Search />
                <span style={{
                  fontWeight: "500",
                  fontSize: "1.2em",
                  paddingLeft: "1vw",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",

                }}>

                  {context.t(`['customers/risky'].listButtonTitle`)}
                </span>
              </FilterButton>

            </FilterContainer>
          </Column>
        </Column>

        {data.length > 0 && (
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-3 mb-1">
              <Button
                title={selectedAllCustomer ? context.t(`['customers/risky'].cancelAllButtonTitle`) : context.t(`['customers/risky'].selectedAllButtonTitle`)}
                icon="check"
                backgroundColor={selectedAllCustomer ? "red" : "primary"}
                textColor="white"
                fullWidth
                onClick={() => {
                  setSelectedCustomers([]);
                  setSelectedAllCustomer(!selectedAllCustomer);
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3 mb-1">
              <Button
                title={context.t(`['customers/risky'].sendMessageButtonTitle`)}
                icon="message"
                backgroundColor="blue-opacity"
                textColor="blue"
                fullWidth
                disabled={selectedAllCustomer ? false : selectedCustomers.length === 0}
                onClick={() => setOpenSmsDialog(true)}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-3">
              <ExcelButton
                route="customers/risky"
                requestData={{ is_excel: true }}
                size="sm"
              />
            </Column>
          </Grid>
        )}

        <Grid>
          <Column className="xs-12 sm-12">
            {initialLoad ? (
              <InfoContainer>
                <IconCircle>
                  <InfoIcon style={{ fontSize: 40, color: '#ff9016' }} />
                </IconCircle>
                <InfoText>{context.t(`['customers/risky'].noDataTextInitial`).split('"').map((text, index) =>
                  index % 2 === 1 ? (
                    <BoldBlueText key={index}>"{text}"</BoldBlueText>
                  ) : (
                    <span key={index}>{text}</span>
                  )
                )}</InfoText>
                <div>

                </div>
              </InfoContainer>
            ) : (
              <Table
                refreshAction={() => getRiskyCustomers()}
                loaded={!loaded}
                headings={{
                  full_name: {
                    label: context.t(`['customers/risky'].headings.fullName`),
                    sortable: {
                      0: [
                        { field: "`customers`.`name`", order: "ASC" },
                        { field: "`customers`.`surname`", order: "ASC" },
                      ],
                      1: [
                        { field: "`customers`.`name`", order: "DESC" },
                        { field: "`customers`.`surname`", order: "DESC" },
                      ],
                    },
                  },
                  phone: {
                    label: context.t(`['customers/risky'].headings.phone`),
                    style: { width: 150 },
                  },
                  sex: {
                    label: context.t(`['customers/risky'].headings.sex`),
                  },
                  "extra_detail.sum_income": {
                    label: context.t(`['customers/risky'].headings.incomeAll`),
                    suffix: context.state.currency ? context.state.currency : "₺",
                    sortable: {
                      0: [{ field: "`rp`.`amount`", order: "ASC" }],
                      1: [{ field: "`rp`.`amount`", order: "DESC" }],
                    },
                  },
                  "extra_detail.total_debt": {
                    label: context.t(`['customers/risky'].headings.debtAll`),
                    suffix: context.state.currency ? context.state.currency : "₺",
                  },
                  "extra_detail.last_transaction_date": {
                    label: context.t(`['customers/risky'].headings.lastTransaction`),
                  },
                  _: { label: context.t(`component.actionHeadingText`) },
                }}
                rows={data}
                pagination={pagination}
                noDataText={context.t(`['customers/risky'].noDataText`)}
                replacements={{
                  sex: {
                    null: context.t(`['customers/risky'].replacements.sex.undef`),
                    0: context.t(`['customers/risky'].replacements.sex.female`),
                    1: context.t(`['customers/risky'].replacements.sex.male`),
                    2: context.t(`['customers/risky'].replacements.sex.undef`),
                  },
                }}
                selectedAll={selectedAllCustomer}
                excepteds={exceptedCustomers}
                checkedCheckboxes={selectedCustomers}
                checkboxOnChange={(row, state) => {
                  if (state) {
                    setSelectedCustomers([...selectedCustomers, row.id]);
                    setExceptedCustomers([...exceptedCustomers.filter((id) => id !== row.id)]);
                  } else {
                    setSelectedCustomers([...selectedCustomers.filter((id) => id !== row.id)]);
                    setExceptedCustomers([...exceptedCustomers, row.id]);
                  }
                }}
                buttons={[
                  {
                    title: context.t(`component.detailEditButtonTitle`),
                    icon: "launch",
                    textColor: "primary",
                    transitionEffect: true,
                    pushEffect: true,
                    onClick: (row) => history.push(`/customers/detail/${row.id}`),
                  },
                ]}
              />)}
          </Column>
        </Grid>
      </Grid>

      <AlertDialog
        title={<h3 style={{ textAlign: "center" }}>{context.t(`accounting.filterArea.header`)}</h3>}
        open={filterModalOpen}
        maxWidth="sm"
        closeHandler={() => setFilterModalOpen(false)}
        buttons={[
          {
            title: context.t(`accounting.filterArea.approve`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              setFilterModalOpen(false);
              // Modal kapandıktan sonra otomatik istek atmıyoruz
            },
          },
          {
            title: context.t(`accounting.filterArea.cancel`),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setSelectedFilterPicker({
                id: 1,
                label: "Bugün",
                offset: 1,
              });
              setFilterModalOpen(false);
            },
          },
        ]}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "100%"
              }}
            >
              <DatePickerInput
                label={context.t(`reports.startInputLabel`)}
                value={start}
                maxDate={end}
                minDate={sixMonthsAgo}
                onChange={(date) => {
                  setStart(date);
                  setSelectedFilterPicker(null);
                  setReportsDate({
                    ...reportsDate,
                    between: {
                      ...reportsDate.between,
                      start: moment(date).format("DD/MM/YYYY"),
                    },
                  });
                }}
                fullWidth
              />
              <DatePickerInput
                label={context.t(`reports.endInputLabel`)}
                value={end}
                minDate={start}
                onChange={(date) => {
                  setEnd(date);
                  setSelectedFilterPicker(null);
                  setReportsDate({
                    ...reportsDate,
                    between: {
                      ...reportsDate.between,
                      end: moment(date).format("DD/MM/YYYY"),
                    },
                  });
                }}
                fullWidth
              />
            </div>
          </Column>
        </Grid>
      </AlertDialog>

      <MessageDialog
        open={openSmsDialog}
        sms={{
          ...sms,
          selectedPlatform: { id: platform }
        }}
        platform={platform}
        context={context}
        onClose={() => setOpenSmsDialog(false)}
        onSmsChange={handleSmsChange}
        onPlatformChange={handlePlatformChange}
        onSend={getSMSReview}
        onDiscard={handleDiscard}
      />
      <SMSReview
        open={openSmsReviewDialog}
        closeHandler={() => {
          setOpenSmsReviewDialog(false);
        }}
        sendSMS={sendSMS}
        sms_content={sms.message}
        total_number={totalNumber}
        platform={platform}
        total_sms_credit={totalSmsCredit}
      />
    </AuthContainer>
  );
}

// Styled components
const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;
const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const FilterButton = styled.div`
  border-radius: 8px;
  padding: 1vh 2.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;
const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
`;

const IconCircle = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(255, 183, 143, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  border: 2px solid #ff9016;
`;

const InfoText = styled.h3`
  color: #333;
  margin: 1rem 0;
  font-weight: bold;
`;

const BoldBlueText = styled.span`
  font-weight: bold;
  color: #2196f3;
`;


export default CustomersRisky;

import React, { useContext, useEffect, useState } from "react";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import { People, Sms, CreditCard, WhatsApp } from "@material-ui/icons";
import styled from "styled-components";
import AppContext from "../../context/store";

const SMSReview = (props) => {
	const context = useContext(AppContext);
	const [isSend, setIsSend] = useState(false);

	useEffect(() => {
		setIsSend(false);
	}, [props.open]);

	// Platform ikonunu belirle
	const getPlatformIcon = () => {
		if (!props.platform || props.platform === "all") {
			return (
				<>
					<div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
						<span style={{ display: "flex", alignItems: "center", gap: "2px" }}><WhatsApp fontSize="small" style={{ color: "green" }} />WhatsApp</span>
						{'  &  '}
						<span style={{ display: "flex", alignItems: "center", gap: "2px" }}>SMS<Sms fontSize="small" style={{ color: "orange" }} /></span>
					</div>
				</>
			);
		} else if (props.platform === "whatsapp") {
			return <div style={{ display: "flex", alignItems: "center", gap: "2px" }}><WhatsApp fontSize="small" style={{ color: "green" }} /> WhatsApp</div>;
		} else {
			return <div style={{ display: "flex", alignItems: "center", gap: "2px" }}><Sms fontSize="small" style={{ color: "orange" }} /> SMS</div>;
		}
	};



	return (
		<AlertDialog
			open={props.open}
			title={context.t(`component.sms_review.smsReviewTitle`)}
			maxWidth="sm"
			fullWidth={true}
			buttons={[
				{
					icon: "send",
					title: context.t(`component.sms_review.sendButtonTitle`),
					textColor: "blue",
					backgroundColor: "primary-opacity",
					fullWidth: true,
					disabled: isSend,
					onClick: () => {
						props.sendSMS();
						setIsSend(true);
					},
				},
				{
					icon: "close",
					title: context.t(`component.sms_review.discardButtonTitle`),
					textColor: "black",
					outlined: true,
					fullWidth: true,
					onClick: () => {
						props.closeHandler();
					},
				},
			]}
			closeHandler={() => {
				props.closeHandler();
			}}
		>
			<div
				style={{
					display: "flex",
					gap: "10px",
					alignItems: " center",
					flexDirection: "column",
					margin: " 1em 0px 3em 0px",
				}}
			>
				{/* Platform bilgisi */}
				<Title>
					{context.t(`component.sms_review.platformTitle`)}: {" "}
					<span>
						{getPlatformIcon()}
					</span>
				</Title>
				<Border />

				<Title>
					<Sms fontSize="small" />
					{context.t(`component.sms_review.smsContentTitle`)}
				</Title>
				<div style={{ maxWidth: "500px" }}>
					<p style={{ overflowWrap: "break-word" }}>{props.sms_content}</p>
				</div>
				<Title>
					<People fontSize="small" />
					{context.t(`component.sms_review.totalNumberTitle`)}{" "}
					<span>{props.total_number}</span>
				</Title>
				<Border />

				{props.check_sms ? (
					<>
						<Title>
							<CreditCard fontSize="small" style={{ color: "orange" }} />
							{context.t(`component.sms_review.totalSMSCreditTitle`)}{" "}
							<span>{props.total_sms_credit}</span>
						</Title>
						<Title>
							<CreditCard fontSize="small" style={{ color: "orange" }} />
							{context.t(`component.sms_review.inUseSMSCredit`)}{" "}
							<span>{props.in_use_sms_credit}</span>
						</Title>
					</>
				) : (
					""
				)}
				{props.check_sms && props.check_wp ? <Border /> : ""}
				{props.check_wp ? (
					<>
						<Title>
							<WhatsApp fontSize="small" style={{ color: "green" }} />
							{context.t(`component.sms_review.totalWPCreditTitle`)}{" "}
							<span>{props.total_wp_credit}</span>
						</Title>
						<Title>
							<WhatsApp fontSize="small" style={{ color: "green" }} />
							{context.t(`component.sms_review.inUseWPCredit`)}{" "}
							<span>{props.total_number}</span>
						</Title>
					</>
				) : (
					""
				)}
			</div>
		</AlertDialog>
	);
};

export default SMSReview;

const Title = styled.h3`
	font-weight: 500;
	display: flex;
	align-items: center;
	gap: 10px;
`;

const Border = styled.div`
	width: 100%;
	border-bottom: 1px solid #bbb;
`;

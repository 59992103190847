import React, { useEffect, useRef } from "react";
import AuthContainer from "../../../components/AuthContainer";
import { Column, Grid } from "../../../theme/Grid";
import styled from "styled-components";
import SemiCirclePie from "../../../components/Charts/SemiCirclePie";
import CustomMUIDatePicker from "../../../theme/CustomMUI/DatePicker";
import HorizontalBar from "../../../components/Charts/HorizontalBar";
import AppContext from "../../../context/store";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Axios from "axios";
import { favoritePage } from "../../../functions/FavoritePages";
import moment from "moment";
import Table from "../../../theme/Table";
import { toast } from "react-toastify";
import RoundedRow from "../../../components/Table/RoundedRow";
import { EventNote } from "@material-ui/icons";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Alert from "@material-ui/lab/Alert";
import StatsCard from "../../../components/Cards/StatsCard";
import CustomDateRangeColumn from "../../../components/Cards/CustomDateRangeColumn";
import FieldCard from "../../../components/Cards/FieldCard";
import FieldCardTwoTitle from "../../../components/Cards/FieldCardTwoTitle";
import Button from "../../../theme/Button";
import CDoughnut from "../../../components/Charts/CDoughnut";
import packageJson from "../../../../package.json";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoing";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import { withRouter } from 'react-router-dom';
import { BsInfoSquareFill } from "react-icons/bs";
import { SlBasket } from "react-icons/sl";

// Memo edilmiş kasa istatistikleri bileşeni
const ReceiptIncomeStats = React.memo(({
  context,
  stats,
  offsets,
  onOffsetChange,
  currency
}) => {

  // Performans testi için önceki props'ları saklayalım
  const prevProps = useRef({ stats, offsets });

  // useEffect(() => {
  //   // Props değişikliklerini kontrol edelim
  //   if (prevProps.current.stats !== stats) {
  //     console.log("Stats changed:", {
  //       previous: prevProps.current.stats,
  //       current: stats
  //     });
  //   }
  //   if (prevProps.current.offsets !== offsets) {
  //     console.log("Offsets changed:", {
  //       previous: prevProps.current.offsets,
  //       current: offsets
  //     });
  //   }
  //   prevProps.current = { stats, offsets };
  // }, [stats, offsets]);

  return (
    <Column className="xs-12 sm-12 md-12 lg-4">
      <FieldCard
        id="card-receipt_income"
        cardTitle={context.t(
          `dashboard.cardTitle.receipt_income_card_title`
        )}
        helperText={context.t(
          `dashboard.cardDescription.receipt_income_card_description`
        )}
        style={{
          height: "330px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evently",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <CustomDateRangeColumn
          style={{ width: "100%" }}
          onClickHandler={async (receiptincomeoffset) => {
            // Eğer offset değişmediyse işlem yapma
            if (offsets.receiptincomeoffset === receiptincomeoffset) {
              console.log("Same offset, skipping update"); // Aynı offset logu
              return;
            }
            await onOffsetChange(receiptincomeoffset);
          }}
        >
          <Grid style={{ width: "100%", height: "100%" }}>
            {stats.loaded &&
              Object.keys(stats.receipt_income).filter(
                (key) => stats.receipt_income[key] > 0
              ).length > 0 ? (
              <>
                <SemiCirclePie
                  data={[
                    {
                      label: context.t(
                        `dashboard.report.company.typeCASH`
                      ),
                      count: stats.receipt_income["0"],
                    },
                    {
                      label: context.t(
                        `dashboard.report.company.typeCARD`
                      ),
                      count: stats.receipt_income["1"],
                    },
                    {
                      label: context.t(
                        `dashboard.report.company.typeEFT`
                      ),
                      count: stats.receipt_income["2"],
                    },
                  ]}
                  colors={["#C6D3C2", "#A4B99A", "#6D8563"]}
                  labelKey="label"
                  valueKey="count"
                  labelText={`{category}\n{value.percent.formatNumber('#.0')}% ({value}${currency ? currency : " ₺"})`}
                  containerStyle={{ width: "100%", height: "55%" }}
                  innerRadius={45}
                  showOnlyValue={true}
                />
              </>
            ) : (
              <p style={{ textAlign: "center", marginTop: "50px" }}>
                {context.t(`dashboard.report.company.noDataText`)}
              </p>
            )}
            <Report>
              <div>
                <h4 style={{ color: "#a0a0a0" }}>
                  {context.t(
                    `dashboard.report.company.totalIncome`
                  )}
                </h4>
                <h2>
                  {stats.receipt_income["0"] +
                    stats.receipt_income["1"] +
                    stats.receipt_income["2"]}
                  {currency ? currency : " ₺"}
                </h2>
              </div>
            </Report>
          </Grid>
        </CustomDateRangeColumn>
      </FieldCard>
    </Column>
  );
}, (prevProps, nextProps) => {
  // Özel karşılaştırma fonksiyonu
  const isEqual = (
    JSON.stringify(prevProps.stats) === JSON.stringify(nextProps.stats) &&
    prevProps.offsets.receiptincomeoffset === nextProps.offsets.receiptincomeoffset
  );

  // console.log("ReceiptIncomeStats memo comparison:", {
  //   isEqual,
  //   prevProps,
  //   nextProps
  // });

  return isEqual;
});

class DashboardIndex extends React.Component {
  static contextType = AppContext;

  state = {
    favoritePages: [],

    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => this.getAppointmentRequests(page),
    },
    paginationBirthDay: {
      page: 1,
      total_page: 1,
      onChange: (page) => this.getBirthDayRequests(page),
    },
    paginationOpenAppointments: {
      page: 1,
      total_page: 1,
      onChange: (page) => this.getOpenApoointmentRequests(page),
    },
    paginationCredits: {
      page: 1,
      total_page: 1,
      onChange: (page) => this.getCreditsRequests(page),
    },
    offsets: {
      appointmentoffset: 1,
      receiptincomeoffset: 1,
    },

    onlineAppointments: {
      loaded: false,
      data: [],
    },
    openAppointments: {
      loaded: false,
      data: [],
    },
    creditors: {
      loaded: false,
      data: [],
    },
    birthDay: {
      loaded: false,
      data: [],
    },
    stats: {
      loaded: false,
      appointments: {
        succeed_appointments: null,
        closed_appointments: null,
        created_appointments: null,
      },
      receipt_income: {
        0: 0,
        1: 0,
        2: 0,
      },
    },

    appointmentDatasets: {
      datasets: [
        {
          label: "# of Votes",
          data: [],
          backgroundColor: [
            "rgba(29, 139, 62, 0.4)",
            "rgba(49, 137, 236, 0.6)",
            "rgba(140, 15, 180, 0.4)",
          ],
          borderWidth: 1,
        },
      ],
    },

    appointment_zone: {
      from_company: 0,
      from_platform: 0,
      from_staff: 0,
      totalPercent: 0,
    },

    app_statistics: {
      from_company: 0,
      from_platform: 0,
      from_staff: 0,
    },

    // Open hidden field of FieldCards
    appointment_type_open_field: false,
    receipt_income_open_field: false,

    pageScrolled: false,

    openTutorial: false,

    packageNo: 0,

    qr_code_dialog: false,
    isConfirmedConflict: false,
    confirmConflictId: "",
    confirmConflictPopUp: false,

    todayAppointments: {
      loaded: false,
      data: [],
    },
    paginationTodayAppointments: {
      page: 1,
      total_page: 0,
      onChange: (page) => {
        this.getTodayAppointments(page);
      },
    },
    activeTab: 0,

    campaignTimers: {
      first: this.context?.state?.campaigns?.first?.timeLeft || 0,
      renewal: this.context?.state?.campaigns?.renewal?.timeLeft || 0
    },

    campaigns: {
      first: {
        timeLeft: this.context.state.campaigns.first.timeLeft,
        url: this.context.state.campaigns.first.url,
      },
      renewal: {
        timeLeft: this.context.state.campaigns.renewal.timeLeft,
        url: this.context.state.campaigns.renewal.url,
      },
    },

    isLoadingAnalysis: false,
    isTimerLoading: false
  };

  QRCodeDialog = () => {
    return (
      <AlertDialog
        open={this.state.qr_code_dialog}
        disableBackdropClick={false}
        closeHandler={() => this.setState({ qr_code_dialog: false })}
        buttons={[
          {
            icon: "close",
            title: "Kapat",
            textColor: "black",
            outlined: true,
          },
        ]}
      >
        <div
          style={{
            height: "auto",
            margin: "50px 40px",
            maxWidth: 256,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`${this.context.app.WEB}/isletme/${this.context.state?.company_id}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </AlertDialog>
    );
  };
  getFavoritePages = () => {
    Axios.get(`${this.context.api_endpoint}/staff/favorites`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            favoritePages: [
              ...response.data.data.data.split(
                ",",
                response.data.data.data.split(",").length - 1
              ),
            ],
          });
        } else {
          this.setState({ favoritePages: [] });
        }
      })
      .catch((e) => {
        this.setState({ favoritePages: [] });
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  //get opened appointments
  getOpenApoointmentRequests = (page) => {
    Axios.get(`${this.context.api_endpoint}/company/receipts/opened`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        if (data.data.receipts.records) {
          this.setState({
            openAppointments: {
              ...this.state.openAppointments,
              loaded: true,
              data: [
                ...data.data.receipts.records.map((item) => ({
                  id: item.id,
                  date: moment(item.created_at).format("DD.MM.YYYY"),
                  customerFullName: item.customer?.full_name,
                  totalPayment:
                    item.all_amount +
                    (this.context.state.currency
                      ? this.context.state.currency
                      : " ₺"),
                  serviceName: item.service_count,
                  products: item.stock_item_count,
                  type: item.is_paid === false ? "Ödenmedi" : "Ödendi",
                })),
              ].filter((item) => item !== undefined),
            },
            paginationOpenAppointments: {
              ...this.state.paginationOpenAppointments,
              page: data.data.receipts.page,
              total_page: data.data.receipts.records.length,
            },
          });
        } else {
          this.setState({
            openAppointments: {
              ...this.state.openAppointments,
              loaded: true,
              data: [],
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          //toast.error(e.response.data.message);
        } else {
          //toast.warning(e.response.data.message);
        }
      });
  };
  //get credits
  getCreditsRequests = (page) => {
    Axios.get(`${this.context.api_endpoint}/company/current/incomes`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        if (data.data.records) {
          this.setState({
            creditors: {
              ...this.state.creditors,
              loaded: true,
              data: [
                ...data.data.records.map((item) => ({
                  customerFullName:
                    item.customer.name + " " + item.customer.surname,
                  totalPayment:
                    item.all_amount +
                    (this.context.state.currency
                      ? this.context.state.currency
                      : " ₺"),
                  type: item.is_paid === false ? "Ödenmedi" : "Ödendi",
                  paymentDate: moment(item.payment_date).format("DD.MM.YYYY"),
                })),
              ].filter((item) => item !== undefined),
            },
            paginationCredits: {
              ...this.state.paginationCredits,
              page: data.data.page,
              total_page: data.data.records.length,
            },
          });
        } else {
          this.setState({
            creditors: {
              ...this.state.creditors,
              loaded: true,
              data: [],
            },
          });
        }
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          toast.error(e.response?.data?.message);
        } else {
          toast.warning(e.response?.data?.message);
        }
      });
  };

  //get birthday
  getBirthDayRequests = (page) => {
    Axios.get(`${this.context.api_endpoint}/company/customer/birthdays`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then((data) => {
        this.setState({
          birthDay: {
            ...this.state.birthDay,
            loaded: true,
            data: [
              ...data.data.data.records.map((item) => ({
                customerFullName: item.full_name,
                birthDate: new Date(item.birthday).toLocaleString("tr-TR", {
                  day: "numeric",
                  month: "long",
                }),
                customer_phone: item.phone,
              })),
            ].filter((item) => item !== undefined),
          },
          paginationBirthDay: {
            ...this.state.paginationBirthDay,
            page: data.data.data.page,
            total_page: data.data.data.records.length,
          },
        });
      })
      .catch(() => {
        //toast.error(e.response.data.message);
      });
  };

  handleDateSelect = (selected_date) => {
    const formattedDate = moment(selected_date).toDate();

    this.props.history.push({
      pathname: '/appointments',
      state: { selectedDate: formattedDate }
    });
  };

  getTodayAppointments = (page = 1) => {
    this.setState({
      todayAppointments: {
        ...this.state.todayAppointments,
        loaded: false,
        data: []
      }
    });

    this.setState({ todayAppointments: { ...this.state.todayAppointments, loaded: false } });

    Axios.get(`${this.context.api_endpoint}/company/appointment/list`, {
      params: {
        page: page,
        offset: 1,
      },
    })
      .then(({ data }) => {
        if (data) {
          const formattedData = data.data.records.map(record => ({
            ...record,
            appointment_start_date: moment(record.appointment_start_date)
              .format('DD MMMM YYYY HH:mm'),
            created_at: moment(record.created_at)
              .format('DD MMMM YYYY HH:mm'),
            service_or_packet: record.service?.name || record.packet?.name || "-",
            raw_appointment_date: record.appointment_start_date
          }));

          this.setState({
            todayAppointments: {
              loaded: true,
              data: formattedData,
            },
            paginationTodayAppointments: {
              ...this.state.paginationTodayAppointments,
              page: data.data.page,
              total_page: data.data.total_page,
            },
          });
        } else {
          this.setState({
            todayAppointments: {
              loaded: true,
              data: [],
            },
          });
        }
      })
      .catch((error) => {
        console.error('Randevu yükleme hatası:', error);
        this.setState({
          todayAppointments: {
            loaded: true,
            data: [],
          },
        });
      });
  };

  getAppointmentRequests = (page) => {
    Axios.get(`${this.context.api_endpoint}/company/appointments/requests`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        this.setState({
          onlineAppointments: {
            ...this.state.onlineAppointments,
            loaded: true,
            data: [
              ...data.data.records.map((item) => ({
                ...item,
                customer_phone:
                  this.context.state.user.permission === 2 &&
                    this.context.state.limited_permission.customer_info === 0
                    ? "----------"
                    : item.customer_phone,
                customer_statics: {
                  ...item.customer_statics,
                  sumCol: (
                    <>
                      <RoundedRow color="blue">
                        <EventNote /> {item.customer_statics.success}/
                        {item.customer_statics.success +
                          item.customer_statics.wait +
                          item.customer_statics.fail}
                      </RoundedRow>
                    </>
                  ),
                },
                appointment_start_date: moment(item.appointment_start_date)
                  .tz("Europe/Istanbul")
                  .format("DD/MM/YYYY HH:mm"),
              })),
            ].filter((item) => item !== undefined),
          },
          pagination: {
            ...this.state.pagination,
            page: data.data.page,
            total_page: data.data.records.length,
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          this.props.history.push("/wallet");
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  approveSingleAppointmentRequest = (id, isApprove) => {
    Axios.post(
      `${this.context.api_endpoint}/company/appointment/request/approve`,
      !isApprove ? {
        request_id: id,
      } : {
        request_id: id,
        force: true
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            this.context.t(`dashboard.onlineAppointments.approveToast`)
          );
          this.getAppointmentRequests();
          this.setState({
            isConfirmedConflict: false,
          })
        }
      })
      .catch((e) => {
        if (e.response.status !== 409) {
          toast.error(e.response.data.message);
        } else if (e.response.status === 409) {
          this.setState({
            confirmConflictId: id,
            confirmConflictPopUp: true,
            isConfirmedConflict: false,
          });
        }
      });
  };
  rejectSingleAppointmentRequest = (id) => {
    Axios.post(
      `${this.context.api_endpoint}/company/appointment/request/${id}/cancel`
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            this.context.t(`dashboard.onlineAppointments.rejectToast`)
          );
          this.getAppointmentRequests();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getAnalysis = () => {
    Axios.get(`${this.context.api_endpoint}/company/analytics`, {
      params: {
        ...this.state.offsets,
      },
    })
      .then(({ data }) => {
        const totalAppointmentZone = Object.keys(data.data.appointments)
          .filter((item) => item.split("_")[0] === "from")
          .reduce((value, key) => (value += data.data.appointments[key]), 0);
        const appointmentZones = {};
        for (let key in data.data.appointments) {
          if (key.split("_")[0] === "from") {
            appointmentZones[key] =
              data.data.appointments[key] !== 0
                ? (
                  (data.data.appointments[key] / totalAppointmentZone) *
                  100
                ).toFixed(0)
                : 0;
          }
        }
        // Same with this.state.receipt_income
        let receipt_income = { 0: 0, 1: 0, 2: 0 };
        data.data.receipt_income.map(
          (p) => (receipt_income[`${p.payment_type}`] = p.amount)
        );
        this.setState({
          app_statistics: {
            ...this.state.app_statistics,
            from_company: data.data.appointments.from_company,
            from_platform: data.data.appointments.from_platform,
            from_staff: data.data.appointments.from_staff,
          },
          stats: {
            ...data.data,
            loaded: true,
            receipt_income: {
              ...this.state.stats.receipt_income,
              ...receipt_income,
            },
          },
          appointmentDatasets: {
            datasets: [
              {
                ...this.state.appointmentDatasets.datasets[0],
                data: [
                  ...[...Object.keys(data.data.appointments)]
                    .filter((key) => key.split("_")[0] === "from")
                    .map((key) => data.data.appointments[key]),
                ],
              },
            ],
          },
          appointment_zone: {
            ...Object.assign(
              {},
              ...Object.keys(appointmentZones).map((key) => ({
                [key]: appointmentZones[key],
              }))
            ),
            totalPercent: totalAppointmentZone,
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getAnalysisRI = () => {
    // Eğer zaten yükleme yapılıyorsa yeni istek atma
    if (this.state.isLoadingAnalysis) return;

    this.setState({ isLoadingAnalysis: true });

    Axios.get(`${this.context.api_endpoint}/company/analytics`, {
      params: {
        ...this.state.offsets,
      },
    })
      .then(({ data }) => {
        // Önceki state ile yeni data'yı karşılaştır
        const receipt_income = { 0: 0, 1: 0, 2: 0 };
        data.data.receipt_income.map(
          (p) => (receipt_income[`${p.payment_type}`] = p.amount)
        );

        // Sadece değişiklik varsa state'i güncelle
        if (JSON.stringify(this.state.stats.receipt_income) !== JSON.stringify(receipt_income)) {
          this.setState({
            stats: {
              ...this.state.stats,
              receipt_income: receipt_income,
            },
          });
        }
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      })
      .finally(() => {
        this.setState({ isLoadingAnalysis: false });
      });
  };

  getAnalysisRA = () => {
    Axios.get(`${this.context.api_endpoint}/company/analytics`, {
      params: {
        ...this.state.offsets,
      },
    })
      .then(({ data }) => {
        const totalAppointmentZone = Object.keys(data.data.appointments)
          .filter((item) => item.split("_")[0] === "from")
          .reduce((value, key) => (value += data.data.appointments[key]), 0);
        const appointmentZones = {};
        for (let key in data.data.appointments) {
          if (key.split("_")[0] === "from") {
            appointmentZones[key] =
              data.data.appointments[key] !== 0
                ? (
                  (data.data.appointments[key] / totalAppointmentZone) *
                  100
                ).toFixed(0)
                : 0;
          }
        }
        // Same with this.state.receipt_income
        let receipt_income = { 0: 0, 1: 0, 2: 0 };
        data.data.receipt_income.map(
          (p) => (receipt_income[`${p.payment_type}`] = p.amount)
        );
        this.setState({
          stats: {
            ...data.data,
            loaded: true,
            receipt_income: {
              ...this.state.stats.receipt_income,
              ...receipt_income,
            },
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  async changeAppointmentZoneOffset(offset, field) {
    await this.setState({
      offsets: {
        ...this.state.offsets,
        [field]: offset,
      },
      appointment_type_open_field: false,
      receipt_income_open_field: false,
    });
    this.getAnalysis();
  }

  caching = () => {
    let version = localStorage.getItem("version");
    if (version !== packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      localStorage.setItem("version", packageJson.version);
    }
  };

  getPackageNo = async () => {
    await Axios.get(`${this.context.api_endpoint}/company/license/packetno`)
      .then((data) => this.setState({ packageNo: data.data.data }))
      .catch((err) => console.log(err));
  };

  async componentDidMount() {
    //await this.caching();
    /*   if (this.context.state.company_tutorial === false) {
        this.props.history.push("/appointments");
      }
  
      setTimeout(() => {
        if (localStorage.getItem("open_tutorial") !== "1") {
          this.setState({ openTutorial: true });
        }
      }, 2000); */
    await this.getPackageNo();
    await this.getAppointmentRequests();
    // await this.getBirthDayRequests();
    //  await this.getCreditsRequests();
    //await this.getOpenApoointmentRequests();
    await this.getFavoritePages();
    await this.getAnalysis();
    this.getTodayAppointments(1);

    // Timer'ları başlat
    if (!this.state.isTimerLoading) {
      this.setState({ isTimerLoading: true }, () => {
        this.firstTimer = setInterval(() => {
          this.setState(prevState => ({
            campaignTimers: {
              ...prevState.campaignTimers,
              first: prevState.campaignTimers.first > 0 ? prevState.campaignTimers.first - 1 : 0
            }
          }));
        }, 1000);

        this.renewalTimer = setInterval(() => {
          this.setState(prevState => ({
            campaignTimers: {
              ...prevState.campaignTimers,
              renewal: prevState.campaignTimers.renewal > 0 ? prevState.campaignTimers.renewal - 1 : 0
            }
          }));
        }, 1000);
      });
    }
  }

  componentWillUnmount() {
    // Timer'ları temizle
    if (this.firstTimer) {
      clearInterval(this.firstTimer);
      this.firstTimer = null;
    }
    if (this.renewalTimer) {
      clearInterval(this.renewalTimer);
      this.renewalTimer = null;
    }
    this.setState({ isTimerLoading: false });
  }

  handleReceiptIncomeOffsetChange = async (receiptincomeoffset) => {
    await this.setState({
      offsets: {
        ...this.state.offsets,
        receiptincomeoffset,
      },
    });
    await this.getAnalysisRI();
  };

  Favorites = () => {
    const handleOnDragEnd = async (result) => {
      if (result.destination !== null) {
        const items = Array.from(this.state.favoritePages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        await this.setState({ favoritePages: [...items] });
        updateFavoritePages();
      }
    };

    const updateFavoritePages = () => {
      Axios.put(`${this.context.api_endpoint}/staff/favorite/save`, {
        data: this.state.favoritePages.toString().concat(","),
      })
        .then((response) => { })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    };

    const boxColors = [
      "#ECFDED",
      "#E9F8FD",
      "#F0EEFB",
      "#FAEFED",
      "#FEF4EA",
      "#EDF1FC",
      "#FDF3FC",
    ];
    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters" direction="horizontal">
          {(provided) => (
            <FavoritesContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {this.state.favoritePages.map((value, index) => {
                const tab = favoritePage(value);
                if (tab) {
                  if (
                    favoritePage(value)?.auth?.includes(this.state.packageNo)
                  ) {
                    return (
                      <Draggable key={value} draggableId={value} index={index}>
                        {(provided) => (
                          <FavoriteItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            favoritePagesCount={this.state.favoritePages.length}
                          >
                            <FavoriteBox
                              backgroundColor={boxColors[index]}
                              onClick={() => {
                                this.props.history.push(tab.path);
                              }}
                            >
                              {<tab.icon fontSize="large" />}
                              {tab.name}
                            </FavoriteBox>
                          </FavoriteItem>
                        )}
                      </Draggable>
                    );
                  } else {
                    return null;
                  }
                }
                return null;
              })}
              {provided.placeholder}
            </FavoritesContainer>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  dashboardFields = () => {
    const { packageNo } = this.state;
    const { campaigns } = this.context.state;
    function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }
    const packageCardDetails = [
      {
        title: this.context.t("dashboard.packageCardDetails.0.title"),
        listItems: this.context.t("dashboard.packageCardDetails.0.listItems"),
        price: this.context.t("dashboard.packageCardDetails.0.price"),
        oldPrice: this.context.t("dashboard.packageCardDetails.0.oldPrice"),
        discount: this.context.t("dashboard.packageCardDetails.0.discount"),
        info: this.context.t("dashboard.packageCardDetails.0.info"),
      },
      {
        title: this.context.t("dashboard.packageCardDetails.1.title"),
        listItems: this.context.t("dashboard.packageCardDetails.1.listItems"),
        price: this.context.t("dashboard.packageCardDetails.1.price"),
        oldPrice: this.context.t("dashboard.packageCardDetails.1.oldPrice"),
        discount: this.context.t("dashboard.packageCardDetails.1.discount"),
        info: this.context.t("dashboard.packageCardDetails.1.info"),
      },
      {
        title: this.context.t("dashboard.packageCardDetails.2.title"),
        listItems: this.context.t("dashboard.packageCardDetails.2.listItems"),
        price: this.context.t("dashboard.packageCardDetails.2.price"),
        oldPrice: this.context.t("dashboard.packageCardDetails.2.oldPrice"),
        discount: this.context.t("dashboard.packageCardDetails.2.discount"),
        info: this.context.t("dashboard.packageCardDetails.2.info"),
      },
    ]

    const packageCardDetailsRenewal = [
      {
        title: this.context.t("dashboard.packageCardDetailsRenewal.0.title"),
        listItems: this.context.t("dashboard.packageCardDetailsRenewal.0.listItems"),
        price: this.context.t("dashboard.packageCardDetailsRenewal.0.price"),
        info: this.context.t("dashboard.packageCardDetailsRenewal.0.info"),
      },
      {
        title: this.context.t("dashboard.packageCardDetailsRenewal.1.title"),
        listItems: this.context.t("dashboard.packageCardDetailsRenewal.1.listItems"),
        price: this.context.t("dashboard.packageCardDetailsRenewal.1.price"),
        info: this.context.t("dashboard.packageCardDetailsRenewal.1.info"),
      },
      {
        title: this.context.t("dashboard.packageCardDetailsRenewal.2.title"),
        listItems: this.context.t("dashboard.packageCardDetailsRenewal.2.listItems"),
        price: this.context.t("dashboard.packageCardDetailsRenewal.2.price"),
        info: this.context.t("dashboard.packageCardDetailsRenewal.2.info"),
      },
    ]



    /*TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    };*/
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    React.useEffect(() => {
      if (value === 4 && this.state.birthDay.data.length === 0) {
        this.getBirthDayRequests();
      } else if (value === 3 && this.state.creditors.data.length === 0) {
        this.getCreditsRequests();
      } else if (value === 2 && this.state.openAppointments.data.length === 0) {
        this.getOpenApoointmentRequests();
      } else if (value === 1 && this.state.todayAppointments.data.length === 0) {
        this.getTodayAppointments(1);
      }
    }, [value]);


    React.useEffect(() => {
      const timer = setTimeout(() => {
        if (this.context?.state?.campaigns?.first?.timeLeft > 0) {
          const newTimeLeft = this.context.state.campaigns.first.timeLeft - 1;
          this.context.state.campaigns.first.timeLeft = newTimeLeft;
        }
      }, 1000);

      return () => clearTimeout(timer);
    }, [this.context?.state?.campaigns?.first?.timeLeft]);


    React.useEffect(() => {
      const timer = setTimeout(() => {
        if (this.context?.state?.campaigns?.renewal?.timeLeft > 0) {
          const newTimeLeft = this.context.state.campaigns.renewal.timeLeft - 1;
          this.context.state.campaigns.renewal.timeLeft = newTimeLeft;
        }
      }, 1000);
      return () => clearTimeout(timer);
    }, [this.context?.state?.campaigns?.renewal?.timeLeft]);

    // React.useEffect(() => {
    //   if (this.state.isConfirmedConflict === true) {
    //     this.approveSingleAppointmentRequest(this.state.confirmConflictId);
    //   }
    // }, [this.state.isConfirmedConflict]);

    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }
    if (packageNo === 1 || packageNo === 4) {
      return (
        <>
          <Grid>

            {/* First kampanya kartları */}
            {this.state.campaignTimers.first > 0 && campaigns?.first?.url && (
              <Column className="xs-12 sm-12 md-12 lg-12">
                {/* Paket Kartları */}
                <PackageTitle>
                  <h1>
                    {this.context.t("dashboard.packageTitle")}
                    <span>{this.context.t("dashboard.packageTitleSpan")}</span>
                  </h1>
                </PackageTitle>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                  {packageCardDetails.map((item, index) => (
                    <Column key={index} style={{ padding: "0px", width: "30%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                      <Card>
                        {index === 1 && (<div>
                          <img src={"https://salonrandevu.app/assets/img/ribbon.024536d1.png"} style={{ width: "45%", objectFit: "contain", position: "absolute", top: "2%", right: "-10%", transform: "rotate(40deg)" }} />
                        </div>)}
                        <Header>
                          <BsInfoSquareFill title={item.info} /> <h2>{item.title}</h2>
                        </Header>
                        <List>
                          {item.listItems.map((listItem, index) => (
                            <li key={index}>
                              <span>✔</span> {listItem}
                            </li>
                          ))}
                        </List>
                        <hr style={{ width: "-webkit-fill-available" }} />
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px", paddingTop: "10px", paddingBottom: "0px" }}>
                            <div style={{ display: "flex", flexDirection: "column", }}>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                                <OriginalPrice>{item.oldPrice}</OriginalPrice>
                                <DiscountBadge>✔ {item.discount}</DiscountBadge>
                              </div>
                              <FinalPrice>
                                {item.price.split('/')[0]} <span style={{ fontSize: "14px" }}> {`/ ${item.price.split('/')[1]}`}</span>
                              </FinalPrice>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <BuyButton href={campaigns.first.url}><SlBasket />{this.context.t("dashboard.packageBuyButton")}</BuyButton>
                            </div>
                          </div>

                        </div>
                        <Link href={campaigns?.first?.url + "#wpdensor"} style={{ cursor: "pointer" }}>{this.context.t("dashboard.packageMoreInfo")}</Link>
                      </Card>
                    </Column>
                  ))}
                </Grid>
              </Column>
            )}
            {/* Renewal kampanya kartları */}
            {(!this.state.campaignTimers.first || !campaigns?.first?.url) &&
              this.state.campaignTimers.renewal > 0 && campaigns?.renewal?.url && (

                <Column className="xs-12 sm-12 md-12 lg-12">
                  {/* Paket Kartları */}
                  <PackageTitle>
                    <h1>
                      {this.context.t("dashboard.packageTitleRenewal")}
                    </h1>
                  </PackageTitle>
                  <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                    {packageCardDetailsRenewal.map((item, index) => (
                      <Column key={index} style={{ padding: "0px", width: "30%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Card>
                          {index === 1 && (<div>
                            <img src={"https://salonrandevu.app/assets/img/ribbon.024536d1.png"} style={{ width: "45%", objectFit: "contain", position: "absolute", top: "2%", right: "-10%", transform: "rotate(40deg)" }} />
                          </div>)}
                          <Header>
                            <BsInfoSquareFill title={item.info} /> <h2>{item.title}</h2>
                          </Header>
                          <List>
                            {item.listItems.map((listItem, index) => (
                              <li key={index}>
                                <span>✔</span> {listItem}
                              </li>
                            ))}
                          </List>
                          <hr style={{ width: "-webkit-fill-available" }} />
                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "30px", paddingTop: "10px", paddingBottom: "0px" }}>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                <FinalPrice>
                                  {item.price.split('/')[0]} <span style={{ fontSize: "14px" }}> {`/ ${item.price.split('/')[1]}`}</span>
                                </FinalPrice>
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <BuyButton href={campaigns?.renewal?.url} ><SlBasket />{this.context.t("dashboard.packageBuyButtonRenewal")}</BuyButton>
                              </div>
                            </div>

                          </div>
                          <Link href={campaigns?.renewal?.url + "#wpdensor"} style={{ cursor: "pointer" }}>{this.context.t("dashboard.packageMoreInfoRenewal")}</Link>
                        </Card>
                      </Column>
                    ))}
                  </Grid>
                </Column>
              )}
            <this.QRCodeDialog />
            <Grid id="favorites">
              <this.Favorites />
            </Grid>
            {/* Kasa Istatikleri */}
            <ReceiptIncomeStats
              context={this.context}
              stats={this.state.stats}
              offsets={this.state.offsets}
              onOffsetChange={this.handleReceiptIncomeOffsetChange}
              currency={this.context.state.currency}
            />
            {/* Randevu Takvimi */}
            <Column className="xs-12 sm-12 md-12 lg-4 mb-1">
              <FieldCard
                id="card-appointments"
                cardTitle={this.context.t(
                  `dashboard.cardTitle.appointment_calender_card_title`
                )}
                helperText={this.context.t(
                  `dashboard.cardDescription.appointment_calender_card_description`
                )}
                style={{
                  height: "330px",
                  overflowX: "auto",
                  background: "#FEF4EA",
                  position: "relative",
                }}
              >
                <CustomMUIDatePicker
                  autoOk
                  openTo="date"
                  variant="static"
                  orientation="landspace"
                  disableToolbar={true}
                  showTodayButton={true}
                  value={new Date()}
                  expanded={true}
                  background="#FEF4EA"
                  onChange={this.handleDateSelect}
                />
              </FieldCard>
            </Column>

            {/* Randevu Ayrıntıları */}
            <Column className="xs-12 sm-12 md-12 lg-4">
              <FieldCardTwoTitle
                id="card-appointment_details"
                style={{ height: "330px", overflow: "scroll" }}
                cardTitle={this.context.t(
                  `dashboard.cardTitle.appointments_card_title`
                )}
                helperText={this.context.t(
                  `dashboard.cardDescription.appointments_card_description`
                )}
              >
                {this.context.state.fieldCardTwoTitle === 0 ? (
                  <CustomDateRangeColumn
                    style={{ width: "100%" }}
                    /* sectionTitle={this.context.t(
                  `dashboard.stats.appointment.heading`
                )} */
                    onClickHandler={async (appointmentoffset) => {
                      await this.setState({
                        offsets: {
                          ...this.state.offsets,
                          appointmentoffset,
                        },
                      });

                      await this.getAnalysisRA();
                    }}
                  >
                    <StatsCard
                      className="mb-2"
                      icon={{ label: "alarm", color: "purple" }}
                      title={this.context.t(
                        `dashboard.stats.appointment.createdAppointments`
                      )}
                      value={this.state.stats.appointments.created_appointments}
                    />
                    <StatsCard
                      className="mb-2"
                      icon={{ label: "alarm_on", color: "green" }}
                      title={this.context.t(
                        `dashboard.stats.appointment.successAppointments`
                      )}
                      value={this.state.stats.appointments.succeed_appointments}
                    />
                    <StatsCard
                      className="mb-2"
                      icon={{ label: "alarm_off", color: "red" }}
                      title={this.context.t(
                        `dashboard.stats.appointment.closedAppointments`
                      )}
                      value={this.state.stats.appointments.closed_appointments}
                    />
                  </CustomDateRangeColumn>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",

                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          icon="crop_free"
                          title={this.context.t(`dashboard.PlatformLink.Qr`)}
                          onClick={() =>
                            this.setState({ qr_code_dialog: true })
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div style={{ maxWidth: "330px" }}>
                            <h4 style={{ fontWeight: "bold" }}>
                              {this.context.t(`dashboard.PlatformLink.title`)}
                            </h4>
                            <p style={{ wordWrap: "break-word" }}>
                              {this.context.t(
                                `dashboard.PlatformLink.description`
                              )}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "15px" }}>
                            <a
                              href={`${this.context.app.WEB}/isletme/${this.context.state?.company_id}`}
                              target="_blank"
                              style={{
                                marginTop: "5px",
                                width: "150px",
                                height: "35px",
                                padding: "5px",
                                backgroundColor: "#3189ec",
                                color: "white",
                                borderRadius: "10px",
                                cursor: "pointer",
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                textDecoration: "none",
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.opacity = "0.7";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.opacity = "1";
                              }}
                            >
                              <CallMissedOutgoingIcon />{" "}
                              {this.context.t(
                                `dashboard.PlatformLink.goToLink`
                              )}
                            </a>
                            <CopyToClipboard
                              text={
                                this.context.state?.company_id !== null
                                  ? `${this.context.app.WEB}/isletme/${this.context.state?.company_id}`
                                  : "Url Not Found"
                              }
                            >
                              <a
                                onClick={() => {
                                  this.context.state?.company_id !== null
                                    ? toast.success(
                                      this.context.t(
                                        `dashboard.PlatformLink.linkCopied`
                                      )
                                    )
                                    : toast.error("Url Not Found");
                                }}
                                style={{
                                  marginTop: "5px",
                                  width: "150px",
                                  height: "35px",
                                  padding: "5px",
                                  backgroundColor: "#4CAF50",
                                  color: "white",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  border: "none",
                                  boxShadow: " 0 4px 6px rgba(0, 0, 0, 0.1)",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  justifyContent: "center",
                                  textDecoration: "none",
                                }}
                                onMouseEnter={(e) => {
                                  e.target.style.opacity = "0.7";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.opacity = "1";
                                }}
                              >
                                <FileCopyIcon />{" "}
                                {this.context.t(
                                  `dashboard.PlatformLink.copyLink`
                                )}
                              </a>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </FieldCardTwoTitle>
            </Column>
          </Grid>

          {/* Last Row */}
          <Grid className="mb-2">
            <span
              className="bg-orange"
              style={{
                position: 'absolute',
                left: "450px",
                zIndex: "11",
                color: 'white',
                marginTop: "-6px",
                padding: '2px 7px',
                borderRadius: '12px',
                fontSize: '10px',
                fontWeight: 'bold',
                textTransform: 'uppercase'
              }}
            >
              {this.context.t(`appointments.detail.statusNew`)}
            </span>
            {/* Online Randevu Talepleri */}
            <Column className="xs-12 sm-12 md-12 lg-6 xl-8 mb-1">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons="auto"
                  TabIndicatorProps={{
                    style: {
                      display: 'none' // Varsayılan indikatörü gizle
                    }
                  }}
                >
                  <Tab
                    style={{
                      backgroundColor: value === 0 ? "rgb(228, 243, 247)" : "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    label={this.context.t(
                      `dashboard.waitingAppointments.sectionHeading`
                    )}
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{
                      backgroundColor: value === 1 ? "rgb(228, 243, 247)" : "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    label={this.context.t(`dashboard.appointmentList.title`)}
                    {...a11yProps(1)}
                  />
                  <Tab
                    style={{
                      backgroundColor: value === 2 ? "rgb(228, 243, 247)" : "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    label={this.context.t(`dashboard.openAppointments.title`)}
                    {...a11yProps(2)}
                  />
                  <Tab
                    style={{
                      backgroundColor: value === 3 ? "rgb(228, 243, 247)" : "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    label={this.context.t(`dashboard.creditors.title`)}
                    {...a11yProps(3)}
                  />
                  <Tab
                    style={{
                      backgroundColor: value === 4 ? "rgb(228, 243, 247)" : "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    label={this.context.t(`dashboard.birthDay.title`)}
                    {...a11yProps(4)}
                  />
                </Tabs>
              </Box>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                  fontSize: "11px",
                  "& .MuiTableCell-root": {
                    fontSize: "11px",
                    padding: "8px"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "13px"
                  }
                }}
                value={value}
                index={0}
              >
                <Table
                  isExist={false}
                  isDashboard={true}
                  loaded={this.state.onlineAppointments.loaded}
                  headings={{
                    appointment_start_date: {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.appointmentStartDate`
                      ),
                    },
                    customer_full_name: {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.customerFullName`
                      ),
                      //with_photo: "customer.photo",
                      limited_line: 3,
                      style: { width: "200px" },
                    },
                    customer_phone: {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.customerPhone`
                      ),
                    },

                    "staff.full_name": {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.staffFullName`
                      ),
                      with_photo: "staff.detail.profile_photo",
                      limited_line: 1,
                    },
                    "service.name": {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.serviceName`
                      ),
                      limited_line: 1,
                    },
                    note: {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.note`
                      ),
                      limited_line: 1,
                    },
                    "customer_statics.sumCol": {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.statistics`
                      ),
                    },
                    _: { label: this.context.t(`component.actionHeadingText`) },
                  }}
                  rows={this.state.onlineAppointments.data}
                  replacements={this.state.replacements}
                  pagination={this.state.pagination}
                  noDataText={this.context.t(`dashboard.waitingAppointments.noDataText`)}
                  buttons={[
                    {
                      // title: this.context.t(`['appointments/online'].approveButtonTitle`),
                      icon: "check",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: this.context.state.user.permission === 0,
                      onClick: (row) =>
                        this.approveSingleAppointmentRequest(row.id),
                    },
                    {
                      // title: this.context.t(`['appointments/online'].rejectButtonTitle`),
                      icon: "close",
                      textColor: "red",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: this.context.state.user.permission === 0,
                      onClick: (row) =>
                        this.setState({
                          delete_id: row.id,
                          delete_confirm: true,
                        }),
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                  fontSize: "11px",
                  "& .MuiTableCell-root": {
                    fontSize: "11px",
                    padding: "8px"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "13px"
                  }
                }}
                value={value}
                index={1}
              >
                <Table
                  loaded={this.state.todayAppointments.loaded}
                  refreshAction={this.getTodayAppointments}
                  headings={{
                    appointment_start_date: {
                      label: this.context.t(`['appointments/filter'].headings.appointmentStartDate`),
                    },
                    "customer.full_name": {
                      label: this.context.t(`['appointments/filter'].headings.customerFullName`),
                      limited_line: 1,
                    },
                    "service_or_packet": {
                      label: this.context.t(
                        `['appointments/filter'].headings.serviceName`
                      ),
                      limited_line: 1,
                      getValue: (row) => {
                        return row.service?.name || row.packet?.name || "-";
                      }
                    },
                    "staff.full_name": {
                      label: this.context.t(`['appointments/filter'].headings.staffFullName`),
                      limited_line: 1,
                    },
                    "customer.phone": {
                      label: this.context.t(`['appointments/filter'].headings.customerPhone`),
                    },
                    created_at: {
                      label: this.context.t(`['appointments/filter'].headings.createdAt`),
                    },
                    note: {
                      label: this.context.t(`['appointments/filter'].headings.appointmentNote`),
                      limited_line: 2,
                    },
                  }}
                  rows={this.state.todayAppointments.data}
                  pagination={this.state.paginationTodayAppointments}
                  onRowClick={this.handleAppointmentClick}
                  rowStyle={{ cursor: 'pointer' }}
                />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                  fontSize: "11px",
                  "& .MuiTableCell-root": {
                    fontSize: "11px",
                    padding: "8px"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "13px"
                  }
                }}
                value={value}
                index={2}
              >
                <Table
                  isExist={false}
                  loaded={this.state.openAppointments.loaded}
                  headings={{
                    date: {
                      label: this.context.t(
                        `dashboard.openAppointments.headings.date`
                      ),
                    },
                    customerFullName: {
                      label: this.context.t(
                        `dashboard.openAppointments.headings.customerFullName`
                      ),
                    },
                    serviceName: {
                      label: this.context.t(
                        `dashboard.openAppointments.headings.serviceName`
                      ),
                    },
                    products: {
                      label: this.context.t(
                        `dashboard.openAppointments.headings.products`
                      ),
                    },
                    totalPayment: {
                      label: this.context.t(
                        `dashboard.openAppointments.headings.totalPayment`
                      ),
                    },
                    _: { label: this.context.t(`component.actionHeadingText`) },
                  }}
                  rows={this.state.openAppointments.data}
                  replacements={this.state.replacements}
                  pagination={this.state.paginationOpenAppointments}
                  noDataText={this.context.t(`dashboard.openAppointments.noDataText`)}
                  buttons={[
                    {
                      icon: "visibility",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: this.context.state.user.permission === 0,
                      onClick: (row) =>
                        (window.location.href = `/receipts/detail/${row.id}`),
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                  fontSize: "11px",
                  "& .MuiTableCell-root": {
                    fontSize: "11px",
                    padding: "8px"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "13px"
                  }
                }}
                value={value}
                index={3}
              >
                <Table
                  isExist={false}
                  loaded={this.state.creditors.loaded}
                  headings={{
                    customerFullName: {
                      label: this.context.t(
                        `dashboard.creditors.headings.customerFullName`
                      ),
                    },
                    type: {
                      label: this.context.t(
                        `dashboard.creditors.headings.type`
                      ),
                    },
                    paymentDate: {
                      label: this.context.t(
                        `dashboard.creditors.headings.paymentDate`
                      ),
                    },
                    totalPayment: {
                      label: this.context.t(
                        `dashboard.creditors.headings.totalPayment`
                      ),
                    },
                  }}
                  rows={this.state.creditors.data}
                  replacements={this.state.replacements}
                  pagination={this.state.paginationCredits}
                  noDataText={this.context.t(`dashboard.creditors.noDataText`)}
                  buttons={[
                    {
                      // title: this.context.t(`['appointments/online'].approveButtonTitle`),
                      icon: "check",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: this.context.state.user.permission === 0,
                      onClick: (row) =>
                        this.approveSingleAppointmentRequest(row.id),
                    },
                    {
                      // title: this.context.t(`['appointments/online'].rejectButtonTitle`),
                      icon: "close",
                      textColor: "red",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: this.context.state.user.permission === 0,
                      onClick: (row) =>
                        this.setState({
                          delete_id: row.id,
                          delete_confirm: true,
                        }),
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                  fontSize: "11px",
                  "& .MuiTableCell-root": {
                    fontSize: "11px",
                    padding: "8px"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "13px"
                  }
                }}
                value={value}
                index={4}
              >
                <Table
                  isExist={false}
                  loaded={this.state.birthDay.loaded}
                  headings={{
                    customerFullName: {
                      label: this.context.t(
                        `dashboard.birthDay.headings.customerFullName`
                      ),
                    },
                    customer_phone: {
                      label: this.context.t(
                        `dashboard.birthDay.headings.customerPhone`
                      ),
                    },
                    birthDate: {
                      label: this.context.t(
                        `dashboard.birthDay.headings.birthDate`
                      ),
                    },
                  }}
                  rows={this.state.birthDay.data}
                  replacements={this.state.replacements}
                  pagination={this.state.paginationBirthDay}
                  noDataText={this.context.t(`dashboard.birthDay.noDataText`)}
                />
              </TabPanel>
            </Column>
            {/* Randevu Istatikleri */}
            <Column className="xs-12 sm-12 md-12 lg-6 xl-4 mb-1">
              <FieldCard
                id="card-appointment_statistics"
                cardTitle={this.context.t(
                  `dashboard.stats.appointment.heading`
                )}
                helperText={this.context.t(
                  `dashboard.cardDescription.appointment_statistic_card_description`
                )}
                style={{
                  height: "400px",
                  maxHeight: "500px",
                  overflow: "hidden",
                }}
              >
                <CustomDateRangeColumn
                  style={{ width: "100%" }}
                  onClickHandler={async (appointmentoffset) => {
                    await this.setState({
                      offsets: {
                        ...this.state.offsets,
                        appointmentoffset,
                      },
                    });

                    await this.getAnalysisRI();
                  }}
                >
                  <Grid style={{ width: "100%", height: "100%" }}>
                    {this.state.appointment_zone.totalPercent !== 0 && (
                      <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CDoughnut
                            data={this.state.appointmentDatasets}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          ></CDoughnut>
                        </div>
                      </Column>
                    )}
                    <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <HorizontalBar
                          width={`${this.state.appointment_zone.from_staff}%`}
                          background="rgba(140, 15, 180, 0.4)"
                          label={`${this.state.app_statistics.from_staff}`}
                          title={this.context.t(
                            `dashboard.appointmentAnalys.panel`
                          )}
                        />
                        <HorizontalBar
                          width={`${this.state.appointment_zone.from_company}%`}
                          background="rgba(29, 139, 62, 0.4)"
                          label={`${this.state.app_statistics.from_company}`}
                          title={this.context.t(
                            `dashboard.appointmentAnalys.company`
                          )}
                        />
                        <HorizontalBar
                          width={`${this.state.appointment_zone.from_platform}%`}
                          background="rgba(49, 137, 236, 0.6)"
                          label={`${this.state.app_statistics.from_platform}`}
                          title={
                            <div>
                              {this.context.t(
                                `dashboard.appointmentAnalys.platform`
                              )}
                            </div>
                          }
                        />
                      </div>
                    </Column>
                  </Grid>
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>

            <AlertDialog
              title={this.context.t(
                `['appointments/online'].rejectConfirm.title`
              )}
              open={this.state.delete_confirm}
              closeHandler={() => this.setState({ delete_confirm: false })}
              buttons={[
                {
                  title: this.context.t(
                    `dashboard.onlineAppointments.rejectConfirm.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () =>
                    this.rejectSingleAppointmentRequest(this.state.delete_id),
                },
                {
                  title: this.context.t(
                    `dashboard.onlineAppointments.rejectConfirm.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>
                  {this.context.t(
                    `dashboard.onlineAppointments.rejectConfirm.alertBoldText`
                  )}
                </b>
                <br />
                {this.context.t(
                  `dashboard.onlineAppointments.rejectConfirm.alertText`
                )}
              </Alert>
            </AlertDialog>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid>
            {/* Randevu Takvimi */}

            <Column className="xs-12 sm-12 md-12 lg-4 mb-1">
              <FieldCard
                id="card-appointments"
                cardTitle={this.context.t(
                  `dashboard.cardTitle.appointment_calender_card_title`
                )}
                helperText={this.context.t(
                  `dashboard.cardDescription.appointment_calender_card_description`
                )}
                style={{
                  height: "330px",
                  overflowX: "auto",
                  background: "#FEF4EA",
                  position: "relative",
                }}
              >
                <CustomMUIDatePicker
                  autoOk
                  openTo="date"
                  variant="static"
                  orientation="landspace"
                  disableToolbar={true}
                  showTodayButton={true}
                  value={new Date()}
                  expanded={true}
                  background="#FEF4EA"
                  onChange={this.handleDateSelect}
                />
              </FieldCard>
            </Column>
            {/* Randevu Ayrıntıları */}
            <Column className="xs-12 sm-12 md-12 lg-4">
              <FieldCard
                id="card-appointment_details"
                style={{ height: "330px", overflow: "scroll" }}
                cardTitle={this.context.t(
                  `dashboard.cardTitle.appointments_card_title`
                )}
                helperText={this.context.t(
                  `dashboard.cardDescription.appointments_card_description`
                )}
              >
                <CustomDateRangeColumn
                  style={{ width: "100%" }}
                  /* sectionTitle={this.context.t(
                    `dashboard.stats.appointment.heading`
                  )} */
                  onClickHandler={async (appointmentoffset) => {
                    await this.setState({
                      offsets: {
                        ...this.state.offsets,
                        appointmentoffset,
                      },
                    });

                    await this.getAnalysisRA();
                  }}
                >
                  <StatsCard
                    className="mb-2"
                    icon={{ label: "alarm", color: "purple" }}
                    title={this.context.t(
                      `dashboard.stats.appointment.createdAppointments`
                    )}
                    value={this.state.stats.appointments.created_appointments}
                  />
                  <StatsCard
                    className="mb-2"
                    icon={{ label: "alarm_on", color: "green" }}
                    title={this.context.t(
                      `dashboard.stats.appointment.successAppointments`
                    )}
                    value={this.state.stats.appointments.succeed_appointments}
                  />
                  <StatsCard
                    className="mb-2"
                    icon={{ label: "alarm_off", color: "red" }}
                    title={this.context.t(
                      `dashboard.stats.appointment.closedAppointments`
                    )}
                    value={this.state.stats.appointments.closed_appointments}
                  />
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>
            {/* Randevu Istatikleri */}
            <Column className="xs-12 sm-12 md-12 lg-6 xl-4 mb-1">
              <FieldCard
                id="card-appointment_statistics"
                cardTitle={this.context.t(
                  `dashboard.stats.appointment.heading`
                )}
                helperText={this.context.t(
                  `dashboard.cardDescription.appointment_statistic_card_description`
                )}
                style={{
                  height: "400px",
                  maxHeight: "500px",
                  overflow: "hidden",
                }}
              >
                <CustomDateRangeColumn
                  style={{ width: "100%" }}
                  onClickHandler={async (appointmentoffset) => {
                    await this.setState({
                      offsets: {
                        ...this.state.offsets,
                        appointmentoffset,
                      },
                    });

                    await this.getAnalysisRI();
                  }}
                >
                  <Grid style={{ width: "100%", height: "100%" }}>
                    {this.state.appointment_zone.totalPercent !== 0 && (
                      <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CDoughnut
                            data={this.state.appointmentDatasets}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          ></CDoughnut>
                        </div>
                      </Column>
                    )}
                    <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <HorizontalBar
                          width={`${this.state.appointment_zone.from_staff}%`}
                          background="rgba(140, 15, 180, 0.4)"
                          label={`${this.state.app_statistics.from_staff}`}
                          title={this.context.t(
                            `dashboard.appointmentAnalys.panel`
                          )}
                        />
                        <HorizontalBar
                          width={`${this.state.appointment_zone.from_company}%`}
                          background="rgba(29, 139, 62, 0.4)"
                          label={`${this.state.app_statistics.from_company}`}
                          title={this.context.t(
                            `dashboard.appointmentAnalys.company`
                          )}
                        />
                        <HorizontalBar
                          width={`${this.state.appointment_zone.from_platform}%`}
                          background="rgba(49, 137, 236, 0.6)"
                          label={`${this.state.app_statistics.from_platform}`}
                          title={
                            <div>
                              {this.context.t(
                                `dashboard.appointmentAnalys.platform`
                              )}
                            </div>
                          }
                        />
                      </div>
                    </Column>
                  </Grid>
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>
          </Grid>

          {/* Last Row */}
          <Grid className="mb-2">
            {/* Online Randevu Talepleri */}
            <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mb-1">
              <FieldCard
                id="card-online_appointment_requests"
                style={{
                  height: "400px",
                  overflow: "auto",
                  padding: "0px 5px",
                }}
                cardTitle={this.context.t(
                  `dashboard.waitingAppointments.sectionHeading`
                )}
                helperText={this.context.t(
                  `dashboard.cardDescription.online_appointment_request_card_description`
                )}
              >
                <Table
                  isExist={false}
                  loaded={this.state.onlineAppointments.loaded}
                  style={{ fontSize: "11px" }}
                  headings={{
                    appointment_start_date: {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.appointmentStartDate`
                      ),
                    },
                    customer_full_name: {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.customerFullName`
                      ),
                      //with_photo: "customer.photo",
                      limited_line: 3,
                      style: { width: "200px" },
                    },
                    customer_phone: {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.customerPhone`
                      ),
                    },

                    "staff.full_name": {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.staffFullName`
                      ),
                      with_photo: "staff.detail.profile_photo",
                      limited_line: 1,
                    },
                    "service.name": {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.serviceName`
                      ),
                      limited_line: 1,
                    },
                    note: {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.note`
                      ),
                      limited_line: 1,
                    },
                    "customer_statics.sumCol": {
                      label: this.context.t(
                        `dashboard.onlineAppointments.headings.statistics`
                      ),
                    },
                    _: { label: this.context.t(`component.actionHeadingText`) },
                  }}
                  rows={this.state.onlineAppointments.data}
                  replacements={this.state.replacements}
                  pagination={this.state.pagination}
                  noDataText={this.context.t(`dashboard.waitingAppointments.noDataText`)}
                  buttons={[
                    {
                      // title: this.context.t(`['appointments/online'].approveButtonTitle`),
                      icon: "check",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: this.context.state.user.permission === 0,
                      onClick: (row) => {
                        this.setState({
                          confirmConflictId: row.id
                        })
                        this.approveSingleAppointmentRequest(row.id);
                      }
                    },
                    {
                      // title: this.context.t(`['appointments/online'].rejectButtonTitle`),
                      icon: "close",
                      textColor: "red",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: this.context.state.user.permission === 0,
                      onClick: (row) =>
                        this.setState({
                          delete_id: row.id,
                          delete_confirm: true,
                        }),
                    },
                  ]}
                />
              </FieldCard>
            </Column>

            <AlertDialog
              title={this.context.t(
                `['appointments/online'].rejectConfirm.title`
              )}
              open={this.state.delete_confirm}
              closeHandler={() => this.setState({ delete_confirm: false })}
              buttons={[
                {
                  title: this.context.t(
                    `dashboard.onlineAppointments.rejectConfirm.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () =>
                    this.rejectSingleAppointmentRequest(this.state.delete_id),
                },
                {
                  title: this.context.t(
                    `dashboard.onlineAppointments.rejectConfirm.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>
                  {this.context.t(
                    `dashboard.onlineAppointments.rejectConfirm.alertBoldText`
                  )}
                </b>
                <br />
                {this.context.t(
                  `dashboard.onlineAppointments.rejectConfirm.alertText`
                )}
              </Alert>
            </AlertDialog>
          </Grid>
        </>
      );
    }
  };

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue }, () => {
      if (this.state.activeTab === 3 && this.state.birthDay.data.length === 0) {
        this.getBirthDayRequests();
      } else if (this.state.activeTab === 2 && this.state.creditors.data.length === 0) {
        this.getCreditsRequests();
      } else if (this.state.activeTab === 1 && this.state.openAppointments.data.length === 0) {
        this.getOpenApoointmentRequests();
      } else if (this.state.activeTab === 0 && this.state.todayAppointments.data.length === 0) {
        this.getTodayAppointments(1);
      }
    });
  };

  handleAppointmentRowClick = (row) => {
    const selectedDate = moment(row.raw_appointment_date).toDate();

    this.props.history.push({
      pathname: '/appointments',
      state: {
        selectedDate,

        fromDashboard: true
      }
    });
  };

  handleAppointmentClick = (appointment) => {
    this.props.history.push({
      pathname: '/appointments/list',
      state: {
        appointment_id: appointment.id,
        selectedFilterPicker: {
          id: 1,
          label: this.context.t(`accounting.today`),
          offset: 1
        }
      }
    });
  };

  // 1. shouldComponentUpdate metodunu ekleyelim
  shouldComponentUpdate(nextProps, nextState) {
    // Hangi bölümün güncellendiğini belirlemek için değişken tanımlayalım
    const updatingSection = this.determineUpdatingSection(nextState);

    switch (updatingSection) {
      case 'timer':
        // Sadece timer değerleri değiştiyse ve timer görünür durumdaysa güncelle
        return (
          this.state.campaignTimers.first !== nextState.campaignTimers.first ||
          this.state.campaignTimers.renewal !== nextState.campaignTimers.renewal
        ) && (
            nextState.campaignTimers.first > 0 ||
            nextState.campaignTimers.renewal > 0
          );

      case 'stats':
        // Kasa istatistikleri için derin karşılaştırma yapalım
        return this.hasStatsChanged(nextState);

      case 'other':
        // Diğer önemli değişiklikler için kontrol
        return this.hasOtherChanges(nextState);

      default:
        return false;
    }
  }

  // Hangi bölümün güncellendiğini belirleyen yardımcı fonksiyon
  determineUpdatingSection(nextState) {
    // Timer değişikliği varsa
    if (
      this.state.campaignTimers.first !== nextState.campaignTimers.first ||
      this.state.campaignTimers.renewal !== nextState.campaignTimers.renewal
    ) {
      return 'timer';
    }

    // Kasa istatistikleri değişikliği varsa
    if (
      this.state.stats !== nextState.stats ||
      this.state.offsets.receiptincomeoffset !== nextState.offsets.receiptincomeoffset
    ) {
      return 'stats';
    }

    // Diğer değişiklikler varsa
    return 'other';
  }

  // Kasa istatistikleri için derin karşılaştırma
  hasStatsChanged(nextState) {
    // Eğer kasa istatistikleri kartı görünür değilse güncelleme yapma
    if (!document.getElementById('card-receipt_income')) {
      return false;
    }

    // Receipt income değerlerini karşılaştır
    const currentIncome = this.state.stats.receipt_income;
    const nextIncome = nextState.stats.receipt_income;

    return (
      JSON.stringify(currentIncome) !== JSON.stringify(nextIncome) ||
      this.state.offsets.receiptincomeoffset !== nextState.offsets.receiptincomeoffset
    );
  }

  // Diğer önemli değişiklikler için kontrol
  hasOtherChanges(nextState) {
    return (
      this.state.onlineAppointments !== nextState.onlineAppointments ||
      this.state.todayAppointments !== nextState.todayAppointments ||
      this.state.openAppointments !== nextState.openAppointments ||
      this.state.creditors !== nextState.creditors ||
      this.state.birthDay !== nextState.birthDay ||
      this.state.confirmConflictPopUp !== nextState.confirmConflictPopUp ||
      this.state.delete_confirm !== nextState.delete_confirm
    );
  }

  render() {
    const { permission } = this.context.state.user;
    const Favorites = this.Favorites;

    return (
      <AuthContainer>
        <AlertDialog
          title={this.context.t(`['appointments/online'].conflictConfirm.title`)}
          open={this.state.confirmConflictPopUp}
          closeHandler={() => this.setState({ confirmConflictPopUp: false })}
          buttons={[
            {
              title: this.context.t(
                `['appointments/online'].rejectConfirm.confirmButtonTitle`
              ),
              icon: "check",
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                this.approveSingleAppointmentRequest(this.state.confirmConflictId, true);
              }
            },
            {
              title: this.context.t(
                `['appointments/online'].rejectConfirm.discardButtonTitle`
              ),
              icon: "close",
              textColor: "grey",
              onClick: () => {
                this.setState({
                  isConfirmedConflict: false,
                  confirmConflictPopUp: false
                })
              }
            },
          ]}
        >
          <Alert severity="warning">
            <b>
              {this.context.t(`['appointments/online'].conflictConfirm.alertBoldText`)}
            </b>
          </Alert>
        </AlertDialog>

        <this.dashboardFields />
      </AuthContainer>
    );
  }

}

export default withRouter(DashboardIndex);

const FavoritesContainer = styled.ul`
  width: 100%;
  padding: 15px;
  display: flex;
  gap: 0.5rem;
  align-items: stretch;
  list-style-type: none;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: 576px) {
    /* width */
    ::-webkit-scrollbar {
      display: block;
      height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

const FavoriteItem = styled.li`
  min-width: 150px;

  @media only screen and (min-width: 1190px) {
    flex: ${(props) => (props.favoritePagesCount > 3 ? 1 : 0)};
  }
`;

const FavoriteBox = styled.div`
  width: 100%;
  height: 120px;
  text-align: center;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${(props) => props.backgroundColor};
  box-shadow: 0 5px 15px #a5a5a5ad;

  @media only screen and (max-width: 1190px) {
    margin: 5px;
  }
`;

const Report = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const PackageTitle = styled.div`
  display: flex;
  font-family: Mulish, sans-serif;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  
  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #C7317C;
    margin: 0;
    
    span {
      color: #C7317C;
      font-size: 24px;
      margin-left: 8px;
    }
  }
`;
const Card = styled.div`
  background: white;
  border-radius: 25px;
  height: 450px;
  color: #333;
  position: relative;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  display: flex;
  flex-direction: column;
  width: 400px;
  border: 3px solid #d70c58;
  font-family: 'Poppins', sans-serif;
`;

const Header = styled.div`
  background: linear-gradient(to bottom right, #d50b59, #840087);
  padding: 18px;
  border-radius: 20px 20px 0 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  h2 {
    font-size: 1.475rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0px;
    }
`;

const List = styled.ul`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 0.875rem;

  li {
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
      color: #00bf63;
    }
  }
`;



const OriginalPrice = styled.p`
  font-size: 0.875rem;
  text-decoration: line-through;
  color: #031d44;
  margin-right: 20px;
`;

const DiscountBadge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00bf63;
  border-radius: 4px;
  padding: 3px 12px;
  font-size: 0.6875rem;
  color: white;
`;

const FinalPrice = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #031d44;

  span {
    font-size: 1rem;
    font-weight: normal;
  }
`;
const BuyButton = styled.a`
  padding-left: 18px;
  padding-right: 18px;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #C7317C;
  color: white;
  border: none;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #b02a6f;
  }
`;

const Link = styled.a`
  margin-top: 12px;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: #840087;
  text-decoration: underline;
`;
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import AuthContainer from "../../../components/AuthContainer";
import Axios from "axios";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import Input from "../../../theme/CustomMUI/Input";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import RoundedRow from "../../../components/Table/RoundedRow";
import FilterObject from "../../../functions/FilterObject";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import { Search } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import CustomerAddDialog from "../../../components/Dialogs/CustomerAddDialog";
import { Group } from "@material-ui/icons";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import { useLocation } from "react-router-dom";
import useHandleParams from "../../../functions/HandleParams";
import CSwitch from "../../../theme/CustomMUI/CSwitch";

const CustomersIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const HandleParams = useHandleParams()
  const [newCustomerModal, setNewCustomerModal] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [showAmount, setShowAmount] = useState(false);
  const [search_text, setSearchText] = useState(null);
  const urlPage = parseInt(
    new URLSearchParams(window.location.search).get("page")
  );
  const [pagination, setPagination] = useState({
    total_page: 1,
    page: urlPage ? urlPage : 1,
    onChange: (page) => setPagination((prev) => ({ ...prev, page: page })),
  });
  const [data, setData] = useState([]);
  const [sumCustomerCount, setSumCustomerCount] = useState(0);

  const [open_passive_all_dialog, setOpenPassiveAllDialog] = useState(false);
  const [open_passive_selected_dialog, setOpenPassiveSelectedDialog] =
    useState(false);
  const [open_update_sms_dialog, setOpenUpdateSmsDialog] = useState(false);

  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const [checkedAll, setCheckedAll] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [checked, setChecked] = useState(null)
  const [selectedList, setSelectedList] = useState({
    order: '',
    orderBy: ''
  })

  const updatePage = (newPage) => {
    searchParams.set('page', newPage);
    history.push({ search: searchParams.toString() });
  };
  const getCustomers = () => {
    setLoaded(false);

    Axios.get(
      `${context.api_endpoint}${showAmount
        ? `/company/customers?extra=1`
        : `/company/customers`
      }`, {
      params: {
        page: pagination.page ? pagination.page : 1,
        key: search_text === '' ? null : search_text,
        order: selectedList.order !== '' ? selectedList.order : null,
        orderBy: selectedList.orderBy !== '' ? selectedList.orderBy : null,
        gender: checked === null ? null : checked ? 1 : 0
      }
    }
    )
      .then(({ data }) => {
        setSumCustomerCount(data.data.total_record);
        if (showAmount) {
          setData([
            ...data.data.records.map((item) => {
              console.log('s_dialCode:', item.s_dialCode, 's_phone:', item.s_phone);
              return {
                ...item,
                full_name: (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/customers/detail/${item.id}`);
                    }}
                  >
                    {item.full_name}
                  </div>
                ),
                phone:
                  item.phone === null || item.phone === ""
                    ? context.state.user.permission === 2 &&
                      context.state.limited_permission.customer_info === 0
                      ? phoneNumberDisplay(item.s_dialCode + " " + item.s_phone)
                      : `${item.s_dialCode} ${item.s_phone}`
                    : context.state.user.permission === 2 &&
                      context.state.limited_permission.customer_info === 0
                      ? phoneNumberDisplay(item.phone)
                      : `${process.env.APP_NAME === "management"
                        ? `${item.dialCode} `
                        : "+90 "
                      }${item.phone.substring(0, 3)} ${item.phone.substring(
                        3,
                        6
                      )} ${item.phone.substring(6, 10)}`,
                extra_detail: {
                  ...item.extra_detail,
                  sum_income: (
                    <RoundedRow color="green">
                      {item.extra_detail.sum_income
                        ? item.extra_detail.sum_income.toFixed(2)
                        : "0.00"}
                      ₺
                    </RoundedRow>
                  ),
                  total_debt: (
                    <RoundedRow color="red">
                      {item.extra_detail.total_debt
                        ? item.extra_detail.total_debt.toFixed(2)
                        : "0.00"}
                      ₺
                    </RoundedRow>
                  ),
                },
              };
            }),
          ]);
        } else {
          setData([
            ...data.data.records.map((item) => {
              console.log('s_dialCode:', item.s_dialCode, 's_phone:', item.s_phone);
              return {
                ...item,
                full_name: (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push(`/customers/detail/${item.id}`);
                    }}
                  >
                    {item.full_name}
                  </div>
                ),
                send_sms: (
                  <div
                    style={{
                      width: "100px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {item.send_sms
                      ? context.t(`customers.headings.message_open`)
                      : context.t(`customers.headings.message_close`)}
                  </div>
                ),
                phone:
                  item.phone === null || item.phone === ""
                    ? context.state.user.permission === 2 &&
                      context.state.limited_permission.customer_info === 0
                      ? phoneNumberDisplay(item.s_dialCode + " " + item.s_phone)
                      : `${item.s_dialCode} ${item.s_phone}`
                    : context.state.user.permission === 2 &&
                      context.state.limited_permission.customer_info === 0
                      ? phoneNumberDisplay(item.phone)
                      : `${process.env.APP_NAME === "management"
                        ? `${item.dialCode} `
                        : "+90 "
                      }${item.phone.substring(0, 3)} ${item.phone.substring(
                        3,
                        6
                      )} ${item.phone.substring(6, 10)}`,
              };
            }),
          ]);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          history.push("/wallet");
        } else {
          toast.warning(e.response.data.message);
          history.push("/wallet");
        }
      })
      .finally(() => setLoaded(true));
  };

  // Update Customer Sms True

  const getUpdateCustomerSmsTrue = () => {
    Axios.put(`${context.api_endpoint}/company/customer/update/sms`, {
      send_sms: true,
    })
      .then((data) => {
        if (data.data.status === 200) {
          toast.success(data.data.data);
        } else {
          toast.error(data.data.data);
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };
  // Update Customer Sms False

  const getUpdateCustomerSmsFalse = () => {
    Axios.put(`${context.api_endpoint}/company/customer/update/sms`, {
      send_sms: false,
    })
      .then((data) => {
        if (data.data.status === 200) {
          toast.success(data.data.data);
        } else {
          toast.error(data.data.data);
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  // Search Customer

  // const searchCustomerByKey = async () => {
  //   setLoaded(false);
  //   await Axios.get(`${context.api_endpoint}/company/customers?page=${pagination.page}`, {
  //     params: {
  //       key: search_text,
  //       page: pagination.page ? pagination.page : 1,
  //       extra: showAmount ? 1 : 0,
  //     },
  //   })
  //     .then(({ data }) => {
  //       setLoaded(true);
  //       if (showAmount) {
  //         setData([
  //           ...data.data.records.map((item) => {
  //             return {
  //               ...item,
  //               phone: `${process.env.APP_NAME === "management"
  //                 ? `${item.dialCode} `
  //                 : "0"
  //                 }${item.phone.substring(0, 3)} ${item.phone.substring(
  //                   3,
  //                   6
  //                 )} ${item.phone.substring(6, 10)}`,
  //               extra_detail: {
  //                 ...item.extra_detail,
  //                 sum_income: (
  //                   <RoundedRow color="green">
  //                     {item.extra_detail.sum_income
  //                       ? item.extra_detail.sum_income.toFixed(2)
  //                       : "0.00"}
  //                     ₺
  //                   </RoundedRow>
  //                 ),
  //                 total_debt: (
  //                   <RoundedRow color="red">
  //                     {item.extra_detail.total_debt
  //                       ? item.extra_detail.total_debt.toFixed(2)
  //                       : "0.00"}
  //                     ₺
  //                   </RoundedRow>
  //                 ),
  //               },
  //             };
  //           }),
  //         ]);
  //       } else {
  //         setData([
  //           ...data.data.records.map((item) => {
  //             return {
  //               ...item,
  //               phone: `${process.env.APP_NAME === "management"
  //                 ? `${item.dialCode} `
  //                 : "0"
  //                 }${item.phone.substring(0, 3)} ${item.phone.substring(
  //                   3,
  //                   6
  //                 )} ${item.phone.substring(6, 10)}`,
  //             };
  //           }),
  //         ]);
  //       }
  //       // setPagination({
  //       //   ...pagination,
  //       //   total_page: data.data.records.length,
  //       //   page: data.data.page,
  //       // });
  //     })
  //     .catch((e) => {
  //       if (e.response.status === 401) {
  //         toast.error(e.response.data.message);
  //       } else {
  //         toast.warning(e.response.data.message);
  //       }
  //     });
  // };

  const [debouncedValueKey, setDebouncedValueKey] = useState('');

  // Debounce işlemi
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValueKey(search_text); // 800ms sonra debouncedValue güncellenir
    }, 800);

    return () => {
      clearTimeout(handler);
    };
  }, [search_text]);

  useEffect(() => {
    getCustomers()
  }, [debouncedValueKey]);

  useEffect(() => {
    getCustomers();
  }, [pagination.page, showAmount, selectedList, checked]);

  const tableDataParser = () => {
    const dataParser = showAmount
      ? {
        full_name: {
          label: context.t(`customers.headings.fullName`),
          sortable: {
            0: [
              { field: "`customers`.`name`", order: "asc" },
              { field: "`customers`.`surname`", order: "asc" },
            ],
            1: [
              { field: "`customers`.`name`", order: "desc" },
              { field: "`customers`.`surname`", order: "desc" },
            ],
          },
          isClicked: selectedList.orderBy === 'name' && selectedList.order === 'asc',
          onClick: () => {
            setPagination((prev) => ({ ...prev, page: 1 }))
            HandleParams('page', 1)

            setSelectedList((prev) => ({
              orderBy: 'name',
              order: prev.orderBy === 'name'
                ? (prev.order === 'asc' ? 'desc' : 'asc')
                : 'asc',
            }));
          },
        },
        phone: {
          label: context.t(`customers.headings.phone`),
          style: { width: 200 },
        },
        ...(process.env.APP_NAME !== "management" && {
          send_sms: {
            label: context.t(`customers.headings.message_state`),
          },
        }),
        sex: { label: context.t(`customers.headings.sex`) },

        "extra_detail.sum_income": {
          label: context.t(`customers.headings.incomeAll`),
          // sortable: {
          //   0: [{ field: "`rp`.`amount`", order: "ASC" }],
          //   1: [{ field: "`rp`.`amount`", order: "DESC" }],
          // },
        },
        "extra_detail.total_debt": {
          label: context.t(`customers.headings.debtAll`),
        },
        _: { label: context.t(`component.actionHeadingText`) },
      }
      : {
        full_name: {
          label: context.t(`customers.headings.fullName`),
          sortable: {
            0: [
              { field: "`customers`.`name`", order: "ASC" },
              { field: "`customers`.`surname`", order: "ASC" },
            ],
            1: [
              { field: "`customers`.`name`", order: "DESC" },
              { field: "`customers`.`surname`", order: "DESC" },
            ],
          },
          isClicked: selectedList.orderBy === 'name' && selectedList.order === 'asc',
          onClick: () => {
            setPagination((prev) => ({ ...prev, page: 1 }))
            HandleParams('page', 1)

            setSelectedList((prev) => ({
              orderBy: 'name',
              order: prev.orderBy === 'name'
                ? (prev.order === 'asc' ? 'desc' : 'asc')
                : 'asc',
            }));
          },
        },

        phone: {
          label: context.t(`customers.headings.phone`),
          style: { width: 200 },
        },
        ...(process.env.APP_NAME !== "management" && {
          send_sms: {
            label: context.t(`customers.headings.message_state`),
          },
        }),
        sex: { label: context.t(`customers.headings.sex`) },
        _: { label: context.t(`component.actionHeadingText`) },
      };

    return FilterObject(
      dataParser,
      (key, val) =>
        (context.current_flag & context.state.FLAGS.SALON_RANDEVU && true) ||
        (key !== "extra_detail.sum_income" && key !== "extra_detail.total_debt")
    );
  };

  const makeCustomersPassive = () => {
    Axios.post(`${context.api_endpoint}/company/customer/delete/multiple`, {
      customer_id: [...selectedCustomers],
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`customers.passiveSelected.successToast`));
          getCustomers(1);
          // searchCustomerByKey(page);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const makeAllCustomersPassive = () => {
    setLoaded(false);
    Axios.post(`${context.api_endpoint}/company/customer/delete/multiple`, {
      customer_id: [-1],
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`customers.passiveAll.successToast`));
          getCustomers(1);
          setLoaded(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          setLoaded(true);
        } else {
          toast.warning(e.response.data.message);
          setLoaded(true);
        }
      });
  };
  const opts = {
    height: "360",
    width: "600",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <AuthContainer authorities={[0, 1]} limited_permission="customer">
      <Grid className="mb-2">
        <Column className="xs-12 sm-12 md-12 lg-12">
          <Column className="xs-12 sm-12 md-12 lg-4">
            <Input
              InputProps={{
                startAdornment: (
                  <div style={{ marginTop: "10px" }}>
                    {" "}
                    <Search />
                  </div>
                ),
              }}
              placeholder={context.t(`customers.filter.inputLabel`)}
              onChange={async (e) => {
                const { value } = e.target;
                setSearchText(value);
                updatePage(1)
                setPagination((prev) => ({ ...prev, page: 1 }))
              }}
            />
          </Column>

          <CustomerAddDialog
            open={newCustomerModal}
            closeHandler={() => setNewCustomerModal(false)}
            createdCustomerHandler={async () => {
              await getCustomers();
            }}
          />

          <Column className="xs-12 sm-12 md-4 lg-4">
            <Button
              fullWidth={true}
              title={context.t(`customers.amount.updateSmsStatus`)}
              backgroundColor="primary"
              textColor="white"
              icon="sms"
              onClick={() => {
                setOpenUpdateSmsDialog(true);
              }}
            />

            <AlertDialog
              fullWidth={true}
              title={context.t(`customers.amount.allCustomerSmsUpdate`)}
              open={open_update_sms_dialog}
              closeHandler={() => setOpenUpdateSmsDialog(false)}
              buttons={[
                {
                  title: context.t(`customers.amount.openSmsSendingStatus`),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  timer: 5,
                  onClick: () => {
                    getUpdateCustomerSmsTrue();
                  },
                },
                {
                  title: context.t(`customers.amount.closeSmsSendingStatus`),
                  icon: "check",
                  backgroundColor: "red-opacity",
                  textColor: "red",
                  timer: 5,
                  onClick: () => {
                    getUpdateCustomerSmsFalse();
                  },
                },
              ]}
            >
              <Alert severity="warning">
                {context.t(`customers.alertTExt`)}
              </Alert>
            </AlertDialog>
          </Column>
          <Column
            className={`xs-12 sm-12 md-4 lg-${process.env.APP_NAME === "management" ? "4" : "2"
              }`}
          >
            <ExcelButton
              fullWidth
              requestData={
                showAmount ? { is_excel: true, extra: 1 } : { is_excel: true }
              }
              route="customers"
            />
          </Column>
          {process.env.APP_NAME === "management" ? null : (
            <Column className="xs-12 sm-12 md-4 lg-2">
              <Button
                icon={<Group />}
                title={context.t(
                  `['appointments/add'].createNewCustomerButtonTitle`
                )}
                backgroundColor="primary-opacity"
                textColor="primary"
                fullWidth={true}
                onClick={() => {
                  setNewCustomerModal(true);
                }}
              />
            </Column>
          )}
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-12">
          <Column
            className="xs-12 sm-12 md-12 lg-4 mt-1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              label={
                <FCLabel>
                  <i className="material-icons">account_balance_wallet</i>{" "}
                  <span>{context.t(`customers.amount.title`)}</span>
                </FCLabel>
              }
              control={
                <Switch
                  color="primary"
                  checked={showAmount}
                  onChange={async (e) => {
                    setShowAmount(e.target.checked);
                  }}
                />
              }
            />
          </Column>

          <Column className="xs-12 sm-12 md-4 lg-4">
            <Button
              disabled={selectedCustomers.length > 0 ? false : true}
              fullWidth={true}
              title={context.t(
                `customers.passiveSelected.passiveSelectedButtonTitle`
              )}
              backgroundColor="red"
              textColor="white"
              icon="block"
              onClick={() => setOpenPassiveAllDialog(true)}
            />
            <AlertDialog
              title={context.t(`customers.passiveSelected.title`)}
              open={open_passive_all_dialog}
              closeHandler={() => setOpenPassiveAllDialog(false)}
              buttons={[
                {
                  title: context.t(
                    `customers.passiveSelected.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  timer: 5,
                  onClick: () => makeCustomersPassive(),
                },
                {
                  title: context.t(
                    `customers.passiveSelected.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                {context.t(`customers.passiveSelected.alertText`)}
              </Alert>
            </AlertDialog>
          </Column>

          {data.length > 0 && (
            <Column className="xs-12 sm-12 md-4 lg-4">
              <Button
                fullWidth={true}
                title={context.t(`customers.passiveAll.passiveAllButtonTitle`)}
                backgroundColor="red"
                textColor="white"
                icon="block"
                onClick={() => setOpenPassiveSelectedDialog(true)}
              />
              <AlertDialog
                title={context.t(`customers.passiveAll.title`)}
                open={open_passive_selected_dialog}
                closeHandler={() => setOpenPassiveAllDialog(false)}
                buttons={[
                  {
                    title: context.t(`customers.passiveAll.confirmButtonTitle`),
                    icon: "check",
                    backgroundColor: "primary-opacity",
                    textColor: "primary",
                    timer: 5,
                    onClick: () => makeAllCustomersPassive(),
                  },
                  {
                    title: context.t(`customers.passiveAll.discardButtonTitle`),
                    icon: "close",
                    textColor: "grey",
                  },
                ]}
              >
                <Alert severity="warning">
                  {context.t(`customers.passiveAll.alertText`)}
                </Alert>
              </AlertDialog>
            </Column>
          )}
        </Column>
      </Grid>

      <Grid>
        <Column
          className="xs-12 sm-12 md-12"
          style={{
            color: "#7c7c7c",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Column>
            <Checkbox
              checked={checkedAll}
              color="primary"
              onChange={() => {
                setCheckedAll(!checkedAll);
                setSelectedCustomers(data.map((item) => item.id));
                if (checkedAll) {
                  setSelectedCustomers([]);
                }
              }}
            />
            {sumCustomerCount > 0 &&
              `${context.t(`customers.replacements.totalCustomerNumber`)}: ` +
              sumCustomerCount}
          </Column>
          <Column>
            <CSwitch
              label={context.t(`profile.detail.genderMale`)}
              labelLeft={context.t(`profile.detail.genderFemale`)}
              checked={checked}
              color="primary"
              closeColor="purple"
              onChange={(checked) => {
                setPagination((prev) => ({ ...prev, page: 1 }))
                HandleParams('page', 1)
                setChecked(checked.target.checked);
              }}
            />
          </Column>
        </Column>
        <Column className="xs-12 sm-12">
          {loaded ? (
            <Table
              refreshAction={() => getCustomers(pagination.page)}
              loaded={loaded}
              headings={tableDataParser()}
              rows={data}
              replacements={{
                sex: {
                  0: context.t(`customers.replacements.sex.female`),
                  1: context.t(`customers.replacements.sex.male`),
                  3: context.t(`customers.replacements.sex.undef`),
                },
              }}
              pagination={pagination}
              checkedCheckboxes={selectedCustomers}
              checkboxOnChange={(row, state) => {
                console.log("row:", row);
                console.log("state:", state);
                console.log("selectedCustomers:", selectedCustomers);
                if (state) {
                  setSelectedCustomers([...selectedCustomers, row.id]);
                } else {
                  setSelectedCustomers([
                    ...selectedCustomers.filter((id) => id !== row.id),
                  ]);
                }
              }}
              buttons={[
                {
                  title: context.t(`component.detailEditButtonTitle`),
                  icon: "launch",
                  textColor: "primary",
                  transitionEffect: true,
                  pushEffect: true,
                  onClick: (row) => history.push(`/customers/detail/${row.id}`),
                },
              ]}
            />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <img
                src={
                  process.env.APP_NAME === "salon"
                    ? Loader
                    : process.env.APP_NAME === "en"
                      ? LoaderEN
                      : LoaderSM
                }
                width="100"
                height="100"
                alt="loading"
              />
            </div>
          )}
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default CustomersIndex;
